import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const SelectAdType = () => {
  const navigate = useNavigate();
  const [selectedAdType, setSelectedAdType] = useState('');
  const token = localStorage.getItem("token")

  const handleAdTypeChange = (event) => {
    setSelectedAdType(event.target.value);
  };
  console.log(selectedAdType)
  const handleNextPage = () => {
    if (selectedAdType) {
      navigate('/pricing-plan', { state: { adType: selectedAdType, token: token }, replace: true });
    }
  };

  return (
    <div
      className="select-ad-type-page section-bg-image py-5"
      style={{ backgroundImage: `url(${require('../assets/bg-banner3.jpg')})` }}
    >
      <div className="container text-center">
        <div className="wrapper-width mx-auto" style={{ maxWidth: '600px' }}>
          <h1 className="section-label fs-1 mb-3 pb-2 text-dark">
            Choose Your Ad Type
          </h1>
          <p className="text-muted fs-5 mb-4">
            Select the type of ad that suits your business needs:
          </p>
          <div className="ad-type-options bg-light border-5 border-top border-primary rounded shadow p-4 mb-5">
            <div className="form-check mb-3">
              <input
                type="radio"
                id="sponsoredAd"
                name="adType"
                value="onetime"
                className="form-check-input me-2"
                onChange={handleAdTypeChange}
              />
              <label htmlFor="sponsoredAd" className="fs-5">
                Sponsored Business Ad
              </label>
            </div>
            <div className="form-check mb-3">
              <input
                type="radio"
                id="regularAd"
                name="adType"
                value="monthly"
                className="form-check-input me-2"
                onChange={handleAdTypeChange}
              />
              <label htmlFor="regularAd" className="fs-5">
                Regular Business Ad
              </label>
            </div>
          </div>
          {selectedAdType && (
            <button
              className="btn btn-primary mt-3"
              onClick={handleNextPage}
            >
              Proceed to Next Page
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectAdType;
