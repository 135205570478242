import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { withSnackbar } from 'react-simple-snackbar';
import * as actions from '../store/actions/index';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe("pk_live_HCDXsOAEmsRJMJkM1ZMnTi6H");

const WelcomePage = (props) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isSelected, setIsSelected] = useState(false);
  let token = state.token;
  const { subscriptionPlan, cost } = props;
  console.log(token)
  useEffect(() => {
    subscriptionPlan();
    // You can handle any effect here, e.g., fetching token from state if needed
  }, [state, subscriptionPlan]);

  const handleSelection = (event) => {
    setIsSelected(event.target.checked);
  };

  const handleNextPage = () => {
    navigate('/save-card', { state: { token: token }, replace: true })
  };

  return (
    <div
      className="membership-page section-bg-image py-5"
      style={{ backgroundImage: `url(${require('../assets/bg-banner3.jpg')})` }}
    >
      <div className="container text-center">
        <div className="wrapper-width mx-auto" style={{ maxWidth: '700px' }}>
          <h1 className="section-label fs-1 mb-3 pb-2 text-dark">
            Welcome to CouponRanking – Empowering Your Business Growth
          </h1>
          <p className="text-muted fs-5 mb-4">
            Become a part of our exclusive network of businesses and enjoy:
          </p>
          <ul className="list-unstyled fs-6 text-start mx-auto mb-4" style={{ maxWidth: '600px' }}>
            <li>✓ Greater exposure to potential customers searching for deals.</li>
            <li>✓ Tools to feature your best offers and attract more clients.</li>
          </ul>
          <div className="membership-fee-wrapper bg-light border-5 border-top border-primary rounded shadow p-4 mb-5">
            <h2 className="fs-3 text-primary mb-3">Membership Fee</h2>
            <div className="d-flex align-items-center justify-content-center">
              <input
                type="radio"
                id="annualSubscription"
                name="membership"
                className="form-check-input me-2"
                onChange={handleSelection}
              />
              <label htmlFor="annualSubscription" className="fs-5">
                <strong>Annual Subscription:</strong> <span className="text-success">${cost}/year</span>
              </label>
            </div>
            <p className="text-muted small mt-2">
              This small investment provides big opportunities for your business to thrive.
            </p>
          </div>
          {isSelected && (
            <button
              className="btn btn-primary mt-3"
              onClick={handleNextPage}
            >
              Proceed to Next Page
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const mapPropsToState = (state) => ({
  loading: state.auth.loading,
  error: state.auth.error,
  success: state.auth.success,
  cost: state.subscriptionPlan.cost
});

const mapDispatchToProps = (dispatch) => ({
  saveCard: (data, navigate) => dispatch(actions.saveCard(data, navigate)),
  subscriptionPlan: () => dispatch(actions.subscriptionPlan()),
  
});

export default connect(
  mapPropsToState,
  mapDispatchToProps
)(withSnackbar(WelcomePage));
