import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Cutlery from '../assets/cutlery.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux'
import { withSnackbar } from 'react-simple-snackbar'
import * as actions from '../store/actions/index';
import { Rating } from 'react-simple-star-rating'

// Import Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import ReactPaginate from 'react-paginate';
// import required modules
import { Autoplay, Pagination, Navigation, EffectFade } from 'swiper/modules';
import ReactLoading from 'react-loading';

const Business = [
    {
        image: require('../assets/business-cleaning.jpg'),
        name: "Johnson Cleaning",
        timing: "Open 11:00 am - 8:00pm",
        address: "Champaign, Il.",
    },
    {
        image: require('../assets/business-repair.jpg'),
        name: "Rick Auto Repair",
        timing: "Close 10:00pm - 8:00am",
        address: "Danvile, Il.",
    },
    {
        image: require('../assets/business-grocery.jpg'),
        name: "Jason Grocery Store",
        timing: "Open 11:00 am - 8:00pm",
        address: "Champaign, Il.",
    },
    {
        image: require('../assets/business-cafe.jpg'),
        name: "Johny Cafe",
        timing: "Open 11:00 am - 8:00pm",
        address: "Danvile, Il.",
    },
    {
        image: require('../assets/business-cleaning.jpg'),
        name: "Johnson Cleaning",
        timing: "Open 11:00 am - 8:00pm",
        address: "Champaign, Il.",
    },
    {
        image: require('../assets/business-cleaning.jpg'),
        name: "Johnson Cleaning",
        timing: "Open 11:00 am - 8:00pm",
        address: "Champaign, Il.",
    },
    {
        image: require('../assets/business-repair.jpg'),
        name: "Rick Auto Repair",
        timing: "Close 10:00pm - 8:00am",
        address: "Danvile, Il.",
    },
    {
        image: require('../assets/business-grocery.jpg'),
        name: "Jason Grocery Store",
        timing: "Open 11:00 am - 8:00pm",
        address: "Champaign, Il.",
    },
    {
        image: require('../assets/business-cafe.jpg'),
        name: "Johny Cafe",
        timing: "Open 11:00 am - 8:00pm",
        address: "Danvile, Il.",
    },
    {
        image: require('../assets/business-cleaning.jpg'),
        name: "Johnson Cleaning",
        timing: "Open 11:00 am - 8:00pm",
        address: "Champaign, Il.",
    },
    {
        image: require('../assets/business-cafe.jpg'),
        name: "Johny Cafe",
        timing: "Open 11:00 am - 8:00pm",
        address: "Danvile, Il.",
    },
    {
        image: require('../assets/business-cleaning.jpg'),
        name: "Johnson Cleaning",
        timing: "Open 11:00 am - 8:00pm",
        address: "Champaign, Il.",
    }
];

const BusinessImg = [
    {
        id: 1,
        image: require('../assets/business-cleaning.jpg'),
    },
    {
        id: 2,
        image: require('../assets/business-repair.jpg'),
    },
    {
        id: 3,
        image: require('../assets/business-grocery.jpg'),
    },
    {
        id: 4,
        image: require('../assets/business-cafe.jpg'),
    }
];

// const slidesData = [
//     {
//         id: 1,
//         title: "Banje Hutz",
//         subtitle: "Indian Restaurant",
//         image: require('../assets/food-banner1.jpg'),
//     },
//     {
//         id: 2,
//         title: "Pizza Hutz",
//         subtitle: "Pizza Restaurant",
//         image: require('../assets/food-banner2.jpg'),
//     },
//     {
//         id: 3,
//         title: "The GT Road Cafe",
//         subtitle: "Indian/Chinese Cafe",
//         image: require('../assets/food-banner3.jpg'),
//     },
// ];

const NearbyBusiness = (props) => {

    const { getBusinessList, businessList, sliderData1, sliderData2, 
        sliderData3, sliderData4, 
        sliderData5, sliderData6, 
        banner, getBusinessListSlide } = props;

    useEffect(() => {
        let lat = localStorage.getItem("latitude")
        let long = localStorage.getItem("longitude")
        let data = {
            latitude: parseFloat(lat),
            longitude: parseFloat(long),
            key: "nearbybusinesspage"
        }
        getBusinessList(data)
        getBusinessListSlide(data)

        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        });
    }, [getBusinessList, getBusinessListSlide])

    const navigate = useNavigate()
    let token = localStorage.getItem('token')
    let userInfo = JSON.parse(localStorage.getItem('userInfo'))

    const getRequiredAddress = (str, from) => {
        var arr = str.split(',');
        arr.splice(0, from);
        return arr.join(',');
    }
    var days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    var d = new Date();
    var dayName = days[d.getDay()];
    var dayString = `is_${dayName}_Open`

    const [callDetails, setCallDetails] = useState({
        country_code: '',
        mobile_no: ''
    })
    const [rating, setRating] = useState(0)
    const [ratingComment, setRatingComment] = useState('')
    const [selectedReviewBusiness, setSelectedBusiness] = useState({
        businessName: '',
        businessId: ''
    })
    const [couponDirection, setCouponDirection] = useState({
        destLat: '',
        desLong: '',
        businessName: '',
        address: ''
    })

    let city = localStorage.getItem('city');
    let state = localStorage.getItem('state');

    // pagination functionality start
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + 9;  //number here represent how many item you want to show on page
    const currentItems = businessList.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(businessList.length / 9);
    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * 9) % businessList.length;
        setItemOffset(newOffset);
    };
    //pagination functionality end

    const handleCouponClick = (business, type) => {
        if (token !== null) {
            setCallDetails({ country_code: business.country_code, mobile_no: business.mobile_no })
            setCouponDirection({
                destLat: business.latitude,
                desLong: business.longitude,
                businessName: business.name,
                address: business.address
            })
            if (type === 'coupon') {
                let couponData = {
                    businessId: business._id,
                    type: "coupon"
                }
                props.createAndGetCoupon(couponData)
            } else if (type === 'reward') {
                let rewardData = {
                    businessId: business._id,
                    type: "reward"
                }
                props.createAndGetCoupon(rewardData)
            }

        } else {
            navigate('/login')
        }
    }

    const handleCallClick = (business) => {
        setCallDetails({ country_code: business.country_code, mobile_no: business.mobile_no })
        let data = {
            businessId: business._id,
            clickType: 'call'
        }
        props.clickPerCount(data)
    }

    const handleEmailClick = (business) => {
        let token = localStorage.getItem('token')
        if (!token) {
            navigate('/login')
        } else {
            let businessInfo = {
                email: business.email,
                businessName: business.name,
                country_code: business.country_code,
                phone_no: business.phone_no,
                businessId: business._id
            }
            navigate(`/send-email-to-business/:${business.name.replaceAll(/ /g, "-").toLowerCase()}`, { state: businessInfo })
        }
    }

    const sendOnWebsite = (business, type, e) => {
        e.preventDefault()
        let data = {
            businessId: business._id,
            clickType: type
        }
        console.log(data, 'hello data')
        props.clickPerCount(data)
        window.open(`https://${business.website}`, '_blank', 'noopener,noreferrer')
    }

    //send on map page for routes
    const sendOnDirectionPage = (business) => {
        let addr = business.address ? getRequiredAddress(business.address, 3) : business.city
        let businessInfo = {
            destLat: business.latitude,
            desLong: business.longitude,
            businessName: business.name,
            address: addr
        }
        let data = {
            businessId: business._id,
            clickType: 'direction'
        }
        props.clickPerCount(data)
        navigate(`/direction-to-business/${addr.replaceAll(/ /g, "-").toLowerCase()}`, { state: businessInfo })
    }

    const sendOnDirectionPageForCouponModal = () => {
        let businessInfo = {
            destLat: couponDirection.destLat,
            desLong: couponDirection.desLong,
            businessName: couponDirection.businessName,
            address: couponDirection.address
        }
        navigate(`/direction-to-business/${couponDirection.address.replaceAll(/ /g, "-").toLowerCase()}`, { state: businessInfo })
    }
    const submitReview = () => {
        let creatorId = userInfo._id
        let data = {
            rating: rating,
            ratingFor: 'businessWebite',
            comment: ratingComment,
            creator_id: creatorId,
            receiver_id: selectedReviewBusiness.businessId
        }
        props.makeRating(data)
    }
    const checkRatingModal = (business) => {
        if (token !== null) {
            setSelectedBusiness({ businessName: business.name, businessId: business._id })
        } else {
            navigate('/login')
        }
    }

    // handle text click
    const handleTextClick = (business) => {
        let token = localStorage.getItem('token')
        if (!token) {
            navigate('/login')
        } else {
            let businessInfo = {
                email: business.email,
                businessName: business.name,
                country_code: business.country_code,
                phone_no: business.phone_no,
                businessId: business._id
            }
            navigate(`/send-text-message-to-business/:${business.name.replaceAll(/ /g, "-").toLowerCase()}`, { state: businessInfo })
        }
    }

    const handleBookAnAppointmentClick = (business) => {
        let businessInfo = {
            email: business.email,
            businessName: business.name,
            country_code: business.country_code,
            phone_no: business.phone_no,
            businessId: business._id,
            bookAppointmentService: business.bookAppointmentService
        }
        navigate(`/book-an-appointment/${business.name.replaceAll(/ /g, "-").toLowerCase()}`, { state: businessInfo })
    }

    // handle request a quote
    const handleRequestAQuoteClick = (business) => {
        let businessInfo = {
            email: business.email,
            businessName: business.name,
            country_code: business.country_code,
            phone_no: business.mobile_no,
            businessId: business._id,
            requestQuoteService: business.requestQuoteService
        }
        navigate(`/request-a-quote/${business.name.replaceAll(/ /g, "-").toLowerCase()}`, { state: businessInfo })
    }

    //handle business details on view details
    const handleBusinessDetails = (event, business) => {
        let addr = business.address ? getRequiredAddress(business.address, 1) : business.city ? business.city : business.name;
        let subcategoryname = business.subCategory.length > 0 ? business.subCategory[0].subCategoryName : "subcategory"
        navigate(`/business-details/${business.name}/${subcategoryname}/${addr}`, { state: { businessId: business._id } })
    }

    return (
        <div className='nearby-business-sec pt-4 pb-5'>
            <Helmet>
                <title>Nearby Business</title>
            </Helmet>

            {/* Start: Common Slider Code */}
            <div className='common-banner-wrapper mb-5 mt-n4'>
            {
                banner && banner.length > 0 ? 
                <Swiper
                    slidesPerView={1}
                    loop={true}
                    effect={'fade'}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Autoplay, EffectFade, Pagination]}
                    className="mySwiper custom-dots"
                >
                    {banner.map((slide) => (
                        <SwiperSlide key={slide._id}>
                            <div className="slide d-flex align-items-center bg-contain" style={{ backgroundImage: `url(${slide.image})`, }} >
                                <div className="container">
                                    <div className="content-wrapper text-light rounded-3 p-3 p-md-4">
                                        <div className="company-name fs-2 fw-bold lh-sm mb-1">{slide.title}</div>
                                        <div className="company-subtitle fs-6 fw-normal mb-3">{slide.subCategory.length > 0 ? slide.subCategory[0].subCategoryName : ''}</div>
                                        <div className="company-subtitle fs-6 fw-normal mb-3">{slide.ad_message}</div>
                                        {/* <div className="discount-badge">20% off</div> */}
                                        {slide.isCouponSelect ? <div className='discount-tag bg-danger rounded-circle d-flex align-items-center justify-content-center text-white small position-absolute p-2 lh-sm'>{slide.couponText.split(',')[0]}</div> : ''}
                                        {/* <div className="button-group row g-2">
                                            <div className="col-6">
                                                <button className="btn btn-warning w-100 px-3">Request a Quote</button>
                                            </div>
                                            <div className="col-6">
                                                <button className="btn btn-success w-100 px-3">Book Appointment</button>
                                            </div>
                                            <div className="col-12">
                                                <button className="btn btn-danger w-100 px-3">Coupon Message</button>
                                            </div>
                                        </div> */}
                                        <div className='action d-flex flex-wrap gap-2 mb-3'>
                                                        {slide.isPhoneSelect ? 
                                                            <Link type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleCallClick(slide)}><i className="bi bi-telephone-fill text-primary me-2"></i>Call
                                                            </Link>
                                                        : ''}
                                                        {slide.isEmailSelect ? <button type='button' className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleEmailClick(slide)}><i className="bi bi-envelope-fill text-primary me-2"></i>Email</button> : ''}
                                                        {slide.isWebsiteSelect ? <Link type="button" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnWebsite(slide, 'website')} ><i className="bi bi-globe-americas text-primary me-2"></i>Website</Link> : ''}
                                                        {slide.isDirectionSelect ? <button type="button" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnDirectionPage(slide)}><i className="bi bi-reply-fill text-primary me-2"></i>Directions</button> : ''}
                                                        {slide.isOrderNowSelect ? <Link to={'#'} className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnWebsite(slide, 'order')}><img src={Cutlery} alt='cutlery' className="text-primary me-2" />Order</Link> : ''}
                                                        {slide.isTextSelect ? <button className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleTextClick(slide)}> <i className="bi bi-pencil-fill text-primary me-2"></i>Text</button> : ''}
                                                        {/* <Link type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-telephone-fill text-primary me-1"></i>Call
                                                        </Link> */}
                                                        
                                                        {/* <Link type='button' className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-envelope-fill text-primary me-1"></i>Email
                                                        </Link>
                                                        <Link type="button" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-globe-americas text-primary me-1"></i>Website
                                                        </Link>
                                                        <Link type="button" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-reply-fill text-primary me-1"></i>Directions
                                                        </Link>
                                                        <Link to={'#'} className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <img src={Cutlery} alt='cutlery' className="text-primary me-1" />Order
                                                        </Link>
                                                        <Link className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-pencil-fill text-primary me-1"></i>Text
                                                        </Link> */}
                                                    </div>
                                        {slide.isCouponSelect ? <div className="discount-badge">{slide.couponText.split(',')[0]}</div> : ''}
                                        <div className="button-group row g-2">
                                        {(slide.isCallForQuoteSelect || slide.isBookForAppointmentSelect) ?
                                            <>
                                                {slide.isCallForQuoteSelect ? <div className="col-6">
                                                    <button className="btn btn-warning w-100 px-3" onClick={() => handleRequestAQuoteClick(slide)}>Request a Quote</button>
                                                </div> : '' }
                                                {slide.isBookForAppointmentSelect ? <div className="col-6">
                                                    <button className="btn btn-success w-100 px-3" onClick={() => handleBookAnAppointmentClick(slide)}>Book Appointment</button>
                                                </div>: '' }
                                            </>:''}
                                        </div>
                                        <div className="sponserd text-white fw-medium mt-2">Sponsored Ad</div>
                                    </div>
                                </div>
                            </div>
                            </SwiperSlide>
                        ))}</Swiper> 
                    : <div className='fs-3 text-danger text-center'>
                        *Promote your business here!* 
                        Take advantage of this prime ad space and grow your brand. Place your sponsored ad today!
                    </div>}
            </div>
            {/* End: Common Slider Code */}

            <div className='container-fluid'>
                <div className='section-header text-center mb-4'>
                    <div className='section-label fs-1'>{`Top 10 best nearby businesses ${city && state ? `in ${city},${state}` : 'near me'}`} </div>
                    <div className='section-sublabel text-dark text-opacity-75'>Find what you’re looking for closer than you think. With Nearby Business, You’ll never be to far away.</div>
                </div>

                <div className="row g-3">
                    <div className="col-lg-3 col-xl-2">
                        {/* Start: Common Sidebar Ads Code*/}
                        <div className="ads-wrapper d-flex flex-column gap-3">
                            <div className='common-banner-wrapper common-sidebar-banner'>
                            {sliderData1 && sliderData1.length > 0 ? 
                                <Swiper
                                    slidesPerView={1}
                                    loop={true}
                                    effect={'fade'}
                                    autoplay={{
                                        delay: 5000,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Autoplay, EffectFade, Pagination]}
                                    className="mySwiper custom-dots"
                                >
                                    {sliderData1.map((slide) => (
                                        <SwiperSlide key={slide._id}>
                                            <div className="slide d-flex align-items-end bg-contain" style={{ backgroundImage: `url(${slide.image})`, }} >
                                                {slide.isCouponSelect ? <div className='discount-tag bg-danger rounded-circle d-flex align-items-center justify-content-center text-white small position-absolute p-2 lh-sm'>{slide.couponText.split(',')[0]}</div> : ''}
                                                <div className="content-wrapper text-light rounded-3 mb-5 p-2">
                                                    <div className="company-name fs-4 fw-bold lh-sm mb-1">{slide.title}</div>
                                                    <div className="company-subtitle fs-6 fw-normal mb-3">{slide.subCategory.length > 0 ? slide.subCategory[0].subCategoryName : ''}</div>
                                                    <div className="company-subtitle fs-6 fw-normal mb-3">{slide.ad_message}</div>
                                                    <div className='action d-flex flex-wrap gap-2 mb-3'>
                                                        {slide.isPhoneSelect ? 
                                                            <Link type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleCallClick(slide)}><i className="bi bi-telephone-fill text-primary me-2"></i>Call
                                                            </Link>
                                                        : ''}
                                                        {slide.isEmailSelect ? <button type='button' className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleEmailClick(slide)}><i className="bi bi-envelope-fill text-primary me-2"></i>Email</button> : ''}
                                                        {slide.isWebsiteSelect ? <Link type="button" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnWebsite(slide, 'website')} ><i className="bi bi-globe-americas text-primary me-2"></i>Website</Link> : ''}
                                                        {slide.isDirectionSelect ? <button type="button" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnDirectionPage(slide)}><i className="bi bi-reply-fill text-primary me-2"></i>Directions</button> : ''}
                                                        {slide.isOrderNowSelect ? <Link to={'#'} className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnWebsite(slide, 'order')}><img src={Cutlery} alt='cutlery' className="text-primary me-2" />Order</Link> : ''}
                                                        {slide.isTextSelect ? <button className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleTextClick(slide)}> <i className="bi bi-pencil-fill text-primary me-2"></i>Text</button> : ''}
                                                        {/* <Link type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-telephone-fill text-primary me-1"></i>Call
                                                        </Link> */}
                                                        
                                                        {/* <Link type='button' className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-envelope-fill text-primary me-1"></i>Email
                                                        </Link>
                                                        <Link type="button" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-globe-americas text-primary me-1"></i>Website
                                                        </Link>
                                                        <Link type="button" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-reply-fill text-primary me-1"></i>Directions
                                                        </Link>
                                                        <Link to={'#'} className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <img src={Cutlery} alt='cutlery' className="text-primary me-1" />Order
                                                        </Link>
                                                        <Link className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-pencil-fill text-primary me-1"></i>Text
                                                        </Link> */}
                                                    </div>
                                                    <div className="button-group d-flex flex-column gap-1">
                                                        {/* <button className='btn w-100 text-white lh-sm px-3' style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)' }} >Click here for a coupon</button> */}
                                                        {slide.isCouponSelect ? slide.couponText.includes('rewards') ?
                                                            <div type="button" className='text-white shadow p-3 w-100 mb-2 mt-2'
                                                                data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#rewardModal" : ''}
                                                                style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }}
                                                                onClick={() => handleCouponClick(slide, 'reward')}
                                                            >
                                                                {slide.couponText ? slide.couponText.split(',')[1] : 'Click here for the deal min order $30'}
                                                            </div>
                                                            :
                                                            <div type="button" className='text-white shadow p-3 w-100 mb-2 mt-2'
                                                                data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#couponModal" : ''}
                                                                style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }}
                                                                onClick={() => handleCouponClick(slide, 'coupon')}
                                                            >
                                                                {slide.couponText ? slide.couponText.split(',')[1] : 'Click here for the deal min order $30'}
                                                            </div>
                                                        : ''}

                                                        {(slide.isCallForQuoteSelect || slide.isBookForAppointmentSelect) ?
                                                            <>
                                                                {slide.isCallForQuoteSelect ?
                                                                    <button className="btn btn-warning w-100 px-3" onClick={() => handleRequestAQuoteClick(slide)}>Request a Quote</button>
                                                                : '' }
                                                                {slide.isBookForAppointmentSelect ?
                                                                    <button className="btn btn-success w-100 px-3" onClick={() => handleBookAnAppointmentClick(slide)}>Book Appointment</button>
                                                                : '' }
                                                            </>:''}
                                                        {/* <button className="btn btn-danger w-100 px-3">Coupon Message</button> */}
                                                    </div>
                                                    <div class="sponserd text-white fw-medium mt-2">Sponsored Ad</div>
                                                </div>
                                            </div>
                                            </SwiperSlide>
                                    ))}</Swiper> 
                                : <div className='fs-3 text-danger text-center'>
                                    *Promote your business here!* 
                                    Take advantage of this prime ad space and grow your brand. Place your sponsored ad today!
                                </div>}
                            </div>

                            {/* Second Ads */}
                            <div className='common-banner-wrapper common-sidebar-banner'>
                            {sliderData3 && sliderData3.length > 0 ? 
                                <Swiper
                                    slidesPerView={1}
                                    loop={true}
                                    effect={'fade'}
                                    autoplay={{
                                        delay: 5000,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Autoplay, EffectFade, Pagination]}
                                    className="mySwiper custom-dots"
                                >
                                    {sliderData3.map((slide) => (
                                        // console.log(slide.subCategory[0].subCategoryName)
                                        <SwiperSlide key={slide._id}>
                                            <div className="slide d-flex align-items-end bg-contain" style={{ backgroundImage: `url(${slide.image})`, }} >
                                                {slide.isCouponSelect ? <div className='discount-tag bg-danger rounded-circle d-flex align-items-center justify-content-center text-white small position-absolute p-2 lh-sm'>{slide.couponText.split(',')[0]}</div> : ''}
                                                <div className="content-wrapper text-light rounded-3 mb-5 p-2">
                                                    <div className="company-name fs-4 fw-bold lh-sm mb-1">{slide.title}</div>
                                                    <div className="company-subtitle fs-6 fw-normal mb-3">{slide.subCategory.length > 0 ? slide.subCategory[0].subCategoryName : ''}</div>
                                                    <div className="company-subtitle fs-6 fw-normal mb-3">{slide.ad_message}</div>
                                                    <div className='action d-flex flex-wrap gap-2 mb-3'>
                                                    {slide.isPhoneSelect ? 
                                                        <Link type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleCallClick(slide)}><i className="bi bi-telephone-fill text-primary me-2"></i>Call
                                                        </Link>
                                                    : ''}
                                                    {slide.isEmailSelect ? <button type='button' className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleEmailClick(slide)}><i className="bi bi-envelope-fill text-primary me-2"></i>Email</button> : ''}
                                                    {slide.isWebsiteSelect ? <Link type="button" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnWebsite(slide, 'website')} ><i className="bi bi-globe-americas text-primary me-2"></i>Website</Link> : ''}
                                                    {slide.isDirectionSelect ? <button type="button" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnDirectionPage(slide)}><i className="bi bi-reply-fill text-primary me-2"></i>Directions</button> : ''}
                                                    {slide.isOrderNowSelect ? <Link to={'#'} className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnWebsite(slide, 'order')}><img src={Cutlery} alt='cutlery' className="text-primary me-2" />Order</Link> : ''}
                                                    {slide.isTextSelect ? <button className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleTextClick(slide)}> <i className="bi bi-pencil-fill text-primary me-2"></i>Text</button> : ''}
                                                    {/* <Link type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                        <i className="bi bi-telephone-fill text-primary me-1"></i>Call
                                                    </Link> */}
                                                    
                                                    {/* <Link type='button' className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                        <i className="bi bi-envelope-fill text-primary me-1"></i>Email
                                                    </Link>
                                                    <Link type="button" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                        <i className="bi bi-globe-americas text-primary me-1"></i>Website
                                                    </Link>
                                                    <Link type="button" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                        <i className="bi bi-reply-fill text-primary me-1"></i>Directions
                                                    </Link>
                                                    <Link to={'#'} className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                        <img src={Cutlery} alt='cutlery' className="text-primary me-1" />Order
                                                    </Link>
                                                    <Link className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                        <i className="bi bi-pencil-fill text-primary me-1"></i>Text
                                                    </Link> */}
                                                    </div>
                                                    <div className="button-group d-flex flex-column gap-1">
                                                        {/* <button className='btn w-100 text-white lh-sm px-3' style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)' }} >Click here for a coupon</button> */}
                                                        {slide.isCouponSelect ? slide.couponText.includes('rewards') ?
                                                            <div type="button" className='text-white shadow p-3 w-100 mb-2 mt-2'
                                                                data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#rewardModal" : ''}
                                                                style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }}
                                                                onClick={() => handleCouponClick(slide, 'reward')}
                                                            >
                                                                {slide.couponText ? slide.couponText.split(',')[1] : 'Click here for the deal min order $30'}
                                                            </div>
                                                            :
                                                            <div type="button" className='text-white shadow p-3 w-100 mb-2 mt-2'
                                                                data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#couponModal" : ''}
                                                                style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }}
                                                                onClick={() => handleCouponClick(slide, 'coupon')}
                                                            >
                                                                {slide.couponText ? slide.couponText.split(',')[1] : 'Click here for the deal min order $30'}
                                                            </div>
                                                        : ''}
                                                        {(slide.isCallForQuoteSelect || slide.isBookForAppointmentSelect) ?
                                                            <>
                                                            {slide.isCallForQuoteSelect ?
                                                                <button className="btn btn-warning w-100 px-3" onClick={() => handleRequestAQuoteClick(slide)}>Request a Quote</button>
                                                            : '' }
                                                            {slide.isBookForAppointmentSelect ?
                                                                <button className="btn btn-success w-100 px-3" onClick={() => handleBookAnAppointmentClick(slide)}>Book Appointment</button>
                                                            : '' }
                                                        </>:''}
                                                        {/* <button className="btn btn-danger w-100 px-3">Coupon Message</button> */}
                                                    </div>
                                                    <div class="sponserd text-white fw-medium mt-2">Sponsored Ad</div>
                                                </div>
                                            </div>
                                            </SwiperSlide>
                                    ))}</Swiper> 
                                : <div className='fs-3 text-danger text-center'>
                                    *Promote your business here!* 
                                    Take advantage of this prime ad space and grow your brand. Place your sponsored ad today!
                                </div>}
                            </div>

                            <div className='common-banner-wrapper common-sidebar-banner'>
                                {sliderData5 && sliderData5.length > 0 ? 
                                <Swiper
                                    slidesPerView={1}
                                    loop={true}
                                    effect={'fade'}
                                    autoplay={{
                                        delay: 5000,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Autoplay, EffectFade, Pagination]}
                                    className="mySwiper custom-dots"
                                >
                                    {sliderData5.map((slide) => (
                                        // console.log(slide.subCategory[0].subCategoryName)
                                        <SwiperSlide key={slide._id}>
                                            <div className="slide d-flex align-items-end bg-contain" style={{ backgroundImage: `url(${slide.image})`, }} >
                                                {slide.isCouponSelect ? <div className='discount-tag bg-danger rounded-circle d-flex align-items-center justify-content-center text-white small position-absolute p-2 lh-sm'>{slide.couponText.split(',')[0]}</div> : ''}
                                                <div className="content-wrapper text-light rounded-3 mb-5 p-2">
                                                    <div className="company-name fs-4 fw-bold lh-sm mb-1">{slide.title}</div>
                                                    <div className="company-subtitle fs-6 fw-normal mb-3">{slide.subCategory.length > 0 ? slide.subCategory[0].subCategoryName : ''}</div>
                                                    <div className="company-subtitle fs-6 fw-normal mb-3">{slide.ad_message}</div>
                                                    <div className='action d-flex flex-wrap gap-2 mb-3'>
                                                    {slide.isPhoneSelect ? 
                                                        <Link type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleCallClick(slide)}><i className="bi bi-telephone-fill text-primary me-2"></i>Call
                                                        </Link>
                                                    : ''}
                                                    {slide.isEmailSelect ? <button type='button' className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleEmailClick(slide)}><i className="bi bi-envelope-fill text-primary me-2"></i>Email</button> : ''}
                                                    {slide.isWebsiteSelect ? <Link type="button" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnWebsite(slide, 'website')} ><i className="bi bi-globe-americas text-primary me-2"></i>Website</Link> : ''}
                                                    {slide.isDirectionSelect ? <button type="button" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnDirectionPage(slide)}><i className="bi bi-reply-fill text-primary me-2"></i>Directions</button> : ''}
                                                    {slide.isOrderNowSelect ? <Link to={'#'} className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnWebsite(slide, 'order')}><img src={Cutlery} alt='cutlery' className="text-primary me-2" />Order</Link> : ''}
                                                    {slide.isTextSelect ? <button className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleTextClick(slide)}> <i className="bi bi-pencil-fill text-primary me-2"></i>Text</button> : ''}
                                                    {/* <Link type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                        <i className="bi bi-telephone-fill text-primary me-1"></i>Call
                                                    </Link> */}
                                                    
                                                    {/* <Link type='button' className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                        <i className="bi bi-envelope-fill text-primary me-1"></i>Email
                                                    </Link>
                                                    <Link type="button" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                        <i className="bi bi-globe-americas text-primary me-1"></i>Website
                                                    </Link>
                                                    <Link type="button" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                        <i className="bi bi-reply-fill text-primary me-1"></i>Directions
                                                    </Link>
                                                    <Link to={'#'} className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                        <img src={Cutlery} alt='cutlery' className="text-primary me-1" />Order
                                                    </Link>
                                                    <Link className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                        <i className="bi bi-pencil-fill text-primary me-1"></i>Text
                                                    </Link> */}
                                                    </div>
                                                    <div className="button-group d-flex flex-column gap-1">
                                                        {/* <button className='btn w-100 text-white lh-sm px-3' style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)' }} >Click here for a coupon</button> */}
                                                        {slide.isCouponSelect ? slide.couponText.includes('rewards') ?
                                                            <div type="button" className='text-white shadow p-3 w-100 mb-2 mt-2'
                                                                data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#rewardModal" : ''}
                                                                style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }}
                                                                onClick={() => handleCouponClick(slide, 'reward')}
                                                            >
                                                                {slide.couponText ? slide.couponText.split(',')[1] : 'Click here for the deal min order $30'}
                                                            </div>
                                                            :
                                                            <div type="button" className='text-white shadow p-3 w-100 mb-2 mt-2'
                                                                data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#couponModal" : ''}
                                                                style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }}
                                                                onClick={() => handleCouponClick(slide, 'coupon')}
                                                            >
                                                                {slide.couponText ? slide.couponText.split(',')[1] : 'Click here for the deal min order $30'}
                                                            </div>
                                                        : ''}

                                                        {(slide.isCallForQuoteSelect || slide.isBookForAppointmentSelect) ?
                                                            <>
                                                            {slide.isCallForQuoteSelect ?
                                                                <button className="btn btn-warning w-100 px-3" onClick={() => handleRequestAQuoteClick(slide)}>Request a Quote</button>
                                                            : '' }
                                                            {slide.isBookForAppointmentSelect ?
                                                                <button className="btn btn-success w-100 px-3" onClick={() => handleBookAnAppointmentClick(slide)}>Book Appointment</button>
                                                            : '' }
                                                        </>:''}
                                                        {/* <button className="btn btn-danger w-100 px-3">Coupon Message</button> */}
                                                    </div>
                                                    <div class="sponserd text-white fw-medium mt-2">Sponsored Ad</div>
                                                </div>
                                            </div>
                                            </SwiperSlide>
                                        ))}</Swiper> 
                                    : <div className='fs-3 text-danger text-center'>
                                        *Promote your business here!* 
                                        Take advantage of this prime ad space and grow your brand. Place your sponsored ad today!
                                    </div>}
                            </div>
                        </div>
                        {/* End: Common Sidebar Ads Code*/}
                    </div>

                    <div className="col-lg-6 col-xl-8">
                        <div className='row g-3'>
                            {currentItems.length > 0 ?
                                currentItems.map((business, index) => (
                                    <div className='col-sm-6 col-xl-4 col-xxl-3' key={index}>
                                        <div className='business-details border rounded-3 overflow-hidden h-100' key={index}>
                                            <div className='business-img position-relative'>
                                                <Swiper
                                                    slidesPerView={1}
                                                    loop={true}
                                                    autoplay={{
                                                        delay: 2500,
                                                        disableOnInteraction: false,
                                                    }}
                                                    pagination={{
                                                        clickable: true,
                                                    }}
                                                    modules={[Autoplay, Pagination]}
                                                    className="mySwiper custom-dots"
                                                >
                                                    {
                                                        business.businessPicture && business.businessPicture.length > 0 ?
                                                            business.businessPicture.map((item, idx) => (
                                                                <SwiperSlide><img src={item.pictureUrl} key={idx} className='img-fluid' alt='Cleaning' /></SwiperSlide>
                                                            ))
                                                            :
                                                            BusinessImg.map((item, idx) => (
                                                                <SwiperSlide><img src={item.image} key={idx} className='img-fluid' alt='Cleaning' /></SwiperSlide>
                                                            ))
                                                    }
                                                </Swiper>
                                                {business.isCouponSelect ? <div className='discount-tag bg-danger rounded-circle d-flex align-items-center justify-content-center text-white small position-absolute p-2 lh-sm'>{business.couponText.split(',')[0]}</div> : ''}
                                                {business.isDealSelect ? <div className='discount-tag bg-danger rounded-circle d-flex align-items-center justify-content-center text-white small position-absolute p-2 lh-sm'>{business.dealText.split(',')[0]}</div> : ''}
                                            </div>
                                            <div className="business-info bg-white text-center p-3">
                                                <div className='name fs-18 fw-medium text-capitalize mb-1'>{business.name}</div>
                                                <div className='review-wrapper d-flex align-items-center justify-content-center gap-2 mb-1'>
                                                    <div className='rating-number'>{business.rating > 0 ? business.rating.toFixed(1) : 5}</div>
                                                    <div className='review-star d-flex justify-content-center gap-1'>
                                                        {business.rating > 0 ?
                                                            <Rating initialValue={business.rating} size={"20px"} readonly={true} allowFraction={true} />
                                                            :
                                                            <Rating initialValue={5} size={"20px"} readonly={true} />
                                                        }
                                                    </div>
                                                    <div className='rating-number'>{business.ratingCount > 0 ? `(${business.ratingCount})` : '(1)'}</div>
                                                </div>
                                                <div className='name text-muted text-capitalize mb-1'>{business.subCategory.length > 0 ? business.subCategory[0].subCategoryName : ''}</div>
                                                <div className='timing text-dark text-opacity-75'>{business.business_weekdays[dayString] == true ? `Open till ${business.business_weekdays[dayName].close_time_hour}:${business.business_weekdays[dayName].close_time_min} ${business.business_weekdays[dayName].close_time_am_pm}` : 'Closed today'}</div>
                                                <div className='address text-muted text-truncate pb-1'>{business.address ? getRequiredAddress(business.address, 1) : business.city}</div>
                                                <div className='action d-flex flex-wrap justify-content-center gap-2 mt-2'>
                                                    {business.isPhoneSelect ? <button type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleCallClick(business)}><i className="bi bi-telephone-fill text-primary me-2"></i>Call</button> : ''}
                                                    {business.isEmailSelect ? <button type='button' className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleEmailClick(business)}><i className="bi bi-envelope-fill text-primary me-2"></i>Email</button> : ''}
                                                    {business.isWebsiteSelect ? <button type="button" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={(e) => sendOnWebsite(business, 'website', e)} ><i className="bi bi-globe-americas text-primary me-2"></i>Website</button> : ''}
                                                    {business.isDirectionSelect ? <button type="button" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnDirectionPage(business)}><i className="bi bi-reply-fill text-primary me-2"></i>Directions</button> : ''}
                                                    {business.isOrderNowSelect ? <button className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={(e) => sendOnWebsite(business, 'order', e)}><img src={Cutlery} alt='cutlery' className="text-primary me-2" />Order</button> : ''}
                                                    {business.isTextSelect ? <button className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleTextClick(business)}> <i className="bi bi-pencil-fill text-primary me-2"></i>Text</button> : ''}
                                                </div>
                                                {business.isCouponSelect ? business.couponText.includes('rewards') ?
                                                    <div type="button" className='text-white shadow p-3 w-100 mb-2 mt-2'
                                                        data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#rewardModal" : ''}
                                                        style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }}
                                                        onClick={() => handleCouponClick(business, 'reward')}
                                                    >
                                                        {business.couponText ? business.couponText.split(',')[1] : 'Click here for the deal min order $30'}
                                                    </div>
                                                    :
                                                    <div type="button" className='text-white shadow p-3 w-100 mb-2 mt-2'
                                                        data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#couponModal" : ''}
                                                        style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }}
                                                        onClick={() => handleCouponClick(business, 'coupon')}
                                                    >
                                                        {business.couponText ? business.couponText.split(',')[1] : 'Click here for the deal min order $30'}
                                                    </div>
                                                    : ''}

                                                {business.isDealSelect ? <div className='text-white shadow p-3 w-100 mb-2 mt-2' style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }} >{business.dealText.split(',')[1]}</div> : ''}
                                                {(business.isCallForQuoteSelect || business.isBookForAppointmentSelect) ?
                                                    <div className='d-flex gap-2 mt-3 mb-2'>
                                                        {business.isCallForQuoteSelect ? <div type='button' className='d-flex align-items-center justify-content-center text-white px-3 py-2 flex-fill lh-sm' style={{ background: 'linear-gradient(to bottom right, #00cc66, #FF9A5A)', minHeight: '55px', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }} onClick={() => handleRequestAQuoteClick(business)}>Request a quote</div> : ''}
                                                        {business.isBookForAppointmentSelect ? <div type='button' className='d-flex align-items-center justify-content-center text-white px-3 py-2 flex-fill lh-sm' style={{ background: 'linear-gradient(to bottom right, #0073e6, #FF6666)', minHeight: '55px', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }}
                                                            onClick={() => handleBookAnAppointmentClick(business)}>Book An Appointment</div> : ''}
                                                    </div> : ''
                                                }
                                                <div className='write-review'>
                                                    <button className='btn btn-danger w-100 d-inline-flex align-items-center justify-content-center py-2' data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#reviewModal1" : ''} onClick={() => checkRatingModal(business)}><i className="bi bi-pencil me-2"></i>Write a review</button>
                                                </div>
                                                <button type='button' className='w-100 btn btn-primary mt-2' onClick={(event) => handleBusinessDetails(event, business)}>View Details</button>
                                            </div>
                                        </div>
                                    </div>
                                ))
                                :
                                <div className='fs-3 text-danger text-center'>
                                    There is no business in your area!
                                </div>
                            }
                            {/* pagination section */}
                            <div className='pagination-wrapper pt-3'>
                                <ReactPaginate
                                    className="pagination justify-content-center m-1"
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    nextLabel="&raquo;"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={1}
                                    marginPagesDisplayed={2}
                                    pageCount={pageCount}
                                    previousLabel="&laquo;"
                                    renderOnZeroPageCount={null}
                                    containerClassName="pagination"
                                    activeClassName="active"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-xl-2">
                        {/* Start: Common Sidebar Ads Code*/}
                        <div className="ads-wrapper d-flex flex-column gap-3">
                            <div className='common-banner-wrapper common-sidebar-banner'>
                                {sliderData2 && sliderData2.length > 0 ? 
                                <Swiper
                                    slidesPerView={1}
                                    loop={true}
                                    effect={'fade'}
                                    autoplay={{
                                        delay: 5000,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Autoplay, EffectFade, Pagination]}
                                    className="mySwiper custom-dots"
                                >
                                    {sliderData2.map((slide) => (
                                        <SwiperSlide key={slide._id}>
                                            <div className="slide d-flex align-items-end bg-contain" style={{ backgroundImage: `url(${slide.image})`, }} >
                                                {slide.isCouponSelect ? <div className='discount-tag bg-danger rounded-circle d-flex align-items-center justify-content-center text-white small position-absolute p-2 lh-sm'>{slide.couponText.split(',')[0]}</div> : ''}
                                                <div className="content-wrapper text-light rounded-3 mb-5 p-2">
                                                    <div className="company-name fs-4 fw-bold lh-sm mb-1">{slide.title}</div>
                                                    <div className="company-subtitle fs-6 fw-normal mb-3">{slide.subCategory.length > 0 ? slide.subCategory[0].subCategoryName : ''}</div>
                                                    <div className="company-subtitle fs-6 fw-normal mb-3">{slide.ad_message}</div>
                                                    <div className='action d-flex flex-wrap gap-2 mb-3'>
                                                    {slide.isPhoneSelect ? 
                                                        <Link type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleCallClick(slide)}><i className="bi bi-telephone-fill text-primary me-2"></i>Call
                                                        </Link>
                                                    : ''}
                                                    {slide.isEmailSelect ? <button type='button' className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleEmailClick(slide)}><i className="bi bi-envelope-fill text-primary me-2"></i>Email</button> : ''}
                                                    {slide.isWebsiteSelect ? <Link type="button" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnWebsite(slide, 'website')} ><i className="bi bi-globe-americas text-primary me-2"></i>Website</Link> : ''}
                                                    {slide.isDirectionSelect ? <button type="button" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnDirectionPage(slide)}><i className="bi bi-reply-fill text-primary me-2"></i>Directions</button> : ''}
                                                    {slide.isOrderNowSelect ? <Link to={'#'} className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnWebsite(slide, 'order')}><img src={Cutlery} alt='cutlery' className="text-primary me-2" />Order</Link> : ''}
                                                    {slide.isTextSelect ? <button className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleTextClick(slide)}> <i className="bi bi-pencil-fill text-primary me-2"></i>Text</button> : ''}
                                                    {/* <Link type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                        <i className="bi bi-telephone-fill text-primary me-1"></i>Call
                                                    </Link> */}
                                                    
                                                    {/* <Link type='button' className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                        <i className="bi bi-envelope-fill text-primary me-1"></i>Email
                                                    </Link>
                                                    <Link type="button" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                        <i className="bi bi-globe-americas text-primary me-1"></i>Website
                                                    </Link>
                                                    <Link type="button" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                        <i className="bi bi-reply-fill text-primary me-1"></i>Directions
                                                    </Link>
                                                    <Link to={'#'} className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                        <img src={Cutlery} alt='cutlery' className="text-primary me-1" />Order
                                                    </Link>
                                                    <Link className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                        <i className="bi bi-pencil-fill text-primary me-1"></i>Text
                                                    </Link> */}
                                                    </div>
                                                    <div className="button-group d-flex flex-column gap-1">
                                                        {/* <button className='btn w-100 text-white lh-sm px-3' style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)' }} >Click here for a coupon</button> */}
                                                        {slide.isCouponSelect ? slide.couponText.includes('rewards') ?
                                                            <div type="button" className='text-white shadow p-3 w-100 mb-2 mt-2'
                                                                data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#rewardModal" : ''}
                                                                style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }}
                                                                onClick={() => handleCouponClick(slide, 'reward')}
                                                            >
                                                                {slide.couponText ? slide.couponText.split(',')[1] : 'Click here for the deal min order $30'}
                                                            </div>
                                                            :
                                                            <div type="button" className='text-white shadow p-3 w-100 mb-2 mt-2'
                                                                data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#couponModal" : ''}
                                                                style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }}
                                                                onClick={() => handleCouponClick(slide, 'coupon')}
                                                            >
                                                                {slide.couponText ? slide.couponText.split(',')[1] : 'Click here for the deal min order $30'}
                                                            </div>
                                                            : ''}

                                                        {(slide.isCallForQuoteSelect || slide.isBookForAppointmentSelect) ?
                                                            <>
                                                            {slide.isCallForQuoteSelect ?
                                                                <button className="btn btn-warning w-100 px-3" onClick={() => handleRequestAQuoteClick(slide)}>Request a Quote</button>
                                                            : '' }
                                                            {slide.isBookForAppointmentSelect ?
                                                                <button className="btn btn-success w-100 px-3" onClick={() => handleBookAnAppointmentClick(slide)}>Book Appointment</button>
                                                            : '' }
                                                        </>:''}
                                                        {/* <button className="btn btn-danger w-100 px-3">Coupon Message</button> */}
                                                    </div>
                                                    <div class="sponserd text-white fw-medium mt-2">Sponsored Ad</div>
                                                </div>
                                            </div>
                                            </SwiperSlide>
                                    ))}</Swiper> 
                                : <div className='fs-3 text-danger text-center'>
                                    *Promote your business here!* 
                                    Take advantage of this prime ad space and grow your brand. Place your sponsored ad today!
                                </div>}
                            </div>

                            {/* Second Ads */}
                            <div className='common-banner-wrapper common-sidebar-banner'>
                                {sliderData4 && sliderData4.length > 0 ? 
                                <Swiper
                                    slidesPerView={1}
                                    loop={true}
                                    effect={'fade'}
                                    autoplay={{
                                        delay: 5000,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Autoplay, EffectFade, Pagination]}
                                    className="mySwiper custom-dots"
                                >
                                    {sliderData4.map((slide) => (
                                        <SwiperSlide key={slide._id}>
                                            <div className="slide d-flex align-items-end bg-contain" style={{ backgroundImage: `url(${slide.image})`, }} >
                                                {slide.isCouponSelect ? <div className='discount-tag bg-danger rounded-circle d-flex align-items-center justify-content-center text-white small position-absolute p-2 lh-sm'>{slide.couponText.split(',')[0]}</div> : ''}
                                                <div className="content-wrapper text-light rounded-3 mb-5 p-2">
                                                    <div className="company-name fs-4 fw-bold lh-sm mb-1">{slide.title}</div>
                                                    <div className="company-subtitle fs-6 fw-normal mb-3">{slide.subCategory.length > 0 ? slide.subCategory[0].subCategoryName : ''}</div>
                                                    <div className="company-subtitle fs-6 fw-normal mb-3">{slide.ad_message}</div>
                                                    <div className='action d-flex flex-wrap gap-2 mb-3'>
                                                    {slide.isPhoneSelect ? 
                                                        <Link type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleCallClick(slide)}><i className="bi bi-telephone-fill text-primary me-2"></i>Call
                                                        </Link>
                                                    : ''}
                                                    {slide.isEmailSelect ? <button type='button' className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleEmailClick(slide)}><i className="bi bi-envelope-fill text-primary me-2"></i>Email</button> : ''}
                                                    {slide.isWebsiteSelect ? <Link type="button" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnWebsite(slide, 'website')} ><i className="bi bi-globe-americas text-primary me-2"></i>Website</Link> : ''}
                                                    {slide.isDirectionSelect ? <button type="button" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnDirectionPage(slide)}><i className="bi bi-reply-fill text-primary me-2"></i>Directions</button> : ''}
                                                    {slide.isOrderNowSelect ? <Link to={'#'} className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnWebsite(slide, 'order')}><img src={Cutlery} alt='cutlery' className="text-primary me-2" />Order</Link> : ''}
                                                    {slide.isTextSelect ? <button className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleTextClick(slide)}> <i className="bi bi-pencil-fill text-primary me-2"></i>Text</button> : ''}
                                                    {/* <Link type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                        <i className="bi bi-telephone-fill text-primary me-1"></i>Call
                                                    </Link> */}
                                                    
                                                    {/* <Link type='button' className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                        <i className="bi bi-envelope-fill text-primary me-1"></i>Email
                                                    </Link>
                                                    <Link type="button" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                        <i className="bi bi-globe-americas text-primary me-1"></i>Website
                                                    </Link>
                                                    <Link type="button" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                        <i className="bi bi-reply-fill text-primary me-1"></i>Directions
                                                    </Link>
                                                    <Link to={'#'} className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                        <img src={Cutlery} alt='cutlery' className="text-primary me-1" />Order
                                                    </Link>
                                                    <Link className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                        <i className="bi bi-pencil-fill text-primary me-1"></i>Text
                                                    </Link> */}
                                                    </div>
                                                    <div className="button-group d-flex flex-column gap-1">
                                                        {/* <button className='btn w-100 text-white lh-sm px-3' style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)' }} >Click here for a coupon</button> */}
                                                        {slide.isCouponSelect ? slide.couponText.includes('rewards') ?
                                                            <div type="button" className='text-white shadow p-3 w-100 mb-2 mt-2'
                                                                data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#rewardModal" : ''}
                                                                style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }}
                                                                onClick={() => handleCouponClick(slide, 'reward')}
                                                            >
                                                                {slide.couponText ? slide.couponText.split(',')[1] : 'Click here for the deal min order $30'}
                                                            </div>
                                                            :
                                                            <div type="button" className='text-white shadow p-3 w-100 mb-2 mt-2'
                                                                data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#couponModal" : ''}
                                                                style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }}
                                                                onClick={() => handleCouponClick(slide, 'coupon')}
                                                            >
                                                                {slide.couponText ? slide.couponText.split(',')[1] : 'Click here for the deal min order $30'}
                                                            </div>
                                                        : ''}

                                                        {(slide.isCallForQuoteSelect || slide.isBookForAppointmentSelect) ?
                                                            <>
                                                            {slide.isCallForQuoteSelect ?
                                                                <button className="btn btn-warning w-100 px-3" onClick={() => handleRequestAQuoteClick(slide)}>Request a Quote</button>
                                                            : '' }
                                                            {slide.isBookForAppointmentSelect ?
                                                                <button className="btn btn-success w-100 px-3" onClick={() => handleBookAnAppointmentClick(slide)}>Book Appointment</button>
                                                            : '' }
                                                        </>:''}
                                                        {/* <button className="btn btn-danger w-100 px-3">Coupon Message</button> */}
                                                    </div>
                                                    <div class="sponserd text-white fw-medium mt-2">Sponsored Ad</div>
                                                </div>
                                            </div>
                                            </SwiperSlide>
                                    ))}</Swiper> 
                                : <div className='fs-3 text-danger text-center'>
                                    *Promote your business here!* 
                                    Take advantage of this prime ad space and grow your brand. Place your sponsored ad today!
                                </div>}
                            </div>
                            <div className='common-banner-wrapper common-sidebar-banner'>
                                {sliderData6 && sliderData6.length > 0 ? 
                                <Swiper
                                    slidesPerView={1}
                                    loop={true}
                                    effect={'fade'}
                                    autoplay={{
                                        delay: 5000,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Autoplay, EffectFade, Pagination]}
                                    className="mySwiper custom-dots"
                                >
                                    {sliderData6.map((slide) => (
                                        <SwiperSlide key={slide._id}>
                                            <div className="slide d-flex align-items-end bg-contain" style={{ backgroundImage: `url(${slide.image})`, }} >
                                                {slide.isCouponSelect ? <div className='discount-tag bg-danger rounded-circle d-flex align-items-center justify-content-center text-white small position-absolute p-2 lh-sm'>{slide.couponText.split(',')[0]}</div> : ''}
                                                <div className="content-wrapper text-light rounded-3 mb-5 p-2">
                                                    <div className="company-name fs-4 fw-bold lh-sm mb-1">{slide.title}</div>
                                                    <div className="company-subtitle fs-6 fw-normal mb-3">{slide.subCategory.length > 0 ? slide.subCategory[0].subCategoryName : ''}</div>
                                                    <div className="company-subtitle fs-6 fw-normal mb-3">{slide.ad_message}</div>
                                                    <div className='action d-flex flex-wrap gap-2 mb-3'>
                                                    {slide.isPhoneSelect ? 
                                                        <Link type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleCallClick(slide)}><i className="bi bi-telephone-fill text-primary me-2"></i>Call
                                                        </Link>
                                                    : ''}
                                                    {slide.isEmailSelect ? <button type='button' className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleEmailClick(slide)}><i className="bi bi-envelope-fill text-primary me-2"></i>Email</button> : ''}
                                                    {slide.isWebsiteSelect ? <Link type="button" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnWebsite(slide, 'website')} ><i className="bi bi-globe-americas text-primary me-2"></i>Website</Link> : ''}
                                                    {slide.isDirectionSelect ? <button type="button" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnDirectionPage(slide)}><i className="bi bi-reply-fill text-primary me-2"></i>Directions</button> : ''}
                                                    {slide.isOrderNowSelect ? <Link to={'#'} className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnWebsite(slide, 'order')}><img src={Cutlery} alt='cutlery' className="text-primary me-2" />Order</Link> : ''}
                                                    {slide.isTextSelect ? <button className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleTextClick(slide)}> <i className="bi bi-pencil-fill text-primary me-2"></i>Text</button> : ''}
                                                    {/* <Link type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                        <i className="bi bi-telephone-fill text-primary me-1"></i>Call
                                                    </Link> */}
                                                    
                                                    {/* <Link type='button' className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                        <i className="bi bi-envelope-fill text-primary me-1"></i>Email
                                                    </Link>
                                                    <Link type="button" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                        <i className="bi bi-globe-americas text-primary me-1"></i>Website
                                                    </Link>
                                                    <Link type="button" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                        <i className="bi bi-reply-fill text-primary me-1"></i>Directions
                                                    </Link>
                                                    <Link to={'#'} className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                        <img src={Cutlery} alt='cutlery' className="text-primary me-1" />Order
                                                    </Link>
                                                    <Link className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                        <i className="bi bi-pencil-fill text-primary me-1"></i>Text
                                                    </Link> */}
                                                    </div>
                                                    <div className="button-group d-flex flex-column gap-1">
                                                        {/* <button className='btn w-100 text-white lh-sm px-3' style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)' }} >Click here for a coupon</button> */}
                                                        {slide.isCouponSelect ? slide.couponText.includes('rewards') ?
                                                            <div type="button" className='text-white shadow p-3 w-100 mb-2 mt-2'
                                                                data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#rewardModal" : ''}
                                                                style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }}
                                                                onClick={() => handleCouponClick(slide, 'reward')}
                                                            >
                                                                {slide.couponText ? slide.couponText.split(',')[1] : 'Click here for the deal min order $30'}
                                                            </div>
                                                            :
                                                            <div type="button" className='text-white shadow p-3 w-100 mb-2 mt-2'
                                                                data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#couponModal" : ''}
                                                                style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }}
                                                                onClick={() => handleCouponClick(slide, 'coupon')}
                                                            >
                                                                {slide.couponText ? slide.couponText.split(',')[1] : 'Click here for the deal min order $30'}
                                                            </div>
                                                        : ''}

                                                        {(slide.isCallForQuoteSelect || slide.isBookForAppointmentSelect) ?
                                                            <>
                                                            {slide.isCallForQuoteSelect ?
                                                                <button className="btn btn-warning w-100 px-3" onClick={() => handleRequestAQuoteClick(slide)}>Request a Quote</button>
                                                            : '' }
                                                            {slide.isBookForAppointmentSelect ?
                                                                <button className="btn btn-success w-100 px-3" onClick={() => handleBookAnAppointmentClick(slide)}>Book Appointment</button>
                                                            : '' }
                                                        </>:''}
                                                        {/* <button className="btn btn-danger w-100 px-3">Coupon Message</button> */}
                                                    </div>
                                                    <div class="sponserd text-white fw-medium mt-2">Sponsored Ad</div>
                                                </div>
                                            </div>
                                            </SwiperSlide>
                                        ))}</Swiper> 
                                    : <div className='fs-3 text-danger text-center'>
                                        *Promote your business here!* 
                                        Take advantage of this prime ad space and grow your brand. Place your sponsored ad today!
                                    </div>}
                            </div>
                        </div>
                        {/* End: Common Sidebar Ads Code*/}
                    </div>
                </div>
            </div>
            {/* infoDetails Modal */}
            <div className="modal fade" id="infoDetails" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="infoDetailsLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm">
                    <div className="modal-content">
                        <div className="modal-body text-center p-4">
                            <div className="link-item mb-3">
                                <Link to={`tel:${callDetails.country_code + callDetails.mobile_no}`} className='fs-18 text-decoration-none text-primary'>
                                    <i className="bi bi-telephone-fill text-primary me-2"></i> {callDetails.country_code + callDetails.mobile_no}
                                </Link>
                            </div>
                            <button type="button" className="btn btn-secondary px-5" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Review Model Popup */}
            <div className="modal fade" id="reviewModal1" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="reviewModal1Label" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header bg-primary text-white px-sm-4">
                            <h1 className="modal-title fs-18 text-capitalize lh-sm" id="reviewModal1Label">{selectedReviewBusiness.businessName}</h1>
                            <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" onClick={() => setRating(0)}></button>
                        </div>
                        <div className="modal-body px-3 px-sm-4 py-4">
                            <div className="review-wrapper mb-2">
                                <div className="star-wrapper d-flex gap-2 fs-5 mb-1">
                                    <Rating emptySymbol="bi bi-star" fullSymbol="bi bi-star-fill"
                                        initialValue={rating}
                                        value={rating}
                                        onClick={(value) => setRating(value)}
                                    />
                                </div>
                                <p className='m-0 text-muted'>Write your review for <small className='fw-medium text-capitalize'>{selectedReviewBusiness.businessName}</small></p>
                            </div>
                            <div className="review-message">
                                <textarea className="form-control small" id="dealMessage" rows="5" placeholder='Enter message here...' required
                                    onChange={(event) => setRatingComment(event.target.value)}
                                ></textarea>
                                <div className='invalid-feedback'></div>
                            </div>
                            <button className='w-100 btn btn-primary py-2 mt-3' data-bs-dismiss="modal" onClick={() => submitReview()}>Submit Review</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* coupon code with call and direction modal */}
            <div className="modal fade" id="couponModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="couponModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header bg-primary text-white">
                            <h1 className="modal-title fs-18 text-capitalize lh-sm" id="couponModalLabel">Promo Code</h1>
                            <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body px-3 py-4">
                            <p className='fs-18 mb-0'>
                                Promo Code: <span className='fw-medium text-muted'>{props.couponDetails.coupon}</span>
                            </p>
                            <p className='fs-18 mb-2'>
                                Expire On: <span className='fw-medium text-muted'>{props.couponDetails.expiryDate ? new Date(props.couponDetails.expiryDate).toLocaleDateString('en-us') : ''}</span>
                            </p>
                            <p>
                                Makes sure you present your code to the cashier to redeem your coupon or when using the phone to redeem you coupon, read out the coupon code to the cashier and you successful redeem your coupon. ONE PROMO CODE PER ORDER (per purchase or per service). Multiple promo code per order will not be allowed.
                            </p>
                            <div className='d-flex gap-2'>
                                <button type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='w-50 bg-light border rounded-pill text-center py-2 px-3'><i className="bi bi-telephone-fill text-primary me-2"></i>Call</button>
                                <button type="button" className='w-50 bg-light border rounded-pill text-center py-2 px-3' data-bs-dismiss="modal" onClick={() => sendOnDirectionPageForCouponModal()}><i className="bi bi-reply-fill text-primary me-2"></i>Directions</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {/* reward with call and direction modal */}
            <div className="modal fade" id="rewardModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="rewardModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header bg-primary text-white">
                            <h1 className="modal-title fs-18 text-capitalize lh-sm" id="rewardModalLabel">Your Reward</h1>
                            <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body px-3 py-4">
                            <p className='fs-18 mb-0'>
                                Reward: <span className='fw-medium text-muted  mb-2'><Rating initialValue={props.couponDetails && props.couponDetails.rewardStar ? props.couponDetails.rewardStar : 0} size={"20px"} readonly={true} allowFraction={true} /></span>
                            </p>
                            <p className='fs-18 mb-2'>
                                Expire On: <span className='fw-medium text-muted'>{props.couponDetails.expiryDate ? new Date(props.couponDetails.expiryDate).toLocaleDateString('en-us') : ''}</span>
                            </p>
                            <p>
                                Make sure you present your rewards to the cashier to earn your points (stars) and redeem your credit or when ordering online, mention your name to the cashier to earn your star (point) and redeem your credit.
                            </p>
                            <div className='d-flex gap-2'>
                                <button type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='w-50 bg-light border rounded-pill text-center py-2 px-3'><i className="bi bi-telephone-fill text-primary me-2"></i>Call</button>
                                <button type="button" className='w-50 bg-light border rounded-pill text-center py-2 px-3' data-bs-dismiss="modal" onClick={() => sendOnDirectionPageForCouponModal()}><i className="bi bi-reply-fill text-primary me-2"></i>Directions</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {/* loader section */}
            {props.loading ?
                <div className='position-fixed top-0 start-0 bottom-0 end-0 d-flex align-items-center justify-content-center w-100 h-100 bg-black bg-opacity-50'
                    style={{ zIndex: 1030 }}>
                    <ReactLoading type={'spin'} color={'#fff'} height={64} width={64} />
                </div>
                : ''}
        </div>
    )
}


const mapPropsToState = (state) => {
    return {
        loading: state.business.loading,
        error: state.business.error,
        success: state.business.success,
        businessList: state.business.bussinesslisting,
        couponDetails: state.rewardAndCoupon.couponDetails,
        sliderData1: state.business.slide1,
        sliderData2: state.business.slide2,
        banner: state.business.banner,
        
        sliderData3: state.business.slide3,
        sliderData4: state.business.slide4,
        sliderData5: state.business.slide5,
        sliderData6: state.business.slide6,
        
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getBusinessList: (data) => dispatch(actions.getBusinessList(data)),
        clickPerCount: (data) => dispatch(actions.clickPerCount(data)),
        makeRating: (data) => dispatch(actions.makeRating(data)),
        createAndGetCoupon: (data) => dispatch(actions.createAndGetCoupon(data)),
        getBusinessListSlide: (data) => dispatch(actions.getBusinessListSlide(data)),
        
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(NearbyBusiness))
