import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { withSnackbar } from 'react-simple-snackbar'
import * as actions from '../store/actions/index';

const Footer = (props) => {
    const { termAndConditionData, getPolicyTermConditionDetails } = props;
    useEffect(() => {
        getPolicyTermConditionDetails()
    }, [getPolicyTermConditionDetails])
    const token = localStorage.getItem('token');
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let city = localStorage.getItem('city');
    let state = localStorage.getItem('state');

    let nerabyUrl = `/nearby-business/top-10-best-nearby-businesses-${city && state ? `${city}-${state}` : 'near me'}`
    let couponRewardUrl = `/coupon-and-reward-program/top-20-best-coupons-and-rewards_${city && state ? `in-${city}-${state}` : 'near me'}`
    let storeFrontUrl = `/store-front-marketplace/top-10-best-store-front-businesses-${city && state ? `in-${city}-${state}` : 'near me'}`
    return (
        <footer className='footer-wrapper bg-darkk pt-4'>
            <div className='container mt-3 my-md-4'>
                <div className='row'>
                    <div className='col-lg-3 col-sm-6 mb-4'>
                        <div className='menu-header fs-5 fw-bold text-primary mb-3'>About Us</div>
                        {/* <p>
                            Coupon Ranking dive deeper into topics that matter with CouponRanking blog, the ultimate online resource for engaging and informative content.</p> */}
                        <div className='mb-3'>
                            <div className='about-desc' dangerouslySetInnerHTML={{
                                __html: termAndConditionData.about_us
                            }}></div>
                            <Link to={'/about-us'} className='btn-link text-warning pointer'>See more</Link>
                        </div>
                        {token && userInfo.user_type === 'business_user' ? <div className='mob-number d-flex mb-1'><i className="bi bi-phone me-1 text-warning"></i> (217) 419-0067</div> : ''}
                        {token && userInfo.user_type === 'business_user' ? <div className='email d-flex mb-1'><i className="bi bi-envelope me-2 text-warning"></i> admin@couponranking.com</div> : ''}
                        <div className='email d-flex'><i className="bi bi-geo-alt me-2 text-warning"></i> 2501 Chatham Rd. Suite 4524 Springfield, IL 62704</div>
                    </div>
                    <div className="col-lg-3 col-sm-6 mb-4">
                        <div className='menu-header fs-5 fw-bold text-primary mb-3'>Quick Links</div>
                        <ul className="nav flex-column">
                            <li className="nav-item"><Link to={'/who-we-are'} className="nav-link">Who we are</Link></li>
                            <li className="nav-item"><Link to={'/what-we-do'} className="nav-link">What we do</Link></li>
                            <li className="nav-item"><Link to={'/about-us'} className="nav-link">About Company</Link></li>
                            <li className="nav-item"><Link to={'/'} className="nav-link">Careers</Link></li>
                            <li className="nav-item"><Link to={'/blogs'} className="nav-link">Our Blogs</Link></li>
                            <li className="nav-item"><Link 
                                onClick={(e) => { 
                                    e.preventDefault(); 
                                    window.open('/faq', '_blank', 'noopener,noreferrer'); 
                                  }}                              
                                className="nav-link">Rebate Frequently Asked Questions(FAQ)</Link></li>
                            {/* {token && userInfo.user_type === 'business_user' ?  */}
                            <li className="nav-item"><Link to={'/login'} className="nav-link" style={{ color: 'red' }}>                            Claim your Rebate</Link></li>
                            {/* : ''} */}
                            {token && userInfo.user_type === 'business_user' ? <li className="nav-item"><Link to={'/'} className="nav-link">Contact Us</Link></li> : ''}
                            {token && userInfo.user_type === 'business_user' ?
                                <li className="nav-item"><Link to={'/pricing-plan'} className="nav-link">Pricing</Link></li>
                                : ''
                            }
                        </ul>
                    </div>
                    <div className="col-lg-3 col-sm-6 mb-4">
                        <div className='menu-header fs-5 fw-bold text-primary mb-3'>Services</div>
                        <ul className="nav flex-column">
                            <li className="nav-item"><Link to={nerabyUrl} className="nav-link">Nearby businesses</Link></li>
                            <li className="nav-item"><Link to={'/'} className="nav-link">Latest report</Link></li>
                            <li className="nav-item"><Link to={'/get-all-categories'} className="nav-link">Category</Link></li>
                            <li className="nav-item"><Link to={storeFrontUrl} className="nav-link">Store front marketplace</Link></li>
                            <li className="nav-item"><Link to={couponRewardUrl} className="nav-link">Coupon & rewards program</Link></li>
                            <li className="nav-item"><Link to={'/ecommerce-business'} className="nav-link">E-commerce & online service market</Link></li>
                            <li className="nav-item"><Link to={'/deal-business'} className="nav-link">Deals</Link></li>
                            <li className="nav-item"><Link to={'/privacy-policy'} className="nav-link">Privacy Policy</Link></li>
                            <li className="nav-item"><Link to={'/term-and-condition'} className="nav-link">Terms & Condition</Link></li>
                            {/* <li className="nav-item"><Link to={'/about-us'} className="nav-link">About Us</Link></li> */}
                        </ul>
                    </div>
                    <div className="col-lg-3 col-sm-6 mb-4">
                        <div className='menu-header fs-5 fw-bold text-primary mb-3'>Our Newsletter</div>
                        <p className='mb-2'>Dive deeper into topics that matter with CouponRanking blog</p>
                        <form className="mb-3">
                            <label htmlFor='category' className='form-label w-100 text-warning mb-1 small fw-medium'>Category</label>
                            <input type="email" className="form-control rounded-1 py-2 mb-2" id="category" placeholder="City, State..." required />
                            <button className="w-100 btn btn-primary rounded-1" id="basic-addon2">Submit</button>
                        </form>
                        <div className='social-sec d-flex align-items-center gap-3 mb-2'>
                            <Link to={"https://www.facebook.com/Couponranking"} target='_blank'><img src={require('../assets/facebook.png')} className="img-fluid" alt="Facebook" /></Link>
                            <Link to={"https://Twitter.com/Couponranking"} target='_blank'><img src={require('../assets/twitter.png')} className="img-fluid" alt="Twitter" /></Link>
                            <Link to={"https://www.instagram.com/Couponranking"} target='_blank'><img src={require('../assets/instagram.png')} className="img-fluid" alt="Instagram" /></Link>
                            <Link to={"https://www.youtube.com/channel/UC-laicUBBca5_4oQbWgVp2A"} target='_blank'><img src={require('../assets/youtube.png')} className="img-fluid" alt="Youtube" /></Link>
                            <Link to={"https://www.tiktok.com/couponrankong"} target='_blank'><img src={require('../assets/tiktok.png')} className="img-fluid" alt="Snapchat" /></Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className='copyright-sec bg-primary bg-opacity-50 py-3'>
                <div className='container'>
                    <div className='copyright-wrapper text-center text-white'>
                        <div className='copyright-text'>&copy; copyright 2023 <Link to={'/'} className='text-warning fw-medium text-decoration-none'>couponranking.com</Link>. "All rights reserved".</div>
                        <div className='cmpny'>Web design by Waliphil Technology Limited</div>
                    </div>
                </div>
            </div>
        </footer>
    )
}


const mapPropsToState = (state) => {
    return {
        termAndConditionData: state.business.policyTermConditionDetails
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getPolicyTermConditionDetails: () => dispatch(actions.getPolicyTermConditionDetails()),
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(Footer))
