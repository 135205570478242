import React, { useEffect, useState } from 'react';
// Our Packages Slick Slider
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux'
import { withSnackbar } from 'react-simple-snackbar'
import * as actions from '../store/actions/index';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SubscriptionTimePeriod = props => {
    console.log(props)
    let { getSubscriptionTimePeriod , success} = props
    let { state } = useLocation();
    const [salesPersonName, setsalesPersonName] = useState('');
    const adType = state.adType;
    const planId = state.planId;
    console.log(adType, "adType");
    console.log(state)
    let navigate = useNavigate()
    const handleChange = (e) => {
        setsalesPersonName(e.target.value)
    }
    useEffect(() => {
        // const adType = "regular"
        // Start: Package Plan Same Height JS
        var i = 0;
        var items = document.querySelectorAll(".package-plan .package-info .list-cover");
        var itemsHeight = [];

        for (i = 0; i < items.length; i++) {
            itemsHeight.push(items[i].offsetHeight);
        }

        var maxHeight = Math.max(...itemsHeight);

        for (i = 0; i < items.length; i++) {
            items[i].style.height = maxHeight + "px";
        }
        getSubscriptionTimePeriod(adType)
        if(success){
            toast.success(success, { autoClose: 1000 })
        }
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
          });
    }, [getSubscriptionTimePeriod, success]);
    console.log(getSubscriptionTimePeriod)
    // Slick Props
    var settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    let details = props.listingTimePeriod ? props.listingTimePeriod : []
    var filteredPlanDetails = [];
    for (var index = 0; index < details.length; index++) {
        // commented by simran
        // isPresent = true;
        // for (var outer = 0; outer < matchWords.length; outer++) {
        //     if (details[index].advertisement_type.indexOf(matchWords[outer]) === -1) {
        //         isPresent = false;
        //         break;
        //     }
        // }
        // if (isPresent) {
        //     filteredPlanDetails.push(details[index]);
        // }
        filteredPlanDetails.push(details[index]);
    }
    let PlanlistByPrice = filteredPlanDetails.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
       
    const subscribePlan = (planId, period, _id) => {
          let storedToken = localStorage.getItem("token")
          let data = {
            planId:planId,
            token:storedToken?storedToken:state.token,
            timePeriod:period,
            _id: _id,
            salesPersonName: salesPersonName,
        }
          props.subscribePlanTimePeriodByUser(data,navigate)
    }
    
    return (
        <div className='pricing-plan-page py-4 py-xl-5'>
            {/* title head */}
            <Helmet>
                <title>CouponRanking | Pricing Plan</title>
            </Helmet>
            <ToastContainer />
            <div className='container'>
                <div className="text-center mx-auto mb-4 pb-xl-3" style={{ maxWidth: '654px' }}>
                    <div className="section-heading fs-3 fw-bold lh-sm mb-1">Subscription Time period</div>
                    <div className="section-subheading text-muted">Take your digital advertising to the next level. Get the results you deserve at a price you can afford.</div>
                </div>
                {
                    PlanlistByPrice.length > 0 ?
                        <div className="package-plan slider-wrapper">
                            <Slider {...settings} className="custom-slick">
                                {PlanlistByPrice.map((ele, index) => (
                                    <div className='package-item bg-white border border-white text-center h-100' key={index}>
                                        <div className='package-header bg-primary d-flex align-items-center justify-content-center p-3'>
                                            <label className='text-light fs-5 fw-semibold'>{ele.name}</label>
                                        </div>
                                        <div className='price-wrapper bg-primary bg-opacity-10 p-3'>
                                            {/* <div className='package-label text-secondary fw-medium'>{ele.description_of_original_price}</div> */}
                                            <div className='package-price fs-2 fw-bold lh-sm'>{ele.description}</div>
                                            {/* <div className='package-label text-secondary fw-medium'>{ele.perClickText}</div> */}
                                        </div>
                                        <div className='package-info d-flex flex-column justify-content-between px-3 px-xl-4 py-4'>
                                            <div className='list-cover text-start' dangerouslySetInnerHTML={{
                                                __html:
                                                    (ele.planDetail !== null) ? ele.planDetail : "waiting"
                                            }}>
                                            </div>
                                            <div className='btn-wrapper mt-4'>
                                                <button  type='button' className='btn btn-primary rounded-1 px-5 py-2'onClick={()=>subscribePlan(planId, ele.period, ele._id)}>Select Your Plan</button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div> : ''
                }
                <div className='col-sm-6'>
                    <div className="mb-3">
                        <label htmlFor="fName" className="form-label">Sales Person Name</label>
                        <input type="text" className="form-control" id="fName" placeholder='Sales Person Name' required
                            value={salesPersonName}
                            onChange={(e) => handleChange(e)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}


const mapPropsToState = (state) => {
    return {
        loading: state.subscriptionPlan.loading,
        error: state.subscriptionPlan.error,
        success: state.subscriptionPlan.success,
        listingTimePeriod: state.subscriptionPlan.listingTimePeriod
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getSubscriptionTimePeriod: (adType) => dispatch(actions.getSubscriptionTimePeriod(adType)),
        subscribePlanTimePeriodByUser:(data,navigate) => dispatch(actions.subscribePlanTimePeriodByUser(data,navigate)),
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(SubscriptionTimePeriod))