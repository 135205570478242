import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useState } from 'react';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux'
import { withSnackbar } from 'react-simple-snackbar'
import * as actions from '../store/actions/index';
import { useEffect } from 'react';
import {Button, Modal} from 'react-bootstrap';
import Cutlery from '../assets/cutlery.svg'

// Import Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
// import required modules
import { Autoplay, EffectFade, Pagination } from 'swiper/modules';

const slidesData = [
    {
        id: 1,
        title: "Banje Hutz",
        subtitle: "Indian Restaurant",
        image: require('../assets/food-banner1.jpg'),
    },
    {
        id: 2,
        title: "Pizza Hutz",
        subtitle: "Pizza Restaurant",
        image: require('../assets/food-banner2.jpg'),
    },
    {
        id: 3,
        title: "The GT Road Cafe",
        subtitle: "Indian/Chinese Cafe",
        image: require('../assets/food-banner3.jpg'),
    },
];

const AllCategories = (props) => {
    const { getCategoryList, categoryList, getCategoryDataSlides, 
        banner, sliderData1, sliderData2, sliderData3, sliderData4, sliderData6, sliderData5 } = props;
    const navigate = useNavigate()
    const [subCategoryModalInfo, setSubCategoryModalInfo] = useState([]);
    let token = localStorage.getItem('token')
    const [show, setShow] = useState(false);
    const [couponDirection, setCouponDirection] = useState({
            destLat: '',
            desLong: '',
            businessName: '',
            address: ''
        })
    
    const [callDetails, setCallDetails] = useState({
        country_code: '',
        mobile_no: ''
    })
    const handleRequestAQuoteClick = (business) => {
        let businessInfo = {
            email: business.email,
            businessName: business.name,
            country_code: business.country_code,
            phone_no: business.mobile_no,
            businessId: business._id,
            requestQuoteService: business.requestQuoteService,
            // subCategoryName: getSubCategoryName
        }
        navigate(`/request-a-quote/${business.name.replaceAll(/ /g, "-").toLowerCase()}`, { state: businessInfo })
    }
    const handleBookAnAppointmentClick = (business) => {
        let businessInfo = {
            email: business.email,
            businessName: business.name,
            country_code: business.country_code,
            phone_no: business.phone_no,
            businessId: business._id,
            bookAppointmentService: business.bookAppointmentService
        }
        navigate(`/book-an-appointment/${business.name.replaceAll(/ /g, "-").toLowerCase()}`, { state: businessInfo })
    }

    useEffect(() => {
        // for loading page from top after re-render from any section
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        });
        getCategoryList()
        let lat = localStorage.getItem("latitude") === null ? "40.1164204" : localStorage.getItem("latitude");
        let long = localStorage.getItem("longitude") === null ?  "-88.2433829": localStorage.getItem("longitude")
        let data = {
            latitude: lat,
            longitude: long,
            
            type: "subcategory"
        }
        getCategoryDataSlides(data)
    }, [getCategoryList, getCategoryDataSlides])

    const handleCouponClick = (business, type) => {
        if (token !== null) {
            setCallDetails({ country_code: business.country_code, mobile_no: business.mobile_no })
            setCouponDirection({
                destLat: business.latitude,
                desLong: business.longitude,
                businessName: business.name,
                address: business.address
            })
            if (type === 'coupon') {
                let couponData = {
                    businessId: business._id,
                    type: "coupon"
                }
                props.createAndGetCoupon(couponData)
            } else if (type === 'reward') {
                let rewardData = {
                    businessId: business._id,
                    type: "reward"
                }
                props.createAndGetCoupon(rewardData)
            }

        } else {
            navigate('/login')
        }
    }

    const handleCallClick = (business) => {
        setCallDetails({ country_code: business.country_code, mobile_no: business.mobile_no })
        let data = {
            businessId: business._id,
            clickType: 'call'
        }
        props.clickPerCount(data)
    }

    const handleEmailClick = (business) => {
        let token = localStorage.getItem('token')
        if (!token) {
            navigate('/login')
        } else {
            let businessInfo = {
                email: business.email,
                businessName: business.name,
                country_code: business.country_code,
                phone_no: business.phone_no,
                businessId: business._id
            }
            navigate(`/send-email-to-business/:${business.name}`, { state: businessInfo })
        }
    }

    const sendOnWebsite = (business, type, e) => {
        e.preventDefault()
        let data = {
            businessId: business._id,
            clickType: type
        }
        props.clickPerCount(data)
        window.open(`https://${business.website}`, '_blank', 'noopener,noreferrer')
    }

    //send on map page for routes
    const sendOnDirectionPage = (business) => {
        let addr = business.address ? getRequiredAddress(business.address, 3) : business.city
        let businessInfo = {
            destLat: business.latitude,
            desLong: business.longitude,
            businessName: business.name,
            address: addr
        }
        let data = {
            businessId: business._id,
            clickType: 'direction'
        }
        props.clickPerCount(data)
        navigate(`/direction-to-business/${addr}`, { state: businessInfo })
    }

    const sendOnDirectionPageForCouponModal = () => {
        let businessInfo = {
            destLat: couponDirection.destLat,
            desLong: couponDirection.desLong,
            businessName: couponDirection.businessName,
            address: couponDirection.address
        }
        navigate(`/direction-to-business/${couponDirection.address}`, { state: businessInfo })
    }

    const getRequiredAddress = (str, from) => {
        var arr = str.split(',');
        arr.splice(0, from);
        return arr.join(',');
    }
    const handleTextClick = (business) => {
        let token = localStorage.getItem('token')
        if (!token) {
            navigate('/login')
        } else {
            let businessInfo = {
                email: business.email,
                businessName: business.name,
                country_code: business.country_code,
                phone_no: business.phone_no,
                businessId: business._id
            }
            navigate(`/send-text-message-to-business/:${business.name}`, { state: businessInfo })
        }
    }
    
    const handleSubCategoryClick = (e, info) => {
        e.preventDefault();
        let city = localStorage.getItem('city');
        let state = localStorage.getItem('state');
        let categoryBusinessUrl = `/get-business-by-category/top-10-best-${info.name.replaceAll(/ /g, "-").toLowerCase()}-in-${city && state ? `${city}-${state}` : 'near me'}`
        navigate(categoryBusinessUrl, { state: info })
    }

    // modal hooks
    const handleClose = () => setShow(false);
    const handleModal = (info) => {
        setShow(true)
        setSubCategoryModalInfo(info)
    }
    return (
        <div className='all-categories-page categories-sec py-4'>
            {/* title head */}
            <Helmet>
                <title>CouponRanking Category list</title>
            </Helmet>

            {/* Start: Common Slider Code*/}
            <div className='common-banner-wrapper mb-5 mt-n4'>
            {banner && banner.length > 0 ? 
                <Swiper
                    slidesPerView={1}
                    loop={true}
                    effect={'fade'}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Autoplay, EffectFade, Pagination]}
                    className="mySwiper custom-dots"
                >
                    {banner.map((slide) => (
                        <SwiperSlide key={slide._id}>
                            <div className="slide d-flex align-items-center bg-contain" style={{ backgroundImage: `url(${slide.image})`, }} >
                                <div className="container">
                                    <div className="content-wrapper text-light rounded-3 p-3 p-md-4">
                                        <div className="company-name fs-2 fw-bold lh-sm mb-1">{slide.title}</div>
                                        <div className="company-subtitle fs-6 fw-normal mb-3">{slide.subCategory.length > 0 ? slide.subCategory[0].subCategoryName : ''}</div>
                                        <div className="company-subtitle fs-6 fw-normal mb-3">{slide.ad_message}</div>
                                        {/* <div className="discount-badge poin">20% off</div> */}
                                        {slide.isCouponSelect ? <div className="discount-badge">{slide.couponText.split(',')[0]}</div> : ''}
                                        <div className="button-group row g-2">
                                        {slide.isCallForQuoteSelect ? <div className="col-6">
                                            <button className="btn btn-warning w-100 px-3" onClick={() => handleRequestAQuoteClick(slide)}>Request a Quote</button>
                                            </div>:<></>}
                                            {slide.isBookForAppointmentSelect ? <div className="col-6">
                                                <button className="btn btn-success w-100 px-3" onClick={() => handleBookAnAppointmentClick(slide)}>Book Appointment</button>
                                            </div> :<></>}
                                            <div className="col-12">
                                                {/* <button className="btn btn-danger w-100 px-3">Coupon Message</button> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>:
                 <div className='fs-3 text-danger text-center'>
                     *Promote your business here!* 
                     Take advantage of this prime ad space and grow your brand. Place your sponsored ad today!
                 </div>}
            </div>
            {/* End: Common Slider Code*/}

            <div className='container-fluid'>
                <div className='section-header text-center mb-4 pb-md-2'>
                    <div className='section-label fs-1'>All Category</div>
                    <div className='section-sublabel text-dark text-opacity-75'>Elevate your business to the next level with our business category solutions. Unlock potential and take advantage of tailored services today.</div>
                </div>

                <div className="row g-3">
                    <div className="col-lg-3 col-xl-2">
                        {/* Start: Common Sidebar Ads Code*/}
                        <div className="ads-wrapper d-flex flex-column gap-3">
                        <div className='common-banner-wrapper common-sidebar-banner'>
                                {sliderData1 && sliderData1.length > 0 ? 
                                <Swiper
                                    slidesPerView={1}
                                    loop={true}
                                    effect={'fade'}
                                    autoplay={{
                                        delay: 5000,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Autoplay, EffectFade, Pagination]}
                                    className="mySwiper custom-dots"
                                >
                                    {sliderData1.map((slide) => (
                                        <SwiperSlide key={slide._id}>
                                            <div className="slide d-flex align-items-end bg-contain" style={{ backgroundImage: `url(${slide.image})`, }} >
                                                {/* <div className="discount-badge poin">20% off</div> */}
                                                {slide.isCouponSelect ? <div className='discount-tag bg-danger rounded-circle d-flex align-items-center justify-content-center text-white small position-absolute p-2 lh-sm'>{slide.couponText.split(',')[0]}</div> : ''}

                                                <div className="content-wrapper text-light rounded-3 mb-5 p-2">
                                                    <div className="company-name fs-4 fw-bold lh-sm mb-1">{slide.title}</div>
                                                    <div className="company-subtitle fs-6 fw-normal mb-3">{slide.subCategory.length > 0 ? slide.subCategory[0].subCategoryName : ''}</div>
                                                    <div className="company-subtitle fs-6 fw-normal mb-3">{slide.ad_message}</div>
                                                    <div className='action d-flex flex-wrap gap-2 mb-3'>
                                                        {/* <Link type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-telephone-fill text-primary me-1"></i>Call
                                                        </Link>
                                                        <Link type='button' className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-envelope-fill text-primary me-1"></i>Email
                                                        </Link>
                                                        <Link type="button" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-globe-americas text-primary me-1"></i>Website
                                                        </Link>
                                                        <Link type="button" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-reply-fill text-primary me-1"></i>Directions
                                                        </Link>
                                                        <Link to={'#'} className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <img src={Cutlery} alt='cutlery' className="text-primary me-1" />Order
                                                        </Link>
                                                        <Link className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-pencil-fill text-primary me-1"></i>Text
                                                        </Link> */}
                                                        {slide.isPhoneSelect ? 
                                                            <Link type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleCallClick(slide)}><i className="bi bi-telephone-fill text-primary me-2"></i>Call
                                                            </Link>
                                                        : ''}
                                                        {slide.isEmailSelect ? <button type='button' className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleEmailClick(slide)}><i className="bi bi-envelope-fill text-primary me-2"></i>Email</button> : ''}
                                                        {slide.isWebsiteSelect ? <Link type="button" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnWebsite(slide, 'website')} ><i className="bi bi-globe-americas text-primary me-2"></i>Website</Link> : ''}
                                                        {slide.isDirectionSelect ? <button type="button" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnDirectionPage(slide)}><i className="bi bi-reply-fill text-primary me-2"></i>Directions</button> : ''}
                                                        {slide.isOrderNowSelect ? <Link to={'#'} className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnWebsite(slide, 'order')}><img src={Cutlery} alt='cutlery' className="text-primary me-2" />Order</Link> : ''}
                                                        {slide.isTextSelect ? <button className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleTextClick(slide)}> <i className="bi bi-pencil-fill text-primary me-2"></i>Text</button> : ''}
                                                    </div>
                                                    <div className="button-group d-flex flex-column gap-1">
                                                        {/* <button className='btn w-100 text-white lh-sm px-3' style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)' }} >Click here for a coupon</button> */}
                                                        {slide.isCouponSelect ? slide.couponText.includes('rewards') ?
                                                            <div type="button" className='text-white shadow p-3 w-100 mb-2 mt-2'
                                                                data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#rewardModal" : ''}
                                                                style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }}
                                                                onClick={() => handleCouponClick(slide, 'reward')}
                                                            >
                                                                {slide.couponText ? slide.couponText.split(',')[1] : 'Click here for the deal min order $30'}
                                                            </div>
                                                            :
                                                            <div type="button" className='text-white shadow p-3 w-100 mb-2 mt-2'
                                                                data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#couponModal" : ''}
                                                                style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }}
                                                                onClick={() => handleCouponClick(slide, 'coupon')}
                                                            >
                                                                {slide.couponText ? slide.couponText.split(',')[1] : 'Click here for the deal min order $30'}
                                                            </div>
                                                        : ''}

                                                        {slide.isCallForQuoteSelect ? <button className="btn btn-warning w-100 px-3" onClick={() => handleRequestAQuoteClick(slide)}>Request a Quote</button> : <></>}
                                                        {slide.isBookForAppointmentSelect ? <button className="btn btn-success w-100 px-3" onClick={() => handleBookAnAppointmentClick(slide)}>Book Appointment</button> : <></>}
                                                        {/* <button className="btn btn-danger w-100 px-3">Coupon Message</button> */}
                                                    </div>
                                                    <div class="sponserd text-white fw-medium mt-2">Sponsored Ad</div>
                                                </div>
                                            </div>
                                            </SwiperSlide>
                                    ))}</Swiper> 
                                : <div className='fs-3 text-danger text-center'>
                                    *Promote your business here!* 
                                    Take advantage of this prime ad space and grow your brand. Place your sponsored ad today!
                                </div>}
                            </div>

                            {/* Second Ads */}
                            <div className='common-banner-wrapper common-sidebar-banner'>
                                {sliderData3 && sliderData3.length > 0 ? 
                                <Swiper
                                    slidesPerView={1}
                                    loop={true}
                                    effect={'fade'}
                                    autoplay={{
                                        delay: 5000,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Autoplay, EffectFade, Pagination]}
                                    className="mySwiper custom-dots"
                                >
                                    {sliderData3.map((slide) => (
                                        <SwiperSlide key={slide._id}>
                                            <div className="slide d-flex align-items-end bg-contain" style={{ backgroundImage: `url(${slide.image})`, }} >
                                                {/* <div className="discount-badge poin">20% off</div> */}
                                                {slide.isCouponSelect ? <div className='discount-tag bg-danger rounded-circle d-flex align-items-center justify-content-center text-white small position-absolute p-2 lh-sm'>{slide.couponText.split(',')[0]}</div> : ''}
                                                
                                                <div className="content-wrapper text-light rounded-3 mb-5 p-2">
                                                    <div className="company-name fs-4 fw-bold lh-sm mb-1">{slide.title}</div>
                                                    <div className="company-subtitle fs-6 fw-normal mb-3">{slide.subCategory.length > 0 ? slide.subCategory[0].subCategoryName : ''}</div>
                                                    <div className="company-subtitle fs-6 fw-normal mb-3">{slide.ad_message}</div>
                                                    <div className='action d-flex flex-wrap gap-2 mb-3'>
                                                        {/* <Link type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-telephone-fill text-primary me-1"></i>Call
                                                        </Link>
                                                        <Link type='button' className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-envelope-fill text-primary me-1"></i>Email
                                                        </Link>
                                                        <Link type="button" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-globe-americas text-primary me-1"></i>Website
                                                        </Link>
                                                        <Link type="button" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-reply-fill text-primary me-1"></i>Directions
                                                        </Link>
                                                        <Link to={'#'} className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <img src={Cutlery} alt='cutlery' className="text-primary me-1" />Order
                                                        </Link>
                                                        <Link className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-pencil-fill text-primary me-1"></i>Text
                                                        </Link> */}
                                                        {slide.isPhoneSelect ? 
                                                            <Link type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleCallClick(slide)}><i className="bi bi-telephone-fill text-primary me-2"></i>Call
                                                            </Link>
                                                        : ''}
                                                        {slide.isEmailSelect ? <button type='button' className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleEmailClick(slide)}><i className="bi bi-envelope-fill text-primary me-2"></i>Email</button> : ''}
                                                        {slide.isWebsiteSelect ? <Link type="button" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnWebsite(slide, 'website')} ><i className="bi bi-globe-americas text-primary me-2"></i>Website</Link> : ''}
                                                        {slide.isDirectionSelect ? <button type="button" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnDirectionPage(slide)}><i className="bi bi-reply-fill text-primary me-2"></i>Directions</button> : ''}
                                                        {slide.isOrderNowSelect ? <Link to={'#'} className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnWebsite(slide, 'order')}><img src={Cutlery} alt='cutlery' className="text-primary me-2" />Order</Link> : ''}
                                                        {slide.isTextSelect ? <button className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleTextClick(slide)}> <i className="bi bi-pencil-fill text-primary me-2"></i>Text</button> : ''}
                                                    </div>
                                                    <div className="button-group d-flex flex-column gap-1">
                                                        {/* <button className='btn w-100 text-white lh-sm px-3' style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)' }} >Click here for a coupon</button> */}
                                                        {slide.isCouponSelect ? slide.couponText.includes('rewards') ?
                                                            <div type="button" className='text-white shadow p-3 w-100 mb-2 mt-2'
                                                                data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#rewardModal" : ''}
                                                                style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }}
                                                                onClick={() => handleCouponClick(slide, 'reward')}
                                                            >
                                                                {slide.couponText ? slide.couponText.split(',')[1] : 'Click here for the deal min order $30'}
                                                            </div>
                                                            :
                                                            <div type="button" className='text-white shadow p-3 w-100 mb-2 mt-2'
                                                                data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#couponModal" : ''}
                                                                style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }}
                                                                onClick={() => handleCouponClick(slide, 'coupon')}
                                                            >
                                                                {slide.couponText ? slide.couponText.split(',')[1] : 'Click here for the deal min order $30'}
                                                            </div>
                                                        : ''}

                                                        {slide.isCallForQuoteSelect ? <button className="btn btn-warning w-100 px-3" onClick={() => handleRequestAQuoteClick(slide)}>Request a Quote</button>:<></>}
                                                        {slide.isBookForAppointmentSelect ? <button className="btn btn-success w-100 px-3" onClick={() => handleBookAnAppointmentClick(slide)}>Book Appointment</button> : <></>}
                                                        {/* <button className="btn btn-danger w-100 px-3">Coupon Message</button> */}
                                                    </div>
                                                    <div class="sponserd text-white fw-medium mt-2">Sponsored Ad</div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))}</Swiper> 
                                : <div className='fs-3 text-danger text-center'>
                                    *Promote your business here!* 
                                    Take advantage of this prime ad space and grow your brand. Place your sponsored ad today!
                                </div>}
                            </div>

                            <div className='common-banner-wrapper common-sidebar-banner'>
                                {sliderData5 && sliderData5.length > 0 ? 
                                <Swiper
                                    slidesPerView={1}
                                    loop={true}
                                    effect={'fade'}
                                    autoplay={{
                                        delay: 5000,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Autoplay, EffectFade, Pagination]}
                                    className="mySwiper custom-dots"
                                >
                                    {sliderData5.map((slide) => (
                                        <SwiperSlide key={slide._id}>
                                            <div className="slide d-flex align-items-end bg-contain" style={{ backgroundImage: `url(${slide.image})`, }} >
                                                {/* <div className="discount-badge poin">20% off</div> */}
                                                {slide.isCouponSelect ? <div className='discount-tag bg-danger rounded-circle d-flex align-items-center justify-content-center text-white small position-absolute p-2 lh-sm'>{slide.couponText.split(',')[0]}</div> : ''}

                                                <div className="content-wrapper text-light rounded-3 mb-5 p-2">
                                                    <div className="company-name fs-4 fw-bold lh-sm mb-1">{slide.title}</div>
                                                    <div className="company-subtitle fs-6 fw-normal mb-3">{slide.subCategory.length > 0 ? slide.subCategory[0].subCategoryName : ''}</div>
                                                    <div className="company-subtitle fs-6 fw-normal mb-3">{slide.ad_message}</div>
                                                    <div className='action d-flex flex-wrap gap-2 mb-3'>
                                                        {/* <Link type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-telephone-fill text-primary me-1"></i>Call
                                                        </Link>
                                                        <Link type='button' className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-envelope-fill text-primary me-1"></i>Email
                                                        </Link>
                                                        <Link type="button" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-globe-americas text-primary me-1"></i>Website
                                                        </Link>
                                                        <Link type="button" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-reply-fill text-primary me-1"></i>Directions
                                                        </Link>
                                                        <Link to={'#'} className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <img src={Cutlery} alt='cutlery' className="text-primary me-1" />Order
                                                        </Link>
                                                        <Link className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-pencil-fill text-primary me-1"></i>Text
                                                        </Link> */}
                                                        {slide.isPhoneSelect ? 
                                                            <Link type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleCallClick(slide)}><i className="bi bi-telephone-fill text-primary me-2"></i>Call
                                                            </Link>
                                                        : ''}
                                                        {slide.isEmailSelect ? <button type='button' className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleEmailClick(slide)}><i className="bi bi-envelope-fill text-primary me-2"></i>Email</button> : ''}
                                                        {slide.isWebsiteSelect ? <Link type="button" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnWebsite(slide, 'website')} ><i className="bi bi-globe-americas text-primary me-2"></i>Website</Link> : ''}
                                                        {slide.isDirectionSelect ? <button type="button" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnDirectionPage(slide)}><i className="bi bi-reply-fill text-primary me-2"></i>Directions</button> : ''}
                                                        {slide.isOrderNowSelect ? <Link to={'#'} className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnWebsite(slide, 'order')}><img src={Cutlery} alt='cutlery' className="text-primary me-2" />Order</Link> : ''}
                                                        {slide.isTextSelect ? <button className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleTextClick(slide)}> <i className="bi bi-pencil-fill text-primary me-2"></i>Text</button> : ''}
                                                    </div>
                                                    <div className="button-group d-flex flex-column gap-1">
                                                        {/* <button className='btn w-100 text-white lh-sm px-3' style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)' }} >Click here for a coupon</button> */}
                                                        {slide.isCouponSelect ? slide.couponText.includes('rewards') ?
                                                            <div type="button" className='text-white shadow p-3 w-100 mb-2 mt-2'
                                                                data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#rewardModal" : ''}
                                                                style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }}
                                                                onClick={() => handleCouponClick(slide, 'reward')}
                                                            >
                                                                {slide.couponText ? slide.couponText.split(',')[1] : 'Click here for the deal min order $30'}
                                                            </div>
                                                            :
                                                            <div type="button" className='text-white shadow p-3 w-100 mb-2 mt-2'
                                                                data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#couponModal" : ''}
                                                                style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }}
                                                                onClick={() => handleCouponClick(slide, 'coupon')}
                                                            >
                                                                {slide.couponText ? slide.couponText.split(',')[1] : 'Click here for the deal min order $30'}
                                                            </div>
                                                        : ''}

                                                        {slide.isCallForQuoteSelect ? <button className="btn btn-warning w-100 px-3" onClick={() => handleRequestAQuoteClick(slide)}>Request a Quote</button>:<></>}
                                                        {slide.isBookForAppointmentSelect ? <button className="btn btn-success w-100 px-3" onClick={() => handleBookAnAppointmentClick(slide)}>Book Appointment</button> : <></>}
                                                        {/* <button className="btn btn-danger w-100 px-3">Coupon Message</button> */}
                                                    </div>
                                                    <div class="sponserd text-white fw-medium mt-2">Sponsored Ad</div>
                                                </div>
                                            </div>
                                            </SwiperSlide>
                                    ))}</Swiper> 
                                : <div className='fs-3 text-danger text-center'>
                                    *Promote your business here!* 
                                    Take advantage of this prime ad space and grow your brand. Place your sponsored ad today!
                                </div>}
                            </div>

                        </div>
                        {/* End: Common Sidebar Ads Code*/}
                    </div>
                    <div className="col-lg-6 col-xl-8">
                        <div className="row g-3">
                            {
                                categoryList && categoryList.length > 0 ?
                                    categoryList.map((categoryInfo, index) => (
                                        <div className="col-sm-6 col-xl-4 col-xxl-3" key={categoryInfo._id}>
                                            <div className="category-info-wrapper bg-primary bg-opacity-10 d-flex justify-content-between flex-column border shadow rounded-3 overflow-hidden h-100">
                                                <div className="coverr">
                                                    <div className="category-header bg-white d-flex align-items-center gap-2 px-3 py-2">
                                                        <img src={categoryInfo.category_images} className="img-fluid" alt="{info.image}" />
                                                        <div className="category-title fw-semibold">{categoryInfo.name}</div>
                                                    </div>
                                                    {
                                                        window.innerWidth >= '768' ?
                                                            <div className="category-body p-3">
                                                                {
                                                                    categoryInfo.subcategoryInfo.slice(0, 3).map((info, i) => (
                                                                        <div type='button' className="category-item nav-link d-flex gap-2 mb-2" key={info._id} onClick={(e) => handleSubCategoryClick(e, info)}><i className="bi bi-asterisk small text-success"></i>{info.name}</div>
                                                                    ))
                                                                }
                                                                <span type='button' className='btn-link text-primary fs-18 ms-3 ps-1' onClick={() => handleModal(categoryInfo.subcategoryInfo)}>See more...</span>
                                                            </div>
                                                            :
                                                            <div className="category-body p-3">
                                                                {
                                                                    categoryInfo.subcategoryInfo.map((info, i) => (
                                                                        <div type='button' className="category-item nav-link d-flex gap-2 mb-2" key={info._id} onClick={(e) => handleSubCategoryClick(e, info)}><i className="bi bi-asterisk small text-success"></i>{info.name}</div>
                                                                    ))
                                                                }
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                    : ''
                            }
                        </div>
                    </div>
                    <div className="col-lg-3 col-xl-2">
                        {/* Start: Common Sidebar Ads Code*/}
                        <div className="ads-wrapper d-flex flex-column gap-3">
                        <div className='common-banner-wrapper common-sidebar-banner'>
                                {sliderData2 && sliderData2.length > 0 ? 
                                <Swiper
                                    slidesPerView={1}
                                    loop={true}
                                    effect={'fade'}
                                    autoplay={{
                                        delay: 5000,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Autoplay, EffectFade, Pagination]}
                                    className="mySwiper custom-dots"
                                >
                                    {sliderData2.map((slide) => (
                                        <SwiperSlide key={slide._id}>
                                            <div className="slide d-flex align-items-end bg-contain" style={{ backgroundImage: `url(${slide.image})`, }} >
                                                {/* <div className="discount-badge poin">20% off</div> */}
                                                {slide.isCouponSelect ? <div className='discount-tag bg-danger rounded-circle d-flex align-items-center justify-content-center text-white small position-absolute p-2 lh-sm'>{slide.couponText.split(',')[0]}</div> : ''}
                                                
                                                <div className="content-wrapper text-light rounded-3 mb-5 p-2">
                                                    <div className="company-name fs-4 fw-bold lh-sm mb-1">{slide.title}</div>
                                                    <div className="company-subtitle fs-6 fw-normal mb-3">{slide.subCategory.length > 0 ? slide.subCategory[0].subCategoryName : ''}</div>
                                                    <div className="company-subtitle fs-6 fw-normal mb-3">{slide.ad_message}</div>
                                                    <div className='action d-flex flex-wrap gap-2 mb-3'>
                                                        {/* <Link type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-telephone-fill text-primary me-1"></i>Call
                                                        </Link>
                                                        <Link type='button' className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-envelope-fill text-primary me-1"></i>Email
                                                        </Link>
                                                        <Link type="button" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-globe-americas text-primary me-1"></i>Website
                                                        </Link>
                                                        <Link type="button" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-reply-fill text-primary me-1"></i>Directions
                                                        </Link>
                                                        <Link to={'#'} className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <img src={Cutlery} alt='cutlery' className="text-primary me-1" />Order
                                                        </Link>
                                                        <Link className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-pencil-fill text-primary me-1"></i>Text
                                                        </Link> */}
                                                        {slide.isPhoneSelect ? 
                                                            <Link type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleCallClick(slide)}><i className="bi bi-telephone-fill text-primary me-2"></i>Call
                                                            </Link>
                                                        : ''}
                                                        {slide.isEmailSelect ? <button type='button' className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleEmailClick(slide)}><i className="bi bi-envelope-fill text-primary me-2"></i>Email</button> : ''}
                                                        {slide.isWebsiteSelect ? <Link type="button" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnWebsite(slide, 'website')} ><i className="bi bi-globe-americas text-primary me-2"></i>Website</Link> : ''}
                                                        {slide.isDirectionSelect ? <button type="button" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnDirectionPage(slide)}><i className="bi bi-reply-fill text-primary me-2"></i>Directions</button> : ''}
                                                        {slide.isOrderNowSelect ? <Link to={'#'} className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnWebsite(slide, 'order')}><img src={Cutlery} alt='cutlery' className="text-primary me-2" />Order</Link> : ''}
                                                        {slide.isTextSelect ? <button className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleTextClick(slide)}> <i className="bi bi-pencil-fill text-primary me-2"></i>Text</button> : ''}
                                                    </div>
                                                    <div className="button-group d-flex flex-column gap-1">
                                                        {/* <button className='btn w-100 text-white lh-sm px-3' style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)' }} >Click here for a coupon</button> */}
                                                        {slide.isCouponSelect ? slide.couponText.includes('rewards') ?
                                                            <div type="button" className='text-white shadow p-3 w-100 mb-2 mt-2'
                                                                data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#rewardModal" : ''}
                                                                style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }}
                                                                onClick={() => handleCouponClick(slide, 'reward')}
                                                            >
                                                                {slide.couponText ? slide.couponText.split(',')[1] : 'Click here for the deal min order $30'}
                                                            </div>
                                                            :
                                                            <div type="button" className='text-white shadow p-3 w-100 mb-2 mt-2'
                                                                data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#couponModal" : ''}
                                                                style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }}
                                                                onClick={() => handleCouponClick(slide, 'coupon')}
                                                            >
                                                                {slide.couponText ? slide.couponText.split(',')[1] : 'Click here for the deal min order $30'}
                                                            </div>
                                                        : ''}

                                                        {slide.isCallForQuoteSelect ? <button className="btn btn-warning w-100 px-3" onClick={() => handleRequestAQuoteClick(slide)}>Request a Quote</button> :<></>}
                                                        {slide.isBookForAppointmentSelect ? <button className="btn btn-success w-100 px-3" onClick={() => handleBookAnAppointmentClick(slide)}>Book Appointment</button>:<></>}
                                                        {/* <button className="btn btn-danger w-100 px-3">Coupon Message</button> */}
                                                    </div>
                                                    <div class="sponserd text-white fw-medium mt-2">Sponsored Ad</div>
                                                </div>
                                            </div>
                                            </SwiperSlide>
                                        ))}</Swiper> 
                                    : <div className='fs-3 text-danger text-center'>
                                        *Promote your business here!* 
                                        Take advantage of this prime ad space and grow your brand. Place your sponsored ad today!
                                    </div>}
                            </div>

                            {/* Second Ads */}
                            <div className='common-banner-wrapper common-sidebar-banner'>
                                {sliderData4 && sliderData4.length > 0 ? 
                                <Swiper
                                    slidesPerView={1}
                                    loop={true}
                                    effect={'fade'}
                                    autoplay={{
                                        delay: 5000,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Autoplay, EffectFade, Pagination]}
                                    className="mySwiper custom-dots"
                                >
                                    {sliderData4.map((slide) => (
                                        <SwiperSlide key={slide._id}>
                                            <div className="slide d-flex align-items-end bg-contain" style={{ backgroundImage: `url(${slide.image})`, }} >
                                                {/* <div className="discount-badge poin">20% off</div> */}
                                                {slide.isCouponSelect ? <div className='discount-tag bg-danger rounded-circle d-flex align-items-center justify-content-center text-white small position-absolute p-2 lh-sm'>{slide.couponText.split(',')[0]}</div> : ''}
                                                
                                                <div className="content-wrapper text-light rounded-3 mb-5 p-2">
                                                    <div className="company-name fs-4 fw-bold lh-sm mb-1">{slide.title}</div>
                                                    <div className="company-subtitle fs-6 fw-normal mb-3">{slide.subCategory.length > 0 ? slide.subCategory[0].subCategoryName : ''}</div>
                                                    <div className="company-subtitle fs-6 fw-normal mb-3">{slide.ad_message}</div>
                                                    <div className='action d-flex flex-wrap gap-2 mb-3'>
                                                        {/* <Link type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-telephone-fill text-primary me-1"></i>Call
                                                        </Link>
                                                        <Link type='button' className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-envelope-fill text-primary me-1"></i>Email
                                                        </Link>
                                                        <Link type="button" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-globe-americas text-primary me-1"></i>Website
                                                        </Link>
                                                        <Link type="button" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-reply-fill text-primary me-1"></i>Directions
                                                        </Link>
                                                        <Link to={'#'} className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <img src={Cutlery} alt='cutlery' className="text-primary me-1" />Order
                                                        </Link>
                                                        <Link className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-pencil-fill text-primary me-1"></i>Text
                                                        </Link> */}
                                                        {slide.isPhoneSelect ? 
                                                            <Link type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleCallClick(slide)}><i className="bi bi-telephone-fill text-primary me-2"></i>Call
                                                            </Link>
                                                        : ''}
                                                        {slide.isEmailSelect ? <button type='button' className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleEmailClick(slide)}><i className="bi bi-envelope-fill text-primary me-2"></i>Email</button> : ''}
                                                        {slide.isWebsiteSelect ? <Link type="button" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnWebsite(slide, 'website')} ><i className="bi bi-globe-americas text-primary me-2"></i>Website</Link> : ''}
                                                        {slide.isDirectionSelect ? <button type="button" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnDirectionPage(slide)}><i className="bi bi-reply-fill text-primary me-2"></i>Directions</button> : ''}
                                                        {slide.isOrderNowSelect ? <Link to={'#'} className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnWebsite(slide, 'order')}><img src={Cutlery} alt='cutlery' className="text-primary me-2" />Order</Link> : ''}
                                                        {slide.isTextSelect ? <button className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleTextClick(slide)}> <i className="bi bi-pencil-fill text-primary me-2"></i>Text</button> : ''}
                                                    </div>
                                                    <div className="button-group d-flex flex-column gap-1">
                                                        {/* <button className='btn w-100 text-white lh-sm px-3' style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)' }} >Click here for a coupon</button> */}
                                                        {slide.isCouponSelect ? slide.couponText.includes('rewards') ?
                                                            <div type="button" className='text-white shadow p-3 w-100 mb-2 mt-2'
                                                                data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#rewardModal" : ''}
                                                                style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }}
                                                                onClick={() => handleCouponClick(slide, 'reward')}
                                                            >
                                                                {slide.couponText ? slide.couponText.split(',')[1] : 'Click here for the deal min order $30'}
                                                            </div>
                                                            :
                                                            <div type="button" className='text-white shadow p-3 w-100 mb-2 mt-2'
                                                                data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#couponModal" : ''}
                                                                style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }}
                                                                onClick={() => handleCouponClick(slide, 'coupon')}
                                                            >
                                                                {slide.couponText ? slide.couponText.split(',')[1] : 'Click here for the deal min order $30'}
                                                            </div>
                                                        : ''}

                                                        {slide.isCallForQuoteSelect ? <button className="btn btn-warning w-100 px-3" onClick={() => handleRequestAQuoteClick(slide)}>Request a Quote</button> : <></>}
                                                        {slide.isBookForAppointmentSelect ? <button className="btn btn-success w-100 px-3" onClick={() => handleBookAnAppointmentClick(slide)}>Book Appointment</button> : <></>}
                                                        {/* <button className="btn btn-danger w-100 px-3">Coupon Message</button> */}
                                                    </div>
                                                    <div class="sponserd text-white fw-medium mt-2">Sponsored Ad</div>
                                                </div>
                                            </div>
                                            </SwiperSlide>
                                    ))}</Swiper> 
                                : <div className='fs-3 text-danger text-center'>
                                    *Promote your business here!* 
                                    Take advantage of this prime ad space and grow your brand. Place your sponsored ad today!
                                </div>}
                            </div>

                            <div className='common-banner-wrapper common-sidebar-banner'>
                                {sliderData6 && sliderData6.length > 0 ? 
                                <Swiper
                                    slidesPerView={1}
                                    loop={true}
                                    effect={'fade'}
                                    autoplay={{
                                        delay: 5000,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Autoplay, EffectFade, Pagination]}
                                    className="mySwiper custom-dots"
                                >
                                    {sliderData6.map((slide) => (
                                        <SwiperSlide key={slide._id}>
                                            <div className="slide d-flex align-items-end bg-contain" style={{ backgroundImage: `url(${slide.image})`, }} >
                                                {/* <div className="discount-badge poin">20% off</div> */}
                                                {slide.isCouponSelect ? <div className='discount-tag bg-danger rounded-circle d-flex align-items-center justify-content-center text-white small position-absolute p-2 lh-sm'>{slide.couponText.split(',')[0]}</div> : ''}
                                                
                                                <div className="content-wrapper text-light rounded-3 mb-5 p-2">
                                                    <div className="company-name fs-4 fw-bold lh-sm mb-1">{slide.title}</div>
                                                    <div className="company-subtitle fs-6 fw-normal mb-3">{slide.subCategory.length > 0 ? slide.subCategory[0].subCategoryName : ''}</div>
                                                    <div className="company-subtitle fs-6 fw-normal mb-3">{slide.ad_message}</div>
                                                    <div className='action d-flex flex-wrap gap-2 mb-3'>
                                                        {/* <Link type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-telephone-fill text-primary me-1"></i>Call
                                                        </Link>
                                                        <Link type='button' className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-envelope-fill text-primary me-1"></i>Email
                                                        </Link>
                                                        <Link type="button" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-globe-americas text-primary me-1"></i>Website
                                                        </Link>
                                                        <Link type="button" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-reply-fill text-primary me-1"></i>Directions
                                                        </Link>
                                                        <Link to={'#'} className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <img src={Cutlery} alt='cutlery' className="text-primary me-1" />Order
                                                        </Link>
                                                        <Link className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-pencil-fill text-primary me-1"></i>Text
                                                        </Link> */}
                                                        {slide.isPhoneSelect ? 
                                                            <Link type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleCallClick(slide)}><i className="bi bi-telephone-fill text-primary me-2"></i>Call
                                                            </Link>
                                                        : ''}
                                                        {slide.isEmailSelect ? <button type='button' className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleEmailClick(slide)}><i className="bi bi-envelope-fill text-primary me-2"></i>Email</button> : ''}
                                                        {slide.isWebsiteSelect ? <Link type="button" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnWebsite(slide, 'website')} ><i className="bi bi-globe-americas text-primary me-2"></i>Website</Link> : ''}
                                                        {slide.isDirectionSelect ? <button type="button" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnDirectionPage(slide)}><i className="bi bi-reply-fill text-primary me-2"></i>Directions</button> : ''}
                                                        {slide.isOrderNowSelect ? <Link to={'#'} className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnWebsite(slide, 'order')}><img src={Cutlery} alt='cutlery' className="text-primary me-2" />Order</Link> : ''}
                                                        {slide.isTextSelect ? <button className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleTextClick(slide)}> <i className="bi bi-pencil-fill text-primary me-2"></i>Text</button> : ''}
                                                    </div>
                                                    <div className="button-group d-flex flex-column gap-1">
                                                        {/* <button className='btn w-100 text-white lh-sm px-3' style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)' }} >Click here for a coupon</button> */}
                                                        {slide.isCouponSelect ? slide.couponText.includes('rewards') ?
                                                            <div type="button" className='text-white shadow p-3 w-100 mb-2 mt-2'
                                                                data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#rewardModal" : ''}
                                                                style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }}
                                                                onClick={() => handleCouponClick(slide, 'reward')}
                                                            >
                                                                {slide.couponText ? slide.couponText.split(',')[1] : 'Click here for the deal min order $30'}
                                                            </div>
                                                            :
                                                            <div type="button" className='text-white shadow p-3 w-100 mb-2 mt-2'
                                                                data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#couponModal" : ''}
                                                                style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }}
                                                                onClick={() => handleCouponClick(slide, 'coupon')}
                                                            >
                                                                {slide.couponText ? slide.couponText.split(',')[1] : 'Click here for the deal min order $30'}
                                                            </div>
                                                        : ''}

                                                        {slide.isCallForQuoteSelect ? <button className="btn btn-warning w-100 px-3" onClick={() => handleRequestAQuoteClick(slide)}>Request a Quote</button> : <></>}
                                                        {slide.isBookForAppointmentSelect ? <button className="btn btn-success w-100 px-3" onClick={() => handleBookAnAppointmentClick(slide)}>Book Appointment</button> : <></>}
                                                        {/* <button className="btn btn-danger w-100 px-3">Coupon Message</button> */}
                                                    </div>
                                                    <div class="sponserd text-white fw-medium mt-2">Sponsored Ad</div>
                                                </div>
                                            </div>
                                            </SwiperSlide>
                                    ))}</Swiper> 
                                : <div className='fs-3 text-danger text-center'>
                                    *Promote your business here!* 
                                    Take advantage of this prime ad space and grow your brand. Place your sponsored ad today!
                                </div>}
                            </div>
                        </div>
                        {/* End: Common Sidebar Ads Code*/}
                    </div>
                </div>
            </div>

            {/* sub category modal data */}
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>All Subcategories</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        subCategoryModalInfo.map((info, i) => (
                            <div type='button' className="category-item nav-link d-flex gap-2 mb-2" key={info._id} onClick={(e) => handleSubCategoryClick(e, info)}><i className="bi bi-asterisk small text-success"></i>{info.name}</div>
                        ))
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}


const mapPropsToState = (state) => {
    return {
        loading: state.business.loading,
        error: state.business.error,
        success: state.business.success,
        categoryList: state.business.categoryList,
        banner: state.business.banner,

        sliderData3: state.business.slide3,
        sliderData4: state.business.slide4,
        sliderData5: state.business.slide5,
        sliderData6: state.business.slide6,
        sliderData1: state.business.slide1,
        sliderData2: state.business.slide2,
        
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getCategoryList: () => dispatch(actions.getCategoryList()),
        getCategoryDataSlides: (data) => dispatch(actions.getCategoryDataSlides(data)),
        
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(AllCategories))