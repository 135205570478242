import React from 'react';
import { Helmet } from "react-helmet";

const FAQList = () => {
    return (
        <div className="container mt-4">
            <Helmet>
                <title>CouponRanking | FAQ</title>
            </Helmet>
            <h1 className="text-center mb-4">Frequently Asked Questions (FAQ) - CouponRanking Rebates</h1>
            
            <div className="faq-section">
                <h3>1. How do I qualify for the CouponRanking Rebate?</h3>
                <p>To qualify, follow these steps:</p>
                <ul>
                    <li>Visit the CouponRanking website or app.</li>
                    <li>Request a quote, book an appointment, or redeem a coupon.</li>
                    <li>Complete the service and have the business mark "YES" on their CouponRanking profile.</li>
                    <li>Rate and leave a comment about the business on the platform.</li>
                    <li>Visit the 'Rebate Page' to claim your rebate.</li>
                </ul>
            </div>

            <div className="faq-section">
                <h3>2. How do I qualify for different rebate amounts?</h3>
                <p><strong>$500 Rebate:</strong> Use services from 20 different businesses (4 sponsored, 16 regular) within a month.</p>
                <p><strong>$200 Rebate:</strong> Use services from 12 businesses (2 sponsored, 10 regular) within a month.</p>
                <p><strong>$100 Rebate:</strong> Use services from 8 businesses (2 sponsored, 6 regular) within a month.</p>
                <p><strong>$20 Rebate:</strong> Use services from 4 businesses (1 sponsored ads, 3 regular) within a month.</p>
                <p><strong>$5 Rebate:</strong> Use services from 2 regular businesses within a month.</p>
            </div>

            <div className="faq-section">
                <h3>3. How do I confirm my completed service?</h3>
                <p>Ask the business to mark "YES" on their CouponRanking profile.</p>
            </div>

            <div className="faq-section">
                <h3>4. Can I submit the same business multiple times?</h3>
                <p>No, each rebate submission must be from a unique business.</p>
            </div>

            <div className="faq-section">
                <h3>5. How will I receive my rebate payment?</h3>
                <p>Payments are sent via Zelle or Cash App.</p>
            </div>

            <div className="faq-section">
                <h3>6. When do I get paid?</h3>
                <p>Rebates are paid on Fridays.</p>
            </div>

            <div className="faq-section">
                <h3>7. Do I submit my receipt from the businesses?</h3>
                <p>Yes, all receipts from the businesses must be uploaded or submitted for you to qualify for the rebate.</p>
            </div>

            <div className="faq-section">
                <p><strong>Important Notes:</strong> Limited offers are available each month. Follow all steps carefully to qualify.</p>
                <p>For assistance, contact us at <a href="mailto:sales@couponranking.com">sales@couponranking.com</a>.</p>
            </div>
        </div>
    );
};

export default FAQList;
