import React, { useEffect } from 'react';
// Our Packages Slick Slider
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux'
import { withSnackbar } from 'react-simple-snackbar'
import * as actions from '../store/actions/index';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PricingPlan = props => {
    console.log(props)
    let { getPlanList ,success} = props
    let { state } = useLocation()
    console.log(state)
    let navigate = useNavigate()
    useEffect(() => {
        if (state && state.adType) {
            getPlanList(state.adType); // Ensure this runs when adType is available
        }
    
        // Start: Package Plan Same Height JS
        var i = 0;
        var items = document.querySelectorAll(".package-plan .package-info .list-cover");
        var itemsHeight = [];

        for (i = 0; i < items.length; i++) {
            itemsHeight.push(items[i].offsetHeight);
        }

        var maxHeight = Math.max(...itemsHeight);

        for (i = 0; i < items.length; i++) {
            items[i].style.height = maxHeight + "px";
        }
        getPlanList(state.adType)
        if(success){
            toast.success(success, { autoClose: 1000 })
        }
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
          });
    }, [getPlanList, state.adType, success]);
    console.log(getPlanList)
    // Slick Props
    var settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    let details = props.planList ? props.planList : []
    var isPresent;
    var filteredPlanDetails = [];
    var matchWords = ["(C &D)"];
    for (var index = 0; index < details.length; index++) {
        // commented by simran
        // isPresent = true;
        // for (var outer = 0; outer < matchWords.length; outer++) {
        //     if (details[index].advertisement_type.indexOf(matchWords[outer]) === -1) {
        //         isPresent = false;
        //         break;
        //     }
        // }
        // if (isPresent) {
        //     filteredPlanDetails.push(details[index]);
        // }
        filteredPlanDetails.push(details[index]);
    }
    let PlanlistByPrice = filteredPlanDetails.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
    //per click functionality
    for (var i = 0; i < PlanlistByPrice.length; i++) {
        if (PlanlistByPrice[i].advertisement_type === 'Marketplace Gold Plan (C &D)' || PlanlistByPrice[i].advertisement_type === 'Marketplace Platinum Plan (C &D)') {
            PlanlistByPrice[i].perClickText = 'per lead/month'
        } else if (PlanlistByPrice[i].advertisement_type === 'Coupon Silver plan (C &D)' || PlanlistByPrice[i].advertisement_type === 'Coupon Gold Plan (C &D)') {
            PlanlistByPrice[i].perClickText = 'per lead/month(per coupon)'
        } else if (PlanlistByPrice[i].advertisement_type === 'Local Digital Ad Plan (C &D)' || PlanlistByPrice[i].advertisement_type === 'Regional Digital Ad Plan (C &D)' || PlanlistByPrice[i].advertisement_type === 'World Digital Ad Plan (C &D)') {
            PlanlistByPrice[i].perClickText = 'per click/month'
        } else if (PlanlistByPrice[i].advertisement_type === 'Coupon Bronze Plan (C &D)') {
            PlanlistByPrice[i].perClickText = 'per coupon/month'
        } else if (PlanlistByPrice[i].advertisement_type === 'Deals Plan (C &D)') {
            PlanlistByPrice[i].perClickText = 'per deal/month'
        } else if (PlanlistByPrice[i].advertisement_type === 'Marketplace Silver Plan (C &D)') {
            PlanlistByPrice[i].perClickText = 'per email/month (per text)'
        } else if (PlanlistByPrice[i].advertisement_type === 'Marketplace Bronze Plan (C &D)') {
            PlanlistByPrice[i].perClickText = 'per click/month(per call, per direction & per order)'
        } else {
            PlanlistByPrice[i].perClickText = ''
        }
    }
       
    const subscribePlan = (planId) => {
          let storedToken = localStorage.getItem("token")
          let data = {
            planId:planId,
            token:storedToken?storedToken:state.token
        }
        console.log(data, state.adType, "data, state.adType")
          props.subscribePlanByUser(data, state.adType,navigate)
    }
    
    return (
        <div className='pricing-plan-page py-4 py-xl-5'>
            {/* title head */}
            <Helmet>
                <title>CouponRanking | Pricing Plan</title>
            </Helmet>
            <ToastContainer />
            <div className='container'>
                <div className="text-center mx-auto mb-4 pb-xl-3" style={{ maxWidth: '654px' }}>
                    <div className="section-heading fs-3 fw-bold lh-sm mb-1">Pricing Plans</div>
                    <div className="section-subheading text-muted">Take your digital advertising to the next level. Get the results you deserve at a price you can afford.</div>
                </div>
                {
                    PlanlistByPrice.length > 0 ?
                        <div className="package-plan slider-wrapper">
                            <Slider {...settings} className="custom-slick">
                                {PlanlistByPrice.map((ele, index) => (
                                    <div className='package-item bg-white border border-white text-center h-100' key={index}>
                                        <div className='package-header bg-primary d-flex align-items-center justify-content-center p-3'>
                                            <label className='text-light fs-5 fw-semibold'>{ele.advertisement_type}</label>
                                        </div>
                                        <div className='price-wrapper bg-primary bg-opacity-10 p-3'>
                                            {/* <div className='package-label text-secondary fw-medium'>{ele.description_of_original_price}</div> */}
                                            <div className='package-price fs-2 fw-bold lh-sm'>${ele.price}</div>
                                            <div className='package-label text-secondary fw-medium'>{ele.perClickText}</div>
                                        </div>
                                        <div className='package-info d-flex flex-column justify-content-between px-3 px-xl-4 py-4'>
                                            <div className='list-cover text-start' dangerouslySetInnerHTML={{
                                                __html:
                                                    (ele.planDetail !== null) ? ele.planDetail : "waiting"
                                            }}>
                                            </div>
                                            <div className='btn-wrapper mt-4'>
                                                <button  type='button' className='btn btn-primary rounded-1 px-5 py-2'onClick={()=>subscribePlan(ele._id)}>Select Your Plan</button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div> : ''
                }
            </div>
        </div>
    )
}


const mapPropsToState = (state) => {
    return {
        loading: state.subscriptionPlan.loading,
        error: state.subscriptionPlan.error,
        success: state.subscriptionPlan.success,
        planList: state.subscriptionPlan.planListing
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getPlanList: (adType) => dispatch(actions.getPlanList(adType)),
        subscribePlanByUser:(data, adType, navigate) => dispatch(actions.subscribePlanByUser(data, adType,navigate)),
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(PricingPlan))