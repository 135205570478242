import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withSnackbar } from 'react-simple-snackbar'
import * as actions from '../store/actions/index';
import { Rating } from 'react-simple-star-rating';
import Cutlery from '../assets/cutlery.svg'
import ReactPaginate from 'react-paginate';

// Import Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';

// import required modules
import { Autoplay, EffectFade, Pagination } from 'swiper/modules';


const BusinessImg = [
    {
        id: 1,
        image: require('../assets/business-cleaning.jpg'),
    },
    {
        id: 2,
        image: require('../assets/business-repair.jpg'),
    },
    {
        id: 3,
        image: require('../assets/business-grocery.jpg'),
    },
    {
        id: 4,
        image: require('../assets/business-cafe.jpg'),
    }
];

const slidesData = [
    {
        id: 1,
        title: "Banje Hutz",
        subtitle: "Indian Restaurant",
        image: require('../assets/food-banner1.jpg'),
    },
    {
        id: 2,
        title: "Pizza Hutz",
        subtitle: "Pizza Restaurant",
        image: require('../assets/food-banner2.jpg'),
    },
    {
        id: 3,
        title: "The GT Road Cafe",
        subtitle: "Indian/Chinese Cafe",
        image: require('../assets/food-banner3.jpg'),
    },
];

const DealBusinessPage = (props) => {
    const { getDealBusinessList, dealBusinessList } = props
    const { state } = useLocation();
    useEffect(() => {
        const getLocationforBusiness = async () => {
            let lat = await localStorage.getItem("latitude")
            let long = await localStorage.getItem("longitude")
            let data = {
                latitude: parseFloat(lat),
                longitude: parseFloat(long),
                type: 'deal'
            }
            return await getDealBusinessList(data)
        }
        getLocationforBusiness()
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        });
    }, [getDealBusinessList, state])
    const navigate = useNavigate()
    let token = localStorage.getItem('token')
    let userInfo = JSON.parse(localStorage.getItem('userInfo'))

    // pagination functionality start
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + 9;  //number here represent how many item you want to show on page
    const currentItems = dealBusinessList.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(dealBusinessList.length / 9);
    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * 9) % dealBusinessList.length;
        setItemOffset(newOffset);
    };
    //pagination functionality end

    const getRequiredAddress = (str, from) => {
        var arr = str.split(',');
        arr.splice(0, from);
        return arr.join(',');
    }

    const [rating, setRating] = useState(0)
    const [ratingComment, setRatingComment] = useState('')
    const [selectedReviewBusiness, setSelectedBusiness] = useState({
        businessName: '',
        businessId: ''
    })

    const submitReview = () => {
        let creatorId = userInfo._id
        let data = {
            rating: rating,
            ratingFor: 'businessWebite',
            comment: ratingComment,
            creator_id: creatorId,
            receiver_id: selectedReviewBusiness.businessId
        }
        props.makeRating(data)
    }
    const checkRatingModal = (business) => {
        if (token !== null) {
            setSelectedBusiness({ businessName: business.name, businessId: business._id })
        } else {
            navigate('/login')
        }
    }
    //handle business details on view details
    const handleBusinessDetails = (event, business) => {
        let addr = business.address ? getRequiredAddress(business.address, 1) : business.city ? business.city : business.name;
        let subcategoryname = business.subCategory.length > 0 ? business.subCategory[0].subCategoryName.replaceAll(/ /g, "-").toLowerCase() : "subcategory"
        navigate(`/business-details/${business.name.replaceAll(/ /g, "-").toLowerCase()}/${subcategoryname}/${addr.replaceAll(/ /g, "-").toLowerCase()}`, { state: { businessId: business._id } })
    }

    const handleDealClick = (business) => {
        let token = localStorage.getItem('token')
        if (!token) {
            navigate('/login')
        } else {
            let customerInfo = JSON.parse(localStorage.getItem('userInfo'));
            let mobileNo = customerInfo.country_code + customerInfo.mobile_no
            let customerName = customerInfo.name;
            let customerEmail = customerInfo.email;
            let data = {
                mobileNo: mobileNo,
                customerName: customerName,
                customerEmail: customerEmail,
                businessName: business.name,
                businessEmail: business.email,
                businessId: business._id,
                clickType: 'deal'
            }
            props.clickPerCount(data)
            window.open(`https://${business.website}`, '_blank', 'noopener,noreferrer')
        }
    }

    return (
        <div className='CategoryBusiness nearby-business-sec pt-4 pb-5'>
            {/* title head */}
            <Helmet>
                <title>Deal Business</title>
            </Helmet>

            {/* Start: Common Slider Code*/}
            <div className='common-banner-wrapper mb-5 mt-n4'>
                <Swiper
                    slidesPerView={1}
                    loop={true}
                    effect={'fade'}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Autoplay, EffectFade, Pagination]}
                    className="mySwiper custom-dots"
                >
                    {slidesData.map((slide) => (
                        <SwiperSlide key={slide.id}>
                            <div className="slide d-flex align-items-center bg-contain" style={{ backgroundImage: `url(${slide.image})`, }} >
                                <div className="container">
                                    <div className="content-wrapper text-light rounded-3 p-3 p-md-4">
                                        <div className="company-name fs-2 fw-bold lh-sm mb-1">{slide.title}</div>
                                        <div className="company-subtitle fs-6 fw-normal mb-3">{slide.subtitle}</div>
                                        <div className="discount-badge poin">20% off</div>
                                        <div className="button-group row g-2">
                                            <div className="col-6">
                                                <button className="btn btn-warning w-100 px-3">Request a Quote</button>
                                            </div>
                                            <div className="col-6">
                                                <button className="btn btn-success w-100 px-3">Book Appointment</button>
                                            </div>
                                            <div className="col-12">
                                                <button className="btn btn-danger w-100 px-3">Coupon Message</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            {/* End: Common Slider Code*/}

            <div className='container-fluid'>
                <div className='section-header text-center mb-4'>
                    <div className='section-label fs-2'>Top 10 Best Deals for Businesses Online</div>
                    <div className='section-sublabel text-dark text-opacity-75'>Looking for great deals? You have come to the right place! We have got exclusive discounts on the latest products and services. Hurry, before they are gone!</div>
                </div>

                <div className="row g-3">
                    <div className="col-lg-3 col-xl-2">
                        {/* Start: Common Sidebar Ads Code*/}
                        <div className="ads-wrapper d-flex flex-column gap-3">
                            <div className='common-banner-wrapper common-sidebar-banner'>
                                <Swiper
                                    slidesPerView={1}
                                    loop={true}
                                    effect={'fade'}
                                    autoplay={{
                                        delay: 5000,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Autoplay, EffectFade, Pagination]}
                                    className="mySwiper custom-dots"
                                >
                                    {slidesData.map((slide) => (
                                        <SwiperSlide key={slide.id}>
                                            <div className="slide d-flex align-items-end bg-contain" style={{ backgroundImage: `url(${slide.image})`, }} >
                                                <div className="discount-badge poin">20% off</div>
                                                <div className="content-wrapper text-light rounded-3 mb-5 p-2">
                                                    <div className="company-name fs-4 fw-bold lh-sm mb-1">{slide.title}</div>
                                                    <div className="company-subtitle fs-6 fw-normal mb-3">{slide.subtitle}</div>
                                                    <div className='action d-flex flex-wrap gap-2 mb-3'>
                                                        <Link type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-telephone-fill text-primary me-1"></i>Call
                                                        </Link>
                                                        <Link type='button' className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-envelope-fill text-primary me-1"></i>Email
                                                        </Link>
                                                        <Link type="button" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-globe-americas text-primary me-1"></i>Website
                                                        </Link>
                                                        <Link type="button" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-reply-fill text-primary me-1"></i>Directions
                                                        </Link>
                                                        <Link to={'#'} className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <img src={Cutlery} alt='cutlery' className="text-primary me-1" />Order
                                                        </Link>
                                                        <Link className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-pencil-fill text-primary me-1"></i>Text
                                                        </Link>
                                                    </div>
                                                    <div className="button-group d-flex flex-column gap-1">
                                                        <button className='btn w-100 text-white lh-sm px-3' style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)' }} >Click here for a coupon</button>
                                                        <button className="btn btn-warning w-100 px-3">Request a Quote</button>
                                                        <button className="btn btn-success w-100 px-3">Book Appointment</button>
                                                        <button className="btn btn-danger w-100 px-3">Coupon Message</button>
                                                    </div>
                                                    <div class="sponserd text-white fw-medium mt-2">Sponsored Ad</div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>

                            {/* Second Ads */}
                            <div className='common-banner-wrapper common-sidebar-banner'>
                                <Swiper
                                    slidesPerView={1}
                                    loop={true}
                                    effect={'fade'}
                                    autoplay={{
                                        delay: 5000,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Autoplay, EffectFade, Pagination]}
                                    className="mySwiper custom-dots"
                                >
                                    {slidesData.map((slide) => (
                                        <SwiperSlide key={slide.id}>
                                            <div className="slide d-flex align-items-end bg-contain" style={{ backgroundImage: `url(${slide.image})`, }} >
                                                <div className="discount-badge poin">20% off</div>
                                                <div className="content-wrapper text-light rounded-3 mb-5 p-2">
                                                    <div className="company-name fs-4 fw-bold lh-sm mb-1">{slide.title}</div>
                                                    <div className="company-subtitle fs-6 fw-normal mb-3">{slide.subtitle}</div>
                                                    <div className='action d-flex flex-wrap gap-2 mb-3'>
                                                        <Link type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-telephone-fill text-primary me-1"></i>Call
                                                        </Link>
                                                        <Link type='button' className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-envelope-fill text-primary me-1"></i>Email
                                                        </Link>
                                                        <Link type="button" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-globe-americas text-primary me-1"></i>Website
                                                        </Link>
                                                        <Link type="button" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-reply-fill text-primary me-1"></i>Directions
                                                        </Link>
                                                        <Link to={'#'} className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <img src={Cutlery} alt='cutlery' className="text-primary me-1" />Order
                                                        </Link>
                                                        <Link className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-pencil-fill text-primary me-1"></i>Text
                                                        </Link>
                                                    </div>
                                                    <div className="button-group d-flex flex-column gap-1">
                                                        <button className='btn w-100 text-white lh-sm px-3' style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)' }} >Click here for a coupon</button>
                                                        <button className="btn btn-warning w-100 px-3">Request a Quote</button>
                                                        <button className="btn btn-success w-100 px-3">Book Appointment</button>
                                                        <button className="btn btn-danger w-100 px-3">Coupon Message</button>
                                                    </div>
                                                    <div class="sponserd text-white fw-medium mt-2">Sponsored Ad</div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </div>
                        {/* End: Common Sidebar Ads Code*/}
                    </div>

                    <div className="col-lg-6 col-xl-8">
                        <div className='row g-3'>
                            {currentItems.length > 0 ?
                                currentItems.map((business, index) => (
                                    <div className='col-sm-6 col-xl-4 col-xxl-3' key={index}>
                                        <div className='business-details border rounded-3 overflow-hidden' key={index}>
                                            <div className='business-img position-relative'>
                                                <Swiper slidesPerView={1} loop={true} modules={[Autoplay, Pagination]} className="mySwiper custom-dots"
                                                    autoplay={{ delay: 2500, disableOnInteraction: false, }}
                                                    pagination={{ clickable: true }}
                                                >
                                                    {
                                                        business.businessPicture && business.businessPicture.length > 0 ?
                                                            business.businessPicture.map((item, idx) => (
                                                                <SwiperSlide><img src={item.pictureUrl} key={idx} className='img-fluid' alt='Cleaning' /></SwiperSlide>
                                                            ))
                                                            :
                                                            BusinessImg.map((item, idx) => (
                                                                <SwiperSlide><img src={item.image} key={idx} className='img-fluid' alt='Cleaning' /></SwiperSlide>
                                                            ))
                                                    }
                                                </Swiper>
                                                {business.isDealSelect ? <div className='discount-tag bg-danger rounded-circle d-flex align-items-center justify-content-center text-white small position-absolute p-2 lh-sm'>{business.dealText.split(',')[0]}</div> : ''}
                                            </div>
                                            <div className="business-info bg-white text-center p-3">
                                                <div className='name fs-18 fw-medium text-capitalize mb-1'>{business.name}</div>
                                                <div className='review-wrapper d-flex align-items-center justify-content-center gap-2 mb-1'>
                                                    <div className='rating-number'>{business.rating > 0 ? business.rating.toFixed(1) : 5}</div>
                                                    <div className='review-star d-flex justify-content-center gap-1'>
                                                        {business.rating > 0 ?
                                                            <Rating initialValue={business.rating} size={"20px"} readonly={true} allowFraction={true} />
                                                            :
                                                            <Rating initialValue={5} size={"20px"} readonly={true} />
                                                        }
                                                    </div>
                                                    <div className='rating-number'>{business.ratingCount > 0 ? `(${business.ratingCount})` : '(1)'}</div>
                                                </div>
                                                <div className='name text-muted text-capitalize mb-1'>{business.subCategory.length > 0 ? business.subCategory[0].subCategoryName : ''}</div>

                                                {business.isDealSelect ? <div type='button' className='text-white shadow p-3 w-100 mb-2 mt-2' style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }}
                                                    onClick={() => handleDealClick(business)}>{business.dealText.split(',')[1]}</div> : ''}
                                                <div className='write-review'>
                                                    <button className='btn btn-danger w-100 d-inline-flex align-items-center justify-content-center py-2' data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#reviewModal1" : ''} onClick={() => checkRatingModal(business)}><i className="bi bi-pencil me-2"></i>Write a review</button>
                                                </div>
                                                <button type='button' className='w-100 btn btn-primary mt-2' onClick={(event) => handleBusinessDetails(event, business)}>View Details</button>
                                            </div>
                                        </div>
                                    </div>
                                ))
                                :
                                <div className='fs-3 text-danger text-center'>
                                    There is no business in your area!
                                </div>
                            }
                            {/* pagination section */}
                            <div className='pagination-wrapper pt-3'>
                                <ReactPaginate
                                    className="pagination justify-content-center m-1"
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    nextLabel="&raquo;"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={1}
                                    marginPagesDisplayed={2}
                                    pageCount={pageCount}
                                    previousLabel="&laquo;"
                                    renderOnZeroPageCount={null}
                                    containerClassName="pagination"
                                    activeClassName="active"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-xl-2">
                        {/* Start: Common Sidebar Ads Code*/}
                        <div className="ads-wrapper d-flex flex-column gap-3">
                            <div className='common-banner-wrapper common-sidebar-banner'>
                                <Swiper
                                    slidesPerView={1}
                                    loop={true}
                                    effect={'fade'}
                                    autoplay={{
                                        delay: 5000,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Autoplay, EffectFade, Pagination]}
                                    className="mySwiper custom-dots"
                                >
                                    {slidesData.map((slide) => (
                                        <SwiperSlide key={slide.id}>
                                            <div className="slide d-flex align-items-end bg-contain" style={{ backgroundImage: `url(${slide.image})`, }} >
                                                <div className="discount-badge poin">20% off</div>
                                                <div className="content-wrapper text-light rounded-3 mb-5 p-2">
                                                    <div className="company-name fs-4 fw-bold lh-sm mb-1">{slide.title}</div>
                                                    <div className="company-subtitle fs-6 fw-normal mb-3">{slide.subtitle}</div>
                                                    <div className='action d-flex flex-wrap gap-2 mb-3'>
                                                        <Link type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-telephone-fill text-primary me-1"></i>Call
                                                        </Link>
                                                        <Link type='button' className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-envelope-fill text-primary me-1"></i>Email
                                                        </Link>
                                                        <Link type="button" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-globe-americas text-primary me-1"></i>Website
                                                        </Link>
                                                        <Link type="button" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-reply-fill text-primary me-1"></i>Directions
                                                        </Link>
                                                        <Link to={'#'} className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <img src={Cutlery} alt='cutlery' className="text-primary me-1" />Order
                                                        </Link>
                                                        <Link className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-pencil-fill text-primary me-1"></i>Text
                                                        </Link>
                                                    </div>
                                                    <div className="button-group d-flex flex-column gap-1">
                                                        <button className='btn w-100 text-white lh-sm px-3' style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)' }} >Click here for a coupon</button>
                                                        <button className="btn btn-warning w-100 px-3">Request a Quote</button>
                                                        <button className="btn btn-success w-100 px-3">Book Appointment</button>
                                                        <button className="btn btn-danger w-100 px-3">Coupon Message</button>
                                                    </div>
                                                    <div class="sponserd text-white fw-medium mt-2">Sponsored Ad</div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>

                            {/* Second Ads */}
                            <div className='common-banner-wrapper common-sidebar-banner'>
                                <Swiper
                                    slidesPerView={1}
                                    loop={true}
                                    effect={'fade'}
                                    autoplay={{
                                        delay: 5000,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Autoplay, EffectFade, Pagination]}
                                    className="mySwiper custom-dots"
                                >
                                    {slidesData.map((slide) => (
                                        <SwiperSlide key={slide.id}>
                                            <div className="slide d-flex align-items-end bg-contain" style={{ backgroundImage: `url(${slide.image})`, }} >
                                                <div className="discount-badge poin">20% off</div>
                                                <div className="content-wrapper text-light rounded-3 mb-5 p-2">
                                                    <div className="company-name fs-4 fw-bold lh-sm mb-1">{slide.title}</div>
                                                    <div className="company-subtitle fs-6 fw-normal mb-3">{slide.subtitle}</div>
                                                    <div className='action d-flex flex-wrap gap-2 mb-3'>
                                                        <Link type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-telephone-fill text-primary me-1"></i>Call
                                                        </Link>
                                                        <Link type='button' className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-envelope-fill text-primary me-1"></i>Email
                                                        </Link>
                                                        <Link type="button" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-globe-americas text-primary me-1"></i>Website
                                                        </Link>
                                                        <Link type="button" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-reply-fill text-primary me-1"></i>Directions
                                                        </Link>
                                                        <Link to={'#'} className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <img src={Cutlery} alt='cutlery' className="text-primary me-1" />Order
                                                        </Link>
                                                        <Link className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-pencil-fill text-primary me-1"></i>Text
                                                        </Link>
                                                    </div>
                                                    <div className="button-group d-flex flex-column gap-1">
                                                        <button className='btn w-100 text-white lh-sm px-3' style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)' }} >Click here for a coupon</button>
                                                        <button className="btn btn-warning w-100 px-3">Request a Quote</button>
                                                        <button className="btn btn-success w-100 px-3">Book Appointment</button>
                                                        <button className="btn btn-danger w-100 px-3">Coupon Message</button>
                                                    </div>
                                                    <div class="sponserd text-white fw-medium mt-2">Sponsored Ad</div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </div>
                        {/* End: Common Sidebar Ads Code*/}
                    </div>
                </div>
            </div>

            {/* Review Model Popup */}
            <div className="modal fade" id="reviewModal1" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="reviewModal1Label" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header bg-primary text-white px-sm-4">
                            <h1 className="modal-title fs-18 text-capitalize lh-sm" id="reviewModal1Label">{selectedReviewBusiness.businessName}</h1>
                            <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" onClick={() => setRating(0)}></button>
                        </div>
                        <div className="modal-body px-3 px-sm-4 py-4">
                            <div className="review-wrapper mb-2">
                                <div className="star-wrapper d-flex gap-2 fs-5 mb-1">
                                    <Rating emptySymbol="bi bi-star" fullSymbol="bi bi-star-fill"
                                        initialValue={rating}
                                        value={rating}
                                        onClick={(value) => setRating(value)}
                                    />
                                </div>
                                <p className='m-0 text-muted'>Write your review for <small className='fw-medium text-capitalize'>{selectedReviewBusiness.businessName}</small></p>
                            </div>
                            <div className="review-message">
                                <textarea className="form-control small" id="dealMessage" rows="5" placeholder='Enter message here...' required
                                    onChange={(event) => setRatingComment(event.target.value)}
                                ></textarea>
                                <div className='invalid-feedback'></div>
                            </div>
                            <button className='w-100 btn btn-primary py-2 mt-3' data-bs-dismiss="modal" onClick={() => submitReview()}>Submit Review</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const mapPropsToState = (state) => {
    return {
        loading: state.business.loading,
        error: state.business.error,
        success: state.business.success,
        dealBusinessList: state.business.dealBusinessList,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getDealBusinessList: (data) => dispatch(actions.getDealBusinessList(data)),
        clickPerCount: (data) => dispatch(actions.clickPerCount(data)),
        makeRating: (data) => dispatch(actions.makeRating(data)),
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(DealBusinessPage))
