import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { withSnackbar } from 'react-simple-snackbar'
import { Helmet } from "react-helmet";
import * as actions from '../store/actions/index'
// import Banner1 from '../assets/bg-banner1.jpg'
import Banner2 from '../assets/bg-banner2.jpg'
import Banner3 from '../assets/bg-banner3.jpg'
import Banner4 from '../assets/bg-banner4.jpg'
// import Banner5 from '../assets/bg-banner5.jpg'
// import Banner6 from '../assets/bg-banner6.jpg'
import Banner7 from '../assets/bg-banner7.jpg'
import Banner8 from '../assets/bg-banner8.jpg'
import Banner9 from '../assets/bg-banner9.jpg'
import Cutlery from '../assets/cutlery.svg'
// Our Packages Slick Slider
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState } from 'react';
import { Rating } from 'react-simple-star-rating'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Import Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';

const Business = [
    {
        image: require('../assets/business-cleaning.jpg'),
        tagLine: 'Voted Central Illinois’ 20 Best Restaurants In 2024.',
        name: "Johnson Cleaning",
        service_rate: "$49.99/hour",
        timing: "Open 11:00 am - 8:00pm",
        address: "Champaign, Il.",
    },
    {
        image: require('../assets/business-repair.jpg'),
        tagLine: 'Voted Central Illinois’ 20 Best Auto Repair Businesses In 2024',
        name: "Rick Auto Repair",
        service_rate: "$120/hour",
        timing: "Close 10:00pm - 8:00am",
        address: "Danvile ,Il.",
    },
    {
        image: require('../assets/business-grocery.jpg'),
        tagLine: 'Voted Central Illinois’ 20 Best Event Planning Companies In 2024.',
        name: "Jason Grocery Store",
        service_rate: "$49.99/hour",
        timing: "Open 11:00 am - 8:00pm",
        address: "Champaign, Il.",
    },
    {
        image: require('../assets/business-cafe.jpg'),
        tagLine: 'Voted Central Illinois’  20 Best Catering Businesses In 2024.',
        name: "Johny Cafe",
        service_rate: "$50.99/hour",
        timing: "Open 11:00 am - 8:00pm",
        address: "Danvile ,Il.",
    },
    // {
    //     image: require('../assets/business-cleaning.jpg'),
    //     name: "Johnson Cleaning",
    //     service_rate: "$49.99/hour",
    //     timing: "Open 11:00 am - 8:00pm",
    //     address: "Champaign, Il.",
    // }
];

const BusinessImg = [
    {
        id: 1,
        image: require('../assets/business-cleaning.jpg'),
    },
    {
        id: 2,
        image: require('../assets/business-repair.jpg'),
    },
    {
        id: 3,
        image: require('../assets/business-grocery.jpg'),
    },
    {
        id: 4,
        image: require('../assets/business-cafe.jpg'),
    }
];

const BusinessImage = [
    {
        id: 1,
        image: require('../assets/taxi.jpg'),
        text: 'Free Page',
    },
    {
        id: 2,
        image: require('../assets/handyman.jpg'),
        text: 'Request A Quote',
    },
    {
        id: 3,
        image: require('../assets/beauty-salon.jpg'),
        text: 'Book Appointment',
    },
    {
        id: 4,
        image: require('../assets/auto-repair.jpg'),
        text: 'Email & Text Message',
    },
    {
        id: 5,
        image: require('../assets/clothing-boutique.jpg'),
        text: 'E-Commerce Ad',
    },
    {
        id: 6,
        image: require('../assets/restaurants.jpg'),
        text: 'Coupon',
    }
];

const CustomerImage = [
    {
        id: 1,
        image: require('../assets/shoe-boutique.jpg'),
        text: 'Deals',
    },
    {
        id: 2,
        image: require('../assets/coffee-shop.jpg'),
        text: 'Rewards Programe',
    },
    {
        id: 3,
        image: require('../assets/auto-detailing.jpg'),
        text: 'Comment & Rating',
    }
];

const getCity = (addressArray) => {
    let city = '';
    for (let i = 0; i < addressArray.length; i++) {
        if (addressArray[i].types[0] && 'locality' === addressArray[i].types[0]) {
            city = addressArray[i].long_name;
            return city;
        }
    }
};
const getState = (addressArray) => {
    let state = '';
    for (let i = 0; i < addressArray.length; i++) {
        for (let i = 0; i < addressArray.length; i++) {
            if (addressArray[i].types[0] && 'administrative_area_level_1' === addressArray[i].types[0]) {
                state = addressArray[i].long_name;
                return state;
            }
        }
    }
};

const formatAMPM = (date) => {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

const Home = (props) => {
    const [selectedReviewBusiness, setSelectedBusiness] = useState({
        businessName: '',
        businessId: ''
    })
    const [callDetails, setCallDetails] = useState({
        country_code: '',
        mobile_no: ''
    })
    const [couponDirection, setCouponDirection] = useState({
        destLat: '',
        desLong: '',
        businessName: '',
        address: ''
    })
    const [rating, setRating] = useState(0)
    const [ratingComment, setRatingComment] = useState('')
    // const [slidesData, setSlideData] = useState([])
    const { getBusinessList, getCategoryList, getBlogList, ratingSuccess, getEcommerceBusinessList,
        eComBusinessList, getCouponBusinessList, getCouponrankingRatingList, couporankingRatingList, sliderData1,
        sliderData2, sliderData3, sliderData4, sliderData5, sliderData6, banner,
        getBusinessListSlide
    } = props
    console.log(sliderData1)
    const navigate = useNavigate()
    let token = localStorage.getItem('token')
    let userInfo = JSON.parse(localStorage.getItem('userInfo'))
    // console.log(slide);
    useEffect(() => {
        console.log("simran")
        async function fetchLocation() {
            // You can await here
            console.log(await navigator.permissions.query({ name: "geolocation" }), 'location')
            await navigator.geolocation.getCurrentPosition(async (position) => {
                let checkLoctionStatus = await navigator.permissions.query({ name: "geolocation" })
                if (checkLoctionStatus.state === "granted") {
                    await localStorage.setItem("latitude", position.coords.latitude);
                    await localStorage.setItem("longitude", position.coords.longitude);
                    let data = {
                        latitude: parseFloat(position.coords.latitude),
                        longitude: parseFloat(position.coords.longitude),
                        key: "homepage",
                    }
                    await getBusinessList(data)
                    await getBusinessListSlide(data)
                    data['type'] = 'coupon'
                    await getEcommerceBusinessList(data)
                    await getCouponBusinessList(data)
                    if (position.coords.latitude && position.coords.longitude) {
                        let lat = position.coords.latitude;
                        let lng = position.coords.longitude
                        fetch('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + lng + '&key=AIzaSyB_KZHG7uYGsaMGdQLDRrV3Qw6xRvLqg_Q', {
                            method: 'GET'
                        }).then((response) => response.json())
                            .then(async (data) => {
                                if (data.results[1]) {
                                    const address = data.results[0].formatted_address,
                                        addressArray = data.results[0].address_components,
                                        city = getCity(addressArray),
                                        state = getState(addressArray);
                                    await localStorage.setItem("city", city);
                                    await localStorage.setItem("state", state);
                                }
                            })
                            .catch((error) => {
                                console.error('Error:', error)
                            })
                    }
                } else if (checkLoctionStatus.state === "prompt") {
                    await localStorage.setItem("latitude", position.coords.latitude);
                    await localStorage.setItem("longitude", position.coords.longitude);
                    let data = {
                        latitude: parseFloat(position.coords.latitude),
                        longitude: parseFloat(position.coords.longitude),
                        key: "homepage",
                    }

                    await getBusinessList(data)
                    await getBusinessListSlide(data)

                    data['type'] = 'coupon'
                    await getEcommerceBusinessList(data)
                    await getCouponBusinessList(data)
                    if (position.coords.latitude && position.coords.longitude) {
                        let lat = position.coords.latitude;
                        let lng = position.coords.longitude
                        fetch('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + lng + '&key=AIzaSyB_KZHG7uYGsaMGdQLDRrV3Qw6xRvLqg_Q', {
                            method: 'GET'
                        }).then((response) => response.json())
                            .then(async (data) => {
                                if (data.results[1]) {
                                    const address = data.results[0].formatted_address,
                                        addressArray = data.results[0].address_components,
                                        city = getCity(addressArray),
                                        state = getState(addressArray);
                                    await localStorage.setItem("city", city);
                                    await localStorage.setItem("state", state);
                                }
                            })
                            .catch((error) => {
                                console.error('Error:', error)
                            })
                    }
                } else if (checkLoctionStatus.state === "denied") {
                    let data = {
                        latitude: parseFloat(40.10783),
                        longitude: parseFloat(-88.25667),
                        key: "homepage",
                    }
                    let zeroLocation = {
                        latitude: parseFloat('00.00'),
                        longitude: parseFloat('00.00'),
                        key: "homepage",
                    }
                    await getBusinessList(zeroLocation)
                    await getBusinessListSlide(zeroLocation)

                    data['type'] = 'coupon'
                    await getEcommerceBusinessList(data)
                    await getCouponBusinessList(data)
                }
            })

        }
        fetchLocation()
        async function getLocationforBusiness() {
            // You can await here
            let lat = await localStorage.getItem("latitude") === null ? -88.25667 : localStorage.getItem("latitude");
            let long = await localStorage.getItem("longitude") === null ? 40.10783 : localStorage.getItem("longitude")
            let data = {
                latitude: parseFloat(lat),
                longitude: parseFloat(long),
                key: "homepage",
            }
            data['type'] = 'coupon'
            await getEcommerceBusinessList(data)
            await getCouponBusinessList(data)
            await getBusinessListSlide(data)

            return await getBusinessList(data)
        }
        async function getCategory() {
            return await getCategoryList()
        }
        getLocationforBusiness()
        getCategory()
        getBlogList()

        // for loading page from top after re-render from any section
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        });
        toast.success(ratingSuccess)
    }, [getBusinessList, getCategoryList, getBlogList, ratingSuccess, getEcommerceBusinessList, getBusinessListSlide])

    var options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
    };
    async function success(pos) {
        var crd = pos.coords;
        await localStorage.setItem("latitude", crd.latitude);
        await localStorage.setItem("longitude", crd.longitude);
        let data = {
            latitude: parseFloat(crd.latitude),
            longitude: parseFloat(crd.longitude),
            key: "homepage",
        }
        fetch('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + crd.latitude + ',' + crd.longitude + '&key=AIzaSyB_KZHG7uYGsaMGdQLDRrV3Qw6xRvLqg_Q', {
            method: 'GET'
        }).then((response) => response.json())
            .then(async (data) => {
                if (data.results[1]) {
                    const address = data.results[0].formatted_address,
                        addressArray = data.results[0].address_components,
                        city = getCity(addressArray),
                        state = getState(addressArray);
                    await localStorage.setItem("city", city);
                    await localStorage.setItem("state", state);
                }
            })
            .catch((error) => {
                console.error('Error:', error)
            })

        await getBusinessList(data)
        await getBusinessListSlide(data)

        await getEcommerceBusinessList(data)
        data['type'] = 'coupon'
        await getCouponBusinessList(data)
        await getCouponrankingRatingList(data)
    }

    async function errors(err) {
        console.warn(`ERROR(${err.code}): ${err.message}`);
        toast.warning('Allow location to get best experience.To give permission click on location and refresh the page.', {
            autoClose: 5000, hideProgressBar: true, closeOnClick: true,
            pauseOnHover: true,
        })

    }
    useEffect(() => {
        if (navigator.geolocation) {
            navigator.permissions
                .query({ name: "geolocation" })
                .then(function (result) {
                    if (result.state === "granted") {
                        //If granted then you can directly call your function here
                        navigator.geolocation.getCurrentPosition(success, errors, options);
                    } else if (result.state === "prompt") {
                        //If prompt then the user will be asked to give permission
                        navigator.geolocation.getCurrentPosition(success, errors, options);
                    } else if (result.state === "denied") {
                        //If denied then you have to show instructions to enable location
                        toast.warning('Allow location to get best experience.To give permission click on location and refresh the page.', {
                            autoClose: 5000, hideProgressBar: true, closeOnClick: true,
                            pauseOnHover: true,
                        })
                    }
                });
        } else {
            console.log("Geolocation is not supported by this browser.");
        }
    }, []);
    // useEffect(() => {
    //     async function getEcommerceBusiness() {
    //         let lat = await localStorage.getItem("latitude")
    //         let long = await localStorage.getItem("longitude")
    //         let data = {
    //             latitude: parseFloat(lat),
    //             longitude: parseFloat(long),
    //         }
    //         await getEcommerceBusinessList(data)
    //     }
    //     getEcommerceBusiness()
    // }, [getEcommerceBusinessList])

    // Slick Props
    var settings = {
        infinite: true,
        speed: 500,
        slidesToShow: props.businessList.length < 4 ? (props.businessList.length - 1) === 0 ? 1 : (props.businessList.length - 1) === 1 ? 2 : props.businessList.length - 1 : 3,
        // slidesToShow:4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    adaptiveHeight: true
                }
            }
        ]
    };

    var NearbyBusinessSettings = {
        infinite: true,
        speed: 500,
        slidesToShow: props.businessList.length < 4 ? (props.businessList.length - 1) === 0 ? 1 : (props.businessList.length - 1) === 1 ? 2 : props.businessList.length - 1 : 3,
        // slidesToShow:4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    adaptiveHeight: true
                }
            }
        ]
    };

    var settingCoupon = { ...settings }
    let filterdArray = props.couponBusinessList.filter((dd) => dd.isCouponSelect || dd.isDealSelect)
    settingCoupon.slidesToShow = filterdArray.length < 4 ? (filterdArray.length - 1) === 0 ? 1 : (filterdArray.length - 1) === 1 ? 2 : filterdArray.length - 1 : 3;

    var settingEcommerece = { ...settings }
    let eComArray = eComBusinessList.filter((dd) => dd.isCouponSelect || dd.isDealSelect)
    settingEcommerece.slidesToShow = eComArray.length < 4 ? (eComArray.length - 1) === 0 ? 1 : (eComArray.length - 1) === 1 ? 2 : eComArray.length - 1 : 3;

    var settingsBlog = { ...settings }
    settingsBlog.slidesToShow = props.blogList.length < 4 ? (props.blogList.length - 1) === 0 ? 1 : (props.blogList.length - 1) === 1 ? 2 : props.blogList.length - 1 : 3;

    var settingReports = { ...settings }
    settingReports.slidesToShow = 3

    var settingsChooseUs = { ...settings }
    settingsChooseUs.slidesToShow = 3

    var days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    var d = new Date();
    var dayName = days[d.getDay()];
    var dayString = `is_${dayName}_Open`

    // make function for showing address with city and state and country
    const getRequiredAddress = (str, from) => {
        var arr = str.split(',');
        arr.splice(0, from);
        return arr.join(',');
    }
    //handle business details on view details
    const handleBusinessDetails = (event, business) => {
        let addr = business.address ? getRequiredAddress(business.address, 1).replaceAll(/ /g, "-").toLowerCase() : business.city ? business.city : business.name;
        let subcategoryname = business.subCategory.length > 0 ? business.subCategory[0].subCategoryName.replaceAll(/ /g, "-").toLowerCase() : "subcategory"
        navigate(`/business-details/${business.name}/${subcategoryname}/${addr}`, { state: { businessId: business._id } })
    }
    //handle business details for ecommerce and deal on view details
    const handleEcommBusinessDetails = (event, business) => {
        // let addr = business.address ? getRequiredAddress(business.address, 1) : business.city ? business.city : business.name;
        let subcategoryname = business.subCategory.length > 0 ? business.subCategory[0].subCategoryName.replaceAll(/ /g, "-").toLowerCase() : "subcategory"
        navigate(`/business-details/${business.name.replaceAll(/ /g, "-").toLowerCase()}/${subcategoryname}/e-commerce-business`, { state: { businessId: business._id } })
    }

    //send on map page for routes
    const sendOnDirectionPage = (business) => {
        let addr = business.address ? getRequiredAddress(business.address, 3) : business.city
        let businessInfo = {
            destLat: business.latitude,
            desLong: business.longitude,
            businessName: business.name,
            address: addr
        }
        let data = {
            businessId: business._id,
            clickType: 'direction'
        }
        props.clickPerCount(data)
        navigate(`/direction-to-business/${addr}`, { state: businessInfo })
    }


    const sendOnWebsite = (business, type) => {
        let data = {
            businessId: business._id,
            clickType: type
        }
        props.clickPerCount(data)
        window.open(`https://${business.website}`, '_blank', 'noopener,noreferrer')
    }

    const handleCallClick = (business) => {
        setCallDetails({ country_code: business.country_code, mobile_no: business.mobile_no })
        let data = {
            businessId: business._id,
            clickType: 'call'
        }
        props.clickPerCount(data)
    }

    const handleEmailClick = (business) => {
        let token = localStorage.getItem('token')
        if (!token) {
            navigate('/login')
        } else {
            let businessInfo = {
                email: business.email,
                businessName: business.name,
                country_code: business.country_code,
                phone_no: business.phone_no,
                businessId: business._id
            }
            navigate(`/send-email-to-business/:${business.name}`, { state: businessInfo })
        }
    }
    const handleTextClick = (business) => {
        let token = localStorage.getItem('token')
        if (!token) {
            navigate('/login')
        } else {
            let businessInfo = {
                email: business.email,
                businessName: business.name,
                country_code: business.country_code,
                phone_no: business.phone_no,
                businessId: business._id
            }
            navigate(`/send-text-message-to-business/:${business.name}`, { state: businessInfo })
        }
    }
    const handleBookAnAppointmentClick = (business) => {
        let businessInfo = {
            email: business.email,
            businessName: business.name,
            country_code: business.country_code,
            phone_no: business.phone_no,
            businessId: business._id,
            bookAppointmentService: business.bookAppointmentService
        }
        navigate(`/book-an-appointment/${business.name}`, { state: businessInfo })
    }
    const handleRequestAQuoteClick = (business) => {
        console.log(business);
        let businessInfo = {
            email: business.email,
            businessName: business.name,
            country_code: business.country_code,
            phone_no: business.mobile_no,
            businessId: business._id,
            requestQuoteService: business.requestQuoteService
        }
        navigate(`/request-a-quote/${business.name}`, { state: businessInfo })
    }

    const handleDealClick = (business) => {
        let token = localStorage.getItem('token')
        if (!token) {
            navigate('/login')
        } else {
            let customerInfo = JSON.parse(localStorage.getItem('userInfo'));
            let mobileNo = customerInfo.country_code + customerInfo.mobile_no
            let customerName = customerInfo.name;
            let customerEmail = customerInfo.email;
            let data = {
                mobileNo: mobileNo,
                customerName: customerName,
                customerEmail: customerEmail,
                businessName: business.name,
                businessEmail: business.email,
                businessId: business._id,
                clickType: 'deal'
            }
            props.clickPerCount(data)
            window.open(`https://${business.website}`, '_blank', 'noopener,noreferrer')
        }
    }
    const checkRatingModal = (business) => {
        if (token !== null) {
            setSelectedBusiness({ businessName: business.name, businessId: business._id })
        } else {
            navigate('/login')
        }
    }
    const submitReview = () => {
        let creatorId = userInfo._id
        let data = {
            rating: rating,
            ratingFor: 'businessWebite',
            comment: ratingComment,
            creator_id: creatorId,
            receiver_id: selectedReviewBusiness.businessId
        }
        props.makeRating(data)
    }
    const handleCouponClick = (business, type) => {
        if (token !== null) {
            setCallDetails({ country_code: business.country_code, mobile_no: business.mobile_no })
            setCouponDirection({
                destLat: business.latitude,
                desLong: business.longitude,
                businessName: business.name,
                address: business.address
            })
            if (type === 'coupon') {
                let couponData = {
                    businessId: business._id,
                    type: "coupon"
                }
                props.createAndGetCoupon(couponData)
            } else if (type === 'reward') {
                let rewardData = {
                    businessId: business._id,
                    type: "reward"
                }
                props.createAndGetCoupon(rewardData)
            }

        } else {
            navigate('/login')
        }
    }

    const sendOnDirectionPageForCouponModal = () => {
        let businessInfo = {
            destLat: couponDirection.destLat,
            desLong: couponDirection.desLong,
            businessName: couponDirection.businessName,
            address: couponDirection.address
        }
        navigate(`/direction-to-business/${couponDirection.address}`, { state: businessInfo })
    }

    const handleSubCategoryClick = (e, info) => {
        e.preventDefault();
        let city = localStorage.getItem('city');
        let state = localStorage.getItem('state');
        let infoName = info.name.replaceAll(/ /g, "-").toLowerCase();
        let categoryBusinessUrl = `/get-business-by-category/top-10-best-${infoName}-in-${city && state ? `${city}-${state}` : 'near me'}`
        // Top 10 best Indian restaurants
        navigate(categoryBusinessUrl, { state: info })
    }

    const handleBlogDetails = (blog) => {
        let blogTitle = blog.blog_title.replaceAll(/ /g, "-").toLowerCase();
        let blogDetailsUrl = `blog-details/${blogTitle}`
        navigate(blogDetailsUrl, { state: { blogId: blog._id } })
    }
    // console.log(props.couponBusinessList,'kokoookko')
    return (
        <>
            {/* title head */}
            <Helmet>
                <title>CouponRanking | Home</title>
            </Helmet>

            {/* Main Banner */}
            <ToastContainer />

            {/* Old Banner Section */}
            {/* <div className='main-banner-section section-bg-image section-bg-overlay py-5' style={{ backgroundImage: `url(${Banner4})` }}>
                <div className='container my-3 my-xl-5'>
                    <div className='row align-items-center position-relative'>
                        <div className='col-md-7 mb-4 mb-md-0'>
                            <div className='coupon-sec pe-xl-5 h-100'>
                                <div className='display-5 fw-bold text-white text-shadow mb-3'>Couponranking is all about connecting customers to Businesses</div>
                                <div className="section-sublabel text-white text-shadow">With just a few clicks, you can connect with your favorite businesses and discover new ones through our platform.</div>
                                <Link to={{ pathname: '/login' }} className='btn btn-primary fs-18 py-3 px-5 mt-3'>Join Now <i className="bi bi-caret-right"></i></Link>
                            </div>
                        </div>
                        <div className='col-md-5'>
                            <div className='add-sec'>
                                <img src={require('../assets/advertise.png')} className="img-fluid" alt="Advertise" />
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* Start: Common Slider Code */}
            <div className='common-banner-wrapper'>
            {banner && banner.length > 0 ? 
                <Swiper
                    slidesPerView={1}
                    loop={true}
                    effect={'fade'}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Autoplay, EffectFade, Pagination]}
                    className="mySwiper custom-dots"
                >
                    {banner.map((slide) => (
                        <SwiperSlide key={slide._id}>
                            <div className="slide d-flex align-items-center bg-contain" style={{ backgroundImage: `url(${slide.image})`, }} >
                                <div className="container">
                                    <div className="content-wrapper text-light rounded-3 p-3 p-md-4">
                                        <div className="company-name fs-2 fw-bold lh-sm mb-1">{slide.title}</div>
                                        <div className="company-subtitle fs-6 fw-normal mb-3">{slide.subCategory?.length > 0 ? slide.subCategory[0].subCategoryName : ''}</div>
                                        <div className="company-subtitle fs-6 fw-normal mb-3">{slide.ad_message}</div>
                                        <div className='action d-flex flex-wrap gap-2 mb-3'>
                                            {slide.isPhoneSelect ?
                                                <Link type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleCallClick(slide)}><i className="bi bi-telephone-fill text-primary me-2"></i>Call
                                                </Link>
                                                : ''}
                                            {slide.isEmailSelect ? <button type='button' className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleEmailClick(slide)}><i className="bi bi-envelope-fill text-primary me-2"></i>Email</button> : ''}
                                            {slide.isWebsiteSelect ? <Link type="button" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnWebsite(slide, 'website')} ><i className="bi bi-globe-americas text-primary me-2"></i>Website</Link> : ''}
                                            {slide.isDirectionSelect ? <button type="button" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnDirectionPage(slide)}><i className="bi bi-reply-fill text-primary me-2"></i>Directions</button> : ''}
                                            {slide.isOrderNowSelect ? <Link to={'#'} className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnWebsite(slide, 'order')}><img src={Cutlery} alt='cutlery' className="text-primary me-2" />Order</Link> : ''}
                                            {slide.isTextSelect ? <button className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleTextClick(slide)}> <i className="bi bi-pencil-fill text-primary me-2"></i>Text</button> : ''}
                                            {/* <Link type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-telephone-fill text-primary me-1"></i>Call
                                                        </Link> */}

                                            {/* <Link type='button' className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-envelope-fill text-primary me-1"></i>Email
                                                        </Link>
                                                        <Link type="button" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-globe-americas text-primary me-1"></i>Website
                                                        </Link>
                                                        <Link type="button" className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-reply-fill text-primary me-1"></i>Directions
                                                        </Link>
                                                        <Link to={'#'} className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <img src={Cutlery} alt='cutlery' className="text-primary me-1" />Order
                                                        </Link>
                                                        <Link className='bg-white fs-12 text-black text-decoration-none rounded-pill d-flex align-items-center py-1 px-2'>
                                                            <i className="bi bi-pencil-fill text-primary me-1"></i>Text
                                                        </Link> */}
                                        </div>
                                        {slide.isCouponSelect ? <div className="discount-badge">{slide.couponText.split(',')[0]}</div> : ''}
                                        <div className="button-group row g-2">
                                            {(slide.isCallForQuoteSelect || slide.isBookForAppointmentSelect) ?
                                                <>
                                                    {slide.isCallForQuoteSelect ? <div className="col-6">
                                                        <button className="btn btn-warning w-100 px-3" onClick={() => handleRequestAQuoteClick(slide)}>Request a Quote</button>
                                                    </div> : ''}
                                                    {slide.isBookForAppointmentSelect ? <div className="col-6">
                                                        <button className="btn btn-success w-100 px-3" onClick={() => handleBookAnAppointmentClick(slide)}>Book Appointment</button>
                                                    </div> : ''}
                                                </> : ''}
                                            {/* <div className="col-12">
                                                <button className="btn btn-danger w-100 px-3">Coupon Message</button>
                                            </div> */}
                                        </div>
                                        <div className="sponserd text-white fw-medium mt-2">Sponsored Ad</div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        
                    ))}</Swiper> : 
                    <div className='fs-3 text-danger text-center'>
                        *Promote your business here!* 
                        Take advantage of this prime ad space and grow your brand. Place your sponsored ad today!
                    </div>}
                
            </div>
            {/* End: Common Slider Code */}

            {/* Coupon Ranking For Businesses */}
            <div className='forBusinesses bg-light py-5'>
                <div className='container-fluid my-xl-4'>
                    <div className='section-header text-center mb-4 mb-lg-5' style={{ maxWidth: '1000px' }}>
                        <div className='section-label fs-1 mb-2'>Couponranking for Businesses</div>
                        <div className='section-sublabel text-dark text-opacity-75'>Couponranking is the best platform for connecting businesses with customers. At couponranking we believe that every business deserve the opportunity to connect with customers and grow their business. That's why we offer a wide range of tools and resources to help businesses of all siges achieve their goals.</div>
                    </div>
                    <div className="row g-3">
                        <div className="col-lg-3 col-xl-2">
                            {/* Start: Common Sidebar Ads Code*/}
                            <div className='common-banner-wrapper common-sidebar-banner'>
                            {sliderData1 && sliderData1.length > 0 ? 
                                <Swiper
                                    slidesPerView={1}
                                    loop={true}
                                    effect={'fade'}
                                    autoplay={{
                                        delay: 5000,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Autoplay, EffectFade, Pagination]}
                                    className="mySwiper custom-dots"
                                >
                                    {sliderData1.map((slide) => (
                                        <SwiperSlide key={slide._id}>
                                            <div className="slide d-flex align-items-end bg-contain" style={{ backgroundImage: `url(${slide.image})`, }} >
                                                {/* <div className="discount-badge poin">20% off</div> */}
                                                {slide.isCouponSelect ? <div className="discount-badge">{slide.couponText.split(',')[0]}</div> : ''}
                                                <div className="content-wrapper text-light rounded-3 mb-5 p-2">
                                                    <div className="company-name fs-4 fw-bold lh-sm">{slide.title}</div>
                                                    <div className="company-subtitle fs-6 fw-normal mb-3">{slide.subCategory?.length > 0 ? slide.subCategory[0].subCategoryName : ''}</div>
                                                    <div className="company-subtitle fs-6 fw-normal mb-3">{slide.ad_message}</div>
                                                    <div className='action d-flex flex-wrap gap-2 mb-3'>
                                                        {slide.isPhoneSelect ?
                                                            <button type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='bg-white fs-12 text-black text-decoration-none border rounded-pill d-flex align-items-center py-1 px-2' onClick={() => handleCallClick(slide)}><i className="bi bi-telephone-fill text-primary me-1"></i>Call
                                                            </button>
                                                            : ''}
                                                        {slide.isEmailSelect ? <button type='button' className='bg-white fs-12 text-black text-decoration-none border rounded-pill d-flex align-items-center py-1 px-2' onClick={() => handleEmailClick(slide)}><i className="bi bi-envelope-fill text-primary me-1"></i>Email</button> : ''}
                                                        {slide.isWebsiteSelect ? <button type="button" className='bg-white fs-12 text-black text-decoration-none border rounded-pill d-flex align-items-center py-1 px-2' onClick={() => sendOnWebsite(slide, 'website')} ><i className="bi bi-globe-americas text-primary me-1"></i>Website</button> : ''}
                                                        {slide.isDirectionSelect ? <button type="button" className='bg-white fs-12 text-black text-decoration-none border rounded-pill d-flex align-items-center py-1 px-2' onClick={() => sendOnDirectionPage(slide)}><i className="bi bi-reply-fill text-primary me-1"></i>Directions</button> : ''}
                                                        {slide.isOrderNowSelect ? <button type="button" className='bg-white fs-12 text-black text-decoration-none border rounded-pill d-flex align-items-center py-1 px-2' onClick={() => sendOnWebsite(slide, 'order')}><img src={Cutlery} alt='cutlery' className="text-primary me-1" />Order</button> : ''}
                                                        {slide.isTextSelect ? <button type="button" className='bg-white fs-12 text-black text-decoration-none border rounded-pill d-flex align-items-center py-1 px-2' onClick={() => handleTextClick(slide)}> <i className="bi bi-pencil-fill text-primary me-1"></i>Text</button> : ''}
                                                    </div>
                                                    <div className="button-group d-flex flex-column gap-1">
                                                        {/* <button className='btn w-100 text-white lh-sm px-3' style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)' }} >Click here for a coupon</button> */}
                                                        {slide.isCouponSelect ? slide.couponText.includes('rewards') ?
                                                        <div type="button" className='text-white shadow p-3 w-100 mt-2' data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#rewardModal" : ''}
                                                            style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }}
                                                            onClick={() => handleCouponClick(slide, 'reward')}
                                                        >
                                                            {slide.couponText ? slide.couponText.split(',')[1] : 'Click here for the deal min order $30'}
                                                        </div>
                                                        :
                                                        <div type="button" className='text-white shadow p-3 w-100 mt-2'
                                                            data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#couponModal" : ''} style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }}
                                                            onClick={() => handleCouponClick(slide, 'coupon')}
                                                        >
                                                            {slide.couponText ? slide.couponText.split(',')[1] : 'Click here for the deal min order $30'}
                                                        </div>
                                                        : ''}
                                                        {
                                                            slide.isCallForQuoteSelect ?
                                                                <button className="btn btn-warning w-100 px-3" onClick={() => handleRequestAQuoteClick(slide)}>Request a Quote</button>
                                                                : ''
                                                        }
                                                        {
                                                            (slide.isBookForAppointmentSelect) ?
                                                                <button className="btn btn-success w-100 px-3" onClick={() => handleBookAnAppointmentClick(slide)}>Book Appointment</button>
                                                                : ''
                                                        }
                                                        {/* <button className="btn btn-danger w-100 px-3">Coupon Message</button> */}
                                                    </div>
                                                    <div class="sponserd text-white fw-medium mt-2">Sponsored Ad</div>
                                                </div>
                                            </div>
                                            </SwiperSlide>
                        
                                        ))}</Swiper> : 
                                        <div className='fs-3 text-danger text-center'>
                                            *Promote your business here!* 
                                            Take advantage of this prime ad space and grow your brand. Place your sponsored ad today!
                                        </div>}
                            </div>
                            {/* End: Common Sidebar Ads Code*/}
                        </div>
                        <div className="col-lg-6 col-xl-8">
                            <div className="row g-3">
                                {BusinessImage.map((item, index) => (
                                    <div className="col-sm-6 col-lg-6 col-xl-4">
                                        <div className='business-details overflow-hidden' key={index}>
                                            <div className='business-img position-relative'>
                                                <img src={item.image} className='w-100 img-fluid rounded object-fit-cover' alt='' style={{ height: '250px' }} />
                                            </div>
                                            <div className="business-info text-center mt-2">
                                                <Link to={'#'} className='fs-18 fw-medium btn-link link-dark text-decoration-none text-capitalize'>{item.text}</Link>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div className="btn-wrapper text-center mt-4 pt-lg-3">
                                <Link to={'/couponranking-for-business'} className="btn btn-primary d-inline-flex align-items-center justify-content-center">Learn More <i className="bi bi-arrow-right ms-1"></i></Link>
                            </div>
                        </div>
                        <div className="col-lg-3 col-xl-2">
                            {/* Start: Common Sidebar Ads Code*/}
                            <div className='common-banner-wrapper common-sidebar-banner'>
                                {
                                    sliderData2 && sliderData2.length > 0 ? 
                                    <Swiper
                                    slidesPerView={1}
                                    loop={true}
                                    effect={'fade'}
                                    autoplay={{
                                        delay: 5000,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Autoplay, EffectFade, Pagination]}
                                    className="mySwiper custom-dots"
                                >
                                    {sliderData2.map((slide) => (
                                        <SwiperSlide key={slide._id}>
                                            <div className="slide d-flex align-items-end bg-contain" style={{ backgroundImage: `url(${slide.image})`, }} >
                                                {/* <div className="discount-badge poin">20% off</div> */}
                                                {slide.isCouponSelect ? <div className="discount-badge">{slide.couponText.split(',')[0]}</div> : ''}
                                                <div className="content-wrapper text-light rounded-3 mb-5 p-2">
                                                    <div className="company-name fs-4 fw-bold lh-sm mb-1">{slide.title}</div>
                                                    <div className="company-subtitle fs-6 fw-normal mb-3">{slide.subCategory?.length > 0 ? slide.subCategory[0].subCategoryName : ''}</div>
                                                    <div className="company-subtitle fs-6 fw-normal mb-3">{slide.ad_message}</div>
                                                    <div className='action d-flex flex-wrap gap-2 mb-3'>
                                                        {slide.isPhoneSelect ?
                                                            <button type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='bg-white fs-12 text-black text-decoration-none border rounded-pill d-flex align-items-center py-1 px-2' onClick={() => handleCallClick(slide)}><i className="bi bi-telephone-fill text-primary me-1"></i>Call
                                                            </button>
                                                            : ''}
                                                        {slide.isEmailSelect ? <button type='button' className='bg-white fs-12 text-black text-decoration-none border rounded-pill d-flex align-items-center py-1 px-2' onClick={() => handleEmailClick(slide)}><i className="bi bi-envelope-fill text-primary me-1"></i>Email</button> : ''}
                                                        {slide.isWebsiteSelect ? <button type="button" className='bg-white fs-12 text-black text-decoration-none border rounded-pill d-flex align-items-center py-1 px-2' onClick={() => sendOnWebsite(slide, 'website')} ><i className="bi bi-globe-americas text-primary me-1"></i>Website</button> : ''}
                                                        {slide.isDirectionSelect ? <button type="button" className='bg-white fs-12 text-black text-decoration-none border rounded-pill d-flex align-items-center py-1 px-2' onClick={() => sendOnDirectionPage(slide)}><i className="bi bi-reply-fill text-primary me-1"></i>Directions</button> : ''}
                                                        {slide.isOrderNowSelect ? <button type="button" className='bg-white fs-12 text-black text-decoration-none border rounded-pill d-flex align-items-center py-1 px-2' onClick={() => sendOnWebsite(slide, 'order')}><img src={Cutlery} alt='cutlery' className="text-primary me-1" />Order</button> : ''}
                                                        {slide.isTextSelect ? <button type="button" className='bg-white fs-12 text-black text-decoration-none border rounded-pill d-flex align-items-center py-1 px-2' onClick={() => handleTextClick(slide)}> <i className="bi bi-pencil-fill text-primary me-1"></i>Text</button> : ''}

                                                    </div>
                                                    <div className="button-group d-flex flex-column gap-1">
                                                        {/* <button className='btn w-100 text-white lh-sm px-3' style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)' }} >Click here for a coupon</button> */}
                                                        {slide.isCouponSelect ? slide.couponText.includes('rewards') ?
                                                        <div type="button" className='text-white shadow p-3 w-100 mt-2' data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#rewardModal" : ''}
                                                            style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }}
                                                            onClick={() => handleCouponClick(slide, 'reward')}
                                                        >
                                                            {slide.couponText ? slide.couponText.split(',')[1] : 'Click here for the deal min order $30'}
                                                        </div>
                                                        :
                                                        <div type="button" className='text-white shadow p-3 w-100 mt-2'
                                                            data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#couponModal" : ''} style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }}
                                                            onClick={() => handleCouponClick(slide, 'coupon')}
                                                        >
                                                            {slide.couponText ? slide.couponText.split(',')[1] : 'Click here for the deal min order $30'}
                                                        </div>
                                                        : ''}
                                                        {
                                                            slide.isCallForQuoteSelect ?
                                                                <button className="btn btn-warning w-100 px-3" onClick={() => handleRequestAQuoteClick(slide)}>Request a Quote</button>
                                                                : ''
                                                        }
                                                        {
                                                            slide.isBookForAppointmentSelect ?
                                                                <button className="btn btn-success w-100 px-3" onClick={() => handleBookAnAppointmentClick(slide)}>Book Appointment</button>
                                                                : ''
                                                        }
                                                        {/* <button className="btn btn-danger w-100 px-3">Coupon Message</button> */}
                                                    </div>
                                                    <div class="sponserd text-white fw-medium mt-2">Sponsored Ad</div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))}</Swiper> 
                                : <div className='fs-3 text-danger text-center'>
                                    *Promote your business here!* 
                                    Take advantage of this prime ad space and grow your brand. Place your sponsored ad today!
                                </div>}
                                
                            </div>
                            {/* End: Common Sidebar Ads Code*/}
                        </div>
                    </div>
                </div>
            </div>

            {/* Coupon Ranking For Customers */}
            <div className='forBusinesses bg-primary bg-opacity-10 py-5'>
                <div className='container-fluid my-xl-4'>
                    <div className='section-header text-center mb-4 mb-lg-5' style={{ maxWidth: '1000px' }}>
                        <div className='section-label fs-1 mb-2'>Couponranking for Customers</div>
                        <div className='section-sublabel text-dark text-opacity-75'>Couponranking is the revolutionary business connector that bridge tha gap between customers and businesses. Our platform is user-friendly and intuitive, designed to simplify the process of connecting with businesses in your area. with just a few clicks, you can browse a wide range of services and products, and connect with the businesses that best suit your needs.</div>
                    </div>
                    <div className="row g-3">
                        <div className="col-lg-3 col-xl-2">
                            {/* Start: Common Sidebar Ads Code*/}
                            <div className='common-banner-wrapper common-sidebar-banner'>
                            {
                                sliderData3 && sliderData3.length > 0 ? 
                                <Swiper
                                slidesPerView={1}
                                loop={true}
                                effect={'fade'}
                                autoplay={{
                                    delay: 5000,
                                    disableOnInteraction: false,
                                }}
                                pagination={{
                                    clickable: true,
                                }}
                                modules={[Autoplay, EffectFade, Pagination]}
                                className="mySwiper custom-dots"
                            >
                                {sliderData3.map((slide) => (
                                    <SwiperSlide key={slide._id}>
                                        <div className="slide d-flex align-items-end bg-contain" style={{ backgroundImage: `url(${slide.image})`, }} >
                                            {/* <div className="discount-badge poin">20% off</div> */}
                                            {slide.isCouponSelect ? <div className="discount-badge">{slide.couponText.split(',')[0]}</div> : ''}
                                            <div className="content-wrapper text-light rounded-3 mb-5 p-2">
                                                <div className="company-name fs-4 fw-bold lh-sm mb-1">{slide.title}</div>
                                                <div className="company-subtitle fs-6 fw-normal mb-3">{slide.subCategory?.length > 0 ? slide.subCategory[0].subCategoryName : ''}</div>
                                                <div className="company-subtitle fs-6 fw-normal mb-3">{slide.ad_message}</div>
                                                <div className="button-group d-flex flex-column gap-1">
                                                    {
                                                        slide.isCallForQuoteSelect ?
                                                            <button className="btn btn-warning w-100 px-3" onClick={() => handleRequestAQuoteClick(slide)}>Request a Quote</button>
                                                            : ''
                                                    }
                                                    {
                                                        slide.isBookForAppointmentSelect ?
                                                            <button className="btn btn-success w-100 px-3" onClick={() => handleBookAnAppointmentClick(slide)}>Book Appointment</button>
                                                            : ''
                                                    }
                                                    {/* <button className="btn btn-danger w-100 px-3">Coupon Message</button> */}
                                                </div>
                                                <div class="sponserd text-white fw-medium mt-2">Sponsored Ad</div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    ))}</Swiper> 
                                    : <div className='fs-3 text-danger text-center'>
                                        *Promote your business here!* 
                                        Take advantage of this prime ad space and grow your brand. Place your sponsored ad today!
                                    </div>}
                            </div>
                            {/* End: Common Sidebar Ads Code*/}
                        </div>
                        <div className="col-lg-6 col-xl-8">
                            <div className="row g-3">
                                {CustomerImage.map((item, index) => (
                                    <div className="col-sm-6 col-lg-6 col-xl-4">
                                        <div className='business-details overflow-hidden' key={index}>
                                            <div className='business-img position-relative'>
                                                <img src={item.image} className='w-100 img-fluid rounded object-fit-cover' alt='' style={{ height: '250px' }} />
                                            </div>
                                            <div className="business-info text-center mt-2">
                                                <Link to={'#'} className='fs-18 fw-medium btn-link link-dark text-decoration-none text-capitalize'>{item.text}</Link>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div className="btn-wrapper text-center mt-4 pt-lg-3">
                                <Link to={'/couponranking-for-customers'} className="btn btn-primary d-inline-flex align-items-center justify-content-center">Learn More <i className="bi bi-arrow-right ms-1"></i></Link>
                            </div>
                        </div>
                        <div className="col-lg-3 col-xl-2">
                            {/* Start: Common Sidebar Ads Code*/}
                            <div className='common-banner-wrapper common-sidebar-banner'>
                            {
                                sliderData4 && sliderData4.length > 0 ? 

                                <Swiper
                                    slidesPerView={1}
                                    loop={true}
                                    effect={'fade'}
                                    autoplay={{
                                        delay: 1000,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Autoplay, EffectFade, Pagination]}
                                    className="mySwiper custom-dots"
                                >
                                    {sliderData4.map((slide) => (
                                        <SwiperSlide key={slide._id}>
                                            <div className="slide d-flex align-items-end bg-contain" style={{ backgroundImage: `url(${slide.image})`, }} >
                                                {/* <div className="discount-badge poin">20% off</div> */}
                                                {slide.isCouponSelect ? <div className="discount-badge">{slide.couponText.split(',')[0]}</div> : ''}
                                                <div className="content-wrapper text-light rounded-3 mb-5 p-2">
                                                    <div className="company-name fs-4 fw-bold lh-sm mb-1">{slide.title}</div>
                                                    <div className="company-subtitle fs-6 fw-normal mb-3">{slide.subCategory?.length > 0 ? slide.subCategory[0].subCategoryName : ''}</div>
                                                    <div className="company-subtitle fs-6 fw-normal mb-3">{slide.ad_message}</div>
                                                    <div className="button-group d-flex flex-column gap-1">
                                                        {slide.isCallForQuoteSelect && (
                                                            <button className="btn btn-warning w-100 px-3" onClick={() => handleRequestAQuoteClick(slide)}>Request a Quote</button>
                                                        )}
                                                        {slide.isBookForAppointmentSelect && (
                                                            <button className="btn btn-success w-100 px-3" onClick={() => handleBookAnAppointmentClick(slide)}>Book Appointment</button>
                                                        )}
                                                        {/* <button className="btn btn-danger w-100 px-3">Coupon Message</button> */}
                                                    </div>
                                                    <div class="sponserd text-white fw-medium mt-2">Sponsored Ad</div>
                                                </div>
                                            </div>
                                            </SwiperSlide>
                                        ))}</Swiper> 
                                    : <div className='fs-3 text-danger text-center'>
                                        *Promote your business here!* 
                                        Take advantage of this prime ad space and grow your brand. Place your sponsored ad today!
                                    </div>}
                            </div>
                            {/* End: Common Sidebar Ads Code*/}
                        </div>
                    </div>
                </div>
            </div>

            {/* Near By Business Section */}
            <div className='nearby-business-sec py-5' style={{ backgroundImage: `url(${Banner9})s` }}>
                <div className='container-fluid my-xl-4'>
                    <div className='section-header text-center mb-4 mb-lg-5'>
                        <div className='section-label fs-1'>Nearby Business</div>
                        <div className='section-sublabel text-dark text-opacity-75'>Find what you’re looking for closer than you think. With Nearby Business, You’ll never be to far away.</div>
                    </div>
                    <div className="row gx-2 gy-3">
                        <div className="col-lg-3 col-xl-2">
                            {/* Start: Common Sidebar Ads Code*/}
                            <div className="ads-wrapper d-flex flex-column gap-3">
                                <div className='common-banner-wrapper common-sidebar-banner'>
                                {
                                    sliderData5 && sliderData5.length > 0 ? 
                                    <Swiper
                                        slidesPerView={1}
                                        loop={true}
                                        effect={'fade'}
                                        autoplay={{
                                            delay: 1000,
                                            disableOnInteraction: false,
                                        }}
                                        pagination={{
                                            clickable: true,
                                        }}
                                        modules={[Autoplay, EffectFade, Pagination]}
                                        className="mySwiper custom-dots"
                                    >
                                        {sliderData5.map((slide) => (
                                            <SwiperSlide key={slide._id}>
                                                <div className="slide d-flex align-items-end bg-contain" style={{ backgroundImage: `url(${slide.image})`, }} >
                                                    {/* <div className="discount-badge poin">20% off</div> */}
                                                    {slide.isCouponSelect ? <div className="discount-badge">{slide.couponText.split(',')[0]}</div> : ''}
                                                    <div className="content-wrapper text-light rounded-3 mb-5 p-2">
                                                        <div className="company-name fs-4 fw-bold lh-sm mb-1">{slide.title}</div>
                                                        <div className="company-subtitle fs-6 fw-normal mb-3">{slide.subCategory?.length > 0 ? slide.subCategory[0].subCategoryName : ''}</div>
                                                        <div className="company-subtitle fs-6 fw-normal mb-3">{slide.ad_message}</div>
                                                        <div className='action d-flex flex-wrap gap-2 mb-3'>
                                                            {slide.isPhoneSelect ?
                                                                <button type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='bg-white fs-12 text-black text-decoration-none border rounded-pill d-flex align-items-center py-1 px-2' onClick={() => handleCallClick(slide)}><i className="bi bi-telephone-fill text-primary me-1"></i>Call
                                                                </button>
                                                                : ''}
                                                            {slide.isEmailSelect ? <button type='button' className='bg-white fs-12 text-black text-decoration-none border rounded-pill d-flex align-items-center py-1 px-2' onClick={() => handleEmailClick(slide)}><i className="bi bi-envelope-fill text-primary me-1"></i>Email</button> : ''}
                                                            {slide.isWebsiteSelect ? <button type="button" className='bg-white fs-12 text-black text-decoration-none border rounded-pill d-flex align-items-center py-1 px-2' onClick={() => sendOnWebsite(slide, 'website')} ><i className="bi bi-globe-americas text-primary me-1"></i>Website</button> : ''}
                                                            {slide.isDirectionSelect ? <button type="button" className='bg-white fs-12 text-black text-decoration-none border rounded-pill d-flex align-items-center py-1 px-2' onClick={() => sendOnDirectionPage(slide)}><i className="bi bi-reply-fill text-primary me-1"></i>Directions</button> : ''}
                                                            {slide.isOrderNowSelect ? <button type="button" className='bg-white fs-12 text-black text-decoration-none border rounded-pill d-flex align-items-center py-1 px-2' onClick={() => sendOnWebsite(slide, 'order')}><img src={Cutlery} alt='cutlery' className="text-primary me-1" />Order</button> : ''}
                                                            {slide.isTextSelect ? <button type="button" className='bg-white fs-12 text-black text-decoration-none border rounded-pill d-flex align-items-center py-1 px-2' onClick={() => handleTextClick(slide)}> <i className="bi bi-pencil-fill text-primary me-1"></i>Text</button> : ''}
                                                        </div>
                                                        <div className="button-group d-flex flex-column gap-1">
                                                            {/* <button className='btn w-100 text-white lh-sm px-3' style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)' }} >Click here for a coupon</button> */}
                                                            {slide.isCouponSelect ? slide.couponText.includes('rewards') ?
                                                                <div type="button" className='text-white shadow p-3 w-100 mt-2' data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#rewardModal" : ''}
                                                                    style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }}
                                                                    onClick={() => handleCouponClick(slide, 'reward')}
                                                                >
                                                                    {slide.couponText ? slide.couponText.split(',')[1] : 'Click here for the deal min order $30'}
                                                                </div>
                                                                :
                                                                <div type="button" className='text-white shadow p-3 w-100 mt-2'
                                                                    data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#couponModal" : ''} style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }}
                                                                    onClick={() => handleCouponClick(slide, 'coupon')}
                                                                >
                                                                    {slide.couponText ? slide.couponText.split(',')[1] : 'Click here for the deal min order $30'}
                                                                </div>
                                                                : ''}
                                                            {
                                                                slide.isCallForQuoteSelect ?
                                                                    <button className="btn btn-warning w-100 px-3" onClick={() => handleRequestAQuoteClick(slide)}>Request a Quote</button>
                                                                    : ''
                                                            }
                                                            {
                                                                slide.isBookForAppointmentSelect ?
                                                                    <button className="btn btn-success w-100 px-3" onClick={() => handleBookAnAppointmentClick(slide)}>Book Appointment</button>
                                                                    : ''
                                                            }
                                                            {/* <button className="btn btn-danger w-100 px-3">Coupon Message</button> */}
                                                        </div>
                                                        <div class="sponserd text-white fw-medium mt-2">Sponsored Ad</div>
                                                    </div>
                                                </div>
                                                </SwiperSlide>
                                            ))}</Swiper> 
                                        : <div className='fs-3 text-danger text-center'>
                                            *Promote your business here!* 
                                            Take advantage of this prime ad space and grow your brand. Place your sponsored ad today!
                                        </div>}
                                </div>
                            </div>
                            {/* End: Common Sidebar Ads Code*/}
                        </div>
                        <div className="col-lg-6 col-xl-8">
                            {
                                props.businessList.length > 0 ?
                                    <Slider {...NearbyBusinessSettings} className='custom-slick px-2 px-sm-0'>
                                        {props.businessList.map((business, index) => (
                                            <div className='business-details border rounded-3 overflow-hidden' key={index}>
                                                {/* {console.log(business,'bussssssssssssssssssssiiiiiiiiiiiiiiiineeeeeeeee')} */}
                                                <div className='business-img position-relative'>
                                                    <Swiper
                                                        slidesPerView={1}
                                                        loop={true}
                                                        autoplay={{
                                                            delay: 2500,
                                                            disableOnInteraction: false,
                                                        }}
                                                        pagination={{
                                                            clickable: true,
                                                        }}
                                                        modules={[Autoplay, Pagination]}
                                                        className="mySwiper custom-dots"
                                                    >
                                                        {
                                                            business.businessPicture && business.businessPicture.length > 0 ?
                                                                business.businessPicture.map((item, idx) => (
                                                                    <SwiperSlide><img src={item.pictureUrl} key={idx} className='w-100 img-fluid' alt='Cleaning' /></SwiperSlide>
                                                                ))
                                                                :
                                                                BusinessImg.map((item, idx) => (
                                                                    <SwiperSlide> <img src={item.image} key={idx} className='w-100 img-fluid' alt='Cleaning' /></SwiperSlide>
                                                                ))
                                                        }
                                                    </Swiper>
                                                    {business.isCouponSelect ? <div className='discount-tag bg-danger rounded-circle d-flex align-items-center justify-content-center text-white small position-absolute p-2 lh-sm'>{business.couponText.split(',')[0]}</div> : ''}
                                                    {/* {business.isDealSelect ? <div className='discount-tag bg-danger rounded-circle d-flex align-items-center justify-content-center text-white small position-absolute p-2 lh-sm'>{business.dealText.split(',')[0]}</div> : ''} */}
                                                </div>
                                                <div className="business-info bg-white text-center p-3">
                                                    <div className='name fs-18 fw-semibold text-capitalize mb-1'>{business.name}</div>
                                                    <div className='review-wrapper d-flex align-items-center justify-content-center gap-2 mt-2'>
                                                        <div className='rating-number'>{business.rating > 0 ? business.rating.toFixed(1) : 5}</div>
                                                        <div className='review-star d-flex justify-content-center gap-1'>
                                                            {business.rating > 0 ?
                                                                <Rating initialValue={business.rating} size={"20px"} readonly={true} allowFraction={true} />
                                                                :
                                                                <Rating initialValue={5} size={"20px"} readonly={true} />
                                                            }
                                                        </div>
                                                        <div className='rating-number'>{business.ratingCount > 0 ? `(${business.ratingCount})` : '(1)'}</div>
                                                    </div>
                                                    <div className='name fw-semibold text-capitalize mb-1'>{business.subCategory.length > 0 ? business.subCategory[0].subCategoryName : ''}</div>
                                                    <div className='service-rate text-dark text-opacity-75 text-capitalize'>{business.service_rate}</div>
                                                    <div className='timing text-dark text-opacity-75'>{business.business_weekdays[dayString] == true ? `Open till ${business.business_weekdays[dayName].close_time_hour}:${business.business_weekdays[dayName].close_time_min} ${business.business_weekdays[dayName].close_time_am_pm}` : 'Closed today'}</div>
                                                    <div className='address text-dark text-opacity-75 text-truncate'>{business.address ? getRequiredAddress(business.address, 1) : business.city}</div>
                                                    <div className='action d-flex flex-wrap justify-content-center gap-2 mt-2 mb-2'>
                                                        {business.isPhoneSelect ? <Link type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleCallClick(business)}><i className="bi bi-telephone-fill text-primary me-2"></i>Call</Link> : ''}
                                                        {business.isEmailSelect ? <button type='button' className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleEmailClick(business)}><i className="bi bi-envelope-fill text-primary me-2"></i>Email</button> : ''}
                                                        {business.isWebsiteSelect ? <Link type="button" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnWebsite(business, 'website')} ><i className="bi bi-globe-americas text-primary me-2"></i>Website</Link> : ''}
                                                        {business.isDirectionSelect ? <button type="button" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnDirectionPage(business)}><i className="bi bi-reply-fill text-primary me-2"></i>Directions</button> : ''}
                                                        {business.isOrderNowSelect ? <Link to={'#'} className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnWebsite(business, 'order')}><img src={Cutlery} alt='cutlery' className="text-primary me-2" />Order</Link> : ''}
                                                        {business.isTextSelect ? <button className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleTextClick(business)}> <i className="bi bi-pencil-fill text-primary me-2"></i>Text</button> : ''}

                                                    </div>
                                                    {business.isCouponSelect ? business.couponText.includes('rewards') ?
                                                        <div type="button" className='text-white shadow p-3 w-100 mt-2' data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#rewardModal" : ''}
                                                            style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }}
                                                            onClick={() => handleCouponClick(business, 'reward')}
                                                        >
                                                            {business.couponText ? business.couponText.split(',')[1] : 'Click here for the deal min order $30'}
                                                        </div>
                                                        :
                                                        <div type="button" className='text-white shadow p-3 w-100 mt-2'
                                                            data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#couponModal" : ''} style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }}
                                                            onClick={() => handleCouponClick(business, 'coupon')}
                                                        >
                                                            {business.couponText ? business.couponText.split(',')[1] : 'Click here for the deal min order $30'}
                                                        </div>
                                                        : ''}

                                                    {/* {business.isDealSelect ? <div className='text-white shadow p-3 w-100 mt-2' style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }} >{business.dealText.split(',')[1]}</div> : ''} */}
                                                    {(business.isCallForQuoteSelect || business.isBookForAppointmentSelect) ?
                                                        <div className='d-flex gap-2 mt-2'>
                                                            {business.isCallForQuoteSelect ? <div type='button' className='d-flex align-items-center justify-content-center text-white px-3 py-2 flex-fill lh-sm' onClick={() => handleRequestAQuoteClick(business)} style={{ background: 'linear-gradient(to bottom right, #00cc66, #FF9A5A)', minHeight: '55px', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }} >Request a quote</div> : ''}
                                                            {business.isBookForAppointmentSelect ? <div type='button' className='d-flex align-items-center justify-content-center text-white px-3 py-2 flex-fill lh-sm' onClick={() => handleBookAnAppointmentClick(business)} style={{ background: 'linear-gradient(to bottom right, #0073e6, #FF6666)', minHeight: '55px', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }} >Book An Appointment</div> : ''}
                                                        </div> : ''
                                                    }
                                                    <div className='write-review mt-2'>
                                                        <button className='btn btn-danger w-100 d-inline-flex align-items-center justify-content-center py-2' data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#reviewModal1" : ''} onClick={() => checkRatingModal(business)}><i className="bi bi-pencil me-2"></i>Write a review</button>
                                                    </div>
                                                    <button className='w-100 btn btn-primary mt-2' onClick={(event) => handleBusinessDetails(event, business)}>View Details</button>
                                                </div>
                                            </div>
                                        ))}
                                    </Slider>
                                    :
                                    <div className='fs-3 text-danger text-center'>
                                        There is no business in your area!
                                    </div>
                            }
                        </div>
                        <div className="col-lg-3 col-xl-2">
                            {/* Start: Common Sidebar Ads Code*/}
                            <div className="ads-wrapper d-flex flex-column gap-3">
                                <div className='common-banner-wrapper common-sidebar-banner'>
                                {sliderData6 && sliderData6.length > 0 ? 
                                    <Swiper
                                        slidesPerView={1}
                                        loop={true}
                                        effect={'fade'}
                                        autoplay={{
                                            delay: 1000,
                                            disableOnInteraction: false,
                                        }}
                                        pagination={{
                                            clickable: true,
                                        }}
                                        modules={[Autoplay, EffectFade, Pagination]}
                                        className="mySwiper custom-dots"
                                    >
                                        {sliderData6.map((slide) => (
                                            <SwiperSlide key={slide._id}>
                                                <div className="slide d-flex align-items-end bg-contain" style={{ backgroundImage: `url(${slide.image})`, }} >
                                                    {/* <div className="discount-badge poin">20% off</div> */}
                                                    {slide.isCouponSelect ? <div className="discount-badge">{slide.couponText.split(',')[0]}</div> : ''}
                                                    <div className="content-wrapper text-light rounded-3 mb-5 p-2">
                                                        <div className="company-name fs-4 fw-bold lh-sm mb-1">{slide.title}</div>
                                                        <div className="company-subtitle fs-6 fw-normal mb-3">{slide.subCategory?.length > 0 ? slide.subCategory[0].subCategoryName : ''}</div>
                                                        <div className="company-subtitle fs-6 fw-normal mb-3">{slide.ad_message}</div>
                                                        <div className='action d-flex flex-wrap gap-2 mb-3'>
                                                            {slide.isPhoneSelect ?
                                                                <button type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='bg-white fs-12 text-black text-decoration-none border rounded-pill d-flex align-items-center py-1 px-2' onClick={() => handleCallClick(slide)}><i className="bi bi-telephone-fill text-primary me-1"></i>Call
                                                                </button>
                                                                : ''}
                                                            {slide.isEmailSelect ? <button type='button' className='bg-white fs-12 text-black text-decoration-none border rounded-pill d-flex align-items-center py-1 px-2' onClick={() => handleEmailClick(slide)}><i className="bi bi-envelope-fill text-primary me-1"></i>Email</button> : ''}
                                                            {slide.isWebsiteSelect ? <button type="button" className='bg-white fs-12 text-black text-decoration-none border rounded-pill d-flex align-items-center py-1 px-2' onClick={() => sendOnWebsite(slide, 'website')} ><i className="bi bi-globe-americas text-primary me-1"></i>Website</button> : ''}
                                                            {slide.isDirectionSelect ? <button type="button" className='bg-white fs-12 text-black text-decoration-none border rounded-pill d-flex align-items-center py-1 px-2' onClick={() => sendOnDirectionPage(slide)}><i className="bi bi-reply-fill text-primary me-1"></i>Directions</button> : ''}
                                                            {slide.isOrderNowSelect ? <button type="button" className='bg-white fs-12 text-black text-decoration-none border rounded-pill d-flex align-items-center py-1 px-2' onClick={() => sendOnWebsite(slide, 'order')}><img src={Cutlery} alt='cutlery' className="text-primary me-1" />Order</button> : ''}
                                                            {slide.isTextSelect ? <button type="button" className='bg-white fs-12 text-black text-decoration-none border rounded-pill d-flex align-items-center py-1 px-2' onClick={() => handleTextClick(slide)}> <i className="bi bi-pencil-fill text-primary me-1"></i>Text</button> : ''}
                                                        </div>
                                                        <div className="button-group d-flex flex-column gap-1">
                                                            {/* <button className='btn w-100 text-white lh-sm px-3' style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)' }} >Click here for a coupon</button> */}
                                                            {slide.isCouponSelect ? slide.couponText.includes('rewards') ?
                                                                <div type="button" className='text-white shadow p-3 w-100 mt-2' data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#rewardModal" : ''}
                                                                    style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }}
                                                                    onClick={() => handleCouponClick(slide, 'reward')}
                                                                >
                                                                    {slide.couponText ? slide.couponText.split(',')[1] : 'Click here for the deal min order $30'}
                                                                </div>
                                                                :
                                                                <div type="button" className='text-white shadow p-3 w-100 mt-2'
                                                                    data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#couponModal" : ''} style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }}
                                                                    onClick={() => handleCouponClick(slide, 'coupon')}
                                                                >
                                                                    {slide.couponText ? slide.couponText.split(',')[1] : 'Click here for the deal min order $30'}
                                                                </div>
                                                                : ''}
                                                            {
                                                                slide.isCallForQuoteSelect ?
                                                                    <button className="btn btn-warning w-100 px-3" onClick={() => handleRequestAQuoteClick(slide)}>Request a Quote</button>
                                                                    : ''
                                                            }
                                                            {
                                                                slide.isBookForAppointmentSelect ?
                                                                    <button className="btn btn-success w-100 px-3" onClick={() => handleBookAnAppointmentClick(slide)}>Book Appointment</button>
                                                                    : ''
                                                            }
                                                            {/* <button className="btn btn-danger w-100 px-3">Coupon Message</button> */}
                                                        </div>
                                                        <div class="sponserd text-white fw-medium mt-2">Sponsored Ad</div>
                                                    </div>
                                                </div>
                                                </SwiperSlide>
                                        ))}</Swiper> 
                                    : <div className='fs-3 text-danger text-center'>
                                        *Promote your business here!* 
                                        Take advantage of this prime ad space and grow your brand. Place your sponsored ad today!
                                    </div>}
                                </div>
                            </div>
                            {/* End: Common Sidebar Ads Code*/}
                        </div>
                    </div>
                </div>
                {/* infoDetails Modal */}
                <div className="modal fade" id="infoDetails" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="infoDetailsLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-sm">
                        <div className="modal-content">
                            <div className="modal-body text-center p-4">
                                <div className="link-item mb-3">
                                    <Link to={`tel:${callDetails.country_code + callDetails.mobile_no}`} className='fs-18 text-decoration-none text-primary'>
                                        <i className="bi bi-telephone-fill text-primary me-2"></i> {callDetails.country_code + callDetails.mobile_no}
                                    </Link>
                                </div>
                                <button type="button" className="btn btn-secondary px-5" data-bs-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Latest Report Section */}
            <div className='latest-report-sec section-bg-image py-5' style={{ backgroundImage: `url(${Banner8})` }}>
                <div className='container my-xl-4'>
                    <div className='section-header text-center mb-4 mb-lg-5'>
                        <div className='section-label fs-1'>Latest Report</div>
                        <div className='section-sublabel text-dark text-opacity-75'>Get the intelligence you need to make informed decisions. Stay ahead of the game with the latest business report from Couponranking.</div>
                    </div>
                    <Slider {...settingReports} className='custom-slick px-2 px-sm-0'>
                        {Business.map((business, index) => (
                            <div className='report-details bg-black border border-light overflow-hidden' key={index}>
                                <div className='report-img position-relative'>
                                    <img src={business.image} className='img-fluid' alt='Cleaning' />
                                </div>
                                <div className='coming-soon text-light text-center px-3 p-3 pb-0'>
                                    Coming soon...
                                </div>
                                <div className='report-desc text-light text-center shadow px-3 p-3 pb-4' key={index} style={{}}>
                                    {business.tagLine}
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>

            {/* Deals & E-Commerce Section */}
            <div className='nearby-business-sec deal-and-ecom bg-light py-5' style={{ backgroundImage: `url(${Banner9})s` }}>
                <div className='container my-xl-4'>
                    <div className='section-header text-center mw-100 px-xl-5 mb-4 mb-lg-5'>
                        <div className='section-label fs-1'>Deal and e-commerce (online services)</div>
                        <div className='section-sublabel text-dark text-opacity-75'>Looking for amazing deals? Look no further! Our site offers unbeatable discounts on all your favorite brands. Score huge saving and find exactly what you need without leaving your home. Shop and start saving today!</div>
                    </div>
                    {
                        eComBusinessList.length > 0 ?
                            <Slider {...settingEcommerece} className='custom-slick px-2 px-sm-0'>
                                {eComBusinessList.map((business, index) => (
                                    <div className='business-details bg-white border rounded-3 overflow-hidden' key={business._id}>
                                        <div className='business-img position-relative'>
                                            <Swiper
                                                slidesPerView={1}
                                                loop={true}
                                                autoplay={{
                                                    delay: 2500,
                                                    disableOnInteraction: false,
                                                }}
                                                pagination={{
                                                    clickable: true,
                                                }}
                                                modules={[Autoplay, Pagination]}
                                                className="mySwiper custom-dots"
                                            >
                                                {
                                                    business.businessPicture && business.businessPicture.length > 0 ?
                                                        business.businessPicture.map((item, idx) => (
                                                            <SwiperSlide><img src={item.pictureUrl} key={idx} className='w-100 img-fluid' alt='Cleaning' /></SwiperSlide>
                                                        ))
                                                        :
                                                        BusinessImg.map((item, idx) => (
                                                            <SwiperSlide> <img src={item.image} key={idx} className='w-100 img-fluid' alt='Cleaning' /></SwiperSlide>
                                                        ))
                                                }
                                            </Swiper>
                                            {business.isDealSelect ? <div className='discount-tag bg-danger rounded-circle d-flex align-items-center justify-content-center text-white small position-absolute p-2 lh-sm'>{business.dealText.split(',')[0]}</div> : ''}
                                        </div>
                                        <div className="business-info bg-white text-center p-3">
                                            <div className='name fs-18 fw-semibold text-capitalize mb-1'>{business.name}</div>
                                            <div className='review-wrapper d-flex align-items-center justify-content-center gap-2 mt-2'>
                                                <div className='rating-number'>{business.rating > 0 ? business.rating.toFixed(1) : 5}</div>
                                                <div className='review-star d-flex justify-content-center gap-1'>
                                                    {business.rating > 0 ?
                                                        <Rating initialValue={business.rating} size={"20px"} readonly={true} allowFraction={true} />
                                                        :
                                                        <Rating initialValue={5} size={"20px"} readonly={true} />
                                                    }
                                                </div>
                                                <div className='rating-number'>{business.ratingCount > 0 ? `(${business.ratingCount})` : '(1)'}</div>

                                            </div>
                                            <div className='name fw-semibold text-capitalize mb-1'>{business.subCategory.length > 0 ? business.subCategory[0].subCategoryName : ''}</div>
                                            <div className='action d-flex flex-wrap justify-content-center gap-2 mt-2 mb-2'>
                                                {business.isPhoneSelect ? <Link type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleCallClick(business)}><i className="bi bi-telephone-fill text-primary me-2"></i>Call</Link> : ''}
                                                {business.isEmailSelect ? <button type='button' className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleEmailClick(business)}><i className="bi bi-envelope-fill text-primary me-2"></i>Email</button> : ''}
                                                {business.isWebsiteSelect ? <Link type="button" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnWebsite(business, 'website')} ><i className="bi bi-globe-americas text-primary me-2"></i>Website</Link> : ''}
                                                {business.isDirectionSelect ? <button type="button" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnDirectionPage(business)}><i className="bi bi-reply-fill text-primary me-2"></i>Directions</button> : ''}
                                                {business.isOrderNowSelect ? <Link to={'#'} className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnWebsite(business, 'order')}><img src={Cutlery} alt='cutlery' className="text-primary me-2" />Order</Link> : ''}
                                                {business.isTextSelect ? <Link to={'#'} className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleTextClick(business)}> <i className="bi bi-pencil-fill text-primary me-2"></i>Text</Link> : ''}

                                            </div>
                                            {business.isDealSelect ?
                                                <div className='text-white shadow p-3 w-100 mt-2' type="button" style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }}
                                                    onClick={() => handleDealClick(business)}
                                                >
                                                    {business.dealText.split(',')[1]}
                                                </div> : ''}
                                            {(business.isCallForQuoteSelect || business.isBookForAppointmentSelect) ?
                                                <div className='d-flex gap-2 mt-2'>
                                                    {business.isCallForQuoteSelect ? <div type='button' className='d-flex align-items-center justify-content-center text-white px-3 py-2 flex-fill lh-sm' onClick={() => handleRequestAQuoteClick(business)} style={{ background: 'linear-gradient(to bottom right, #00cc66, #FF9A5A)', minHeight: '55px', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }} >Request a quote</div> : ''}
                                                    {business.isBookForAppointmentSelect ? <div type='button' className='d-flex align-items-center justify-content-center text-white px-3 py-2 flex-fill lh-sm' style={{ background: 'linear-gradient(to bottom right, #0073e6, #FF6666)', minHeight: '55px', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }} onClick={() => handleBookAnAppointmentClick(business)}>Book An Appointment</div> : ''}
                                                </div> : ''
                                            }
                                            <div className='write-review mt-2'>
                                                <button className='btn btn-danger w-100 d-inline-flex align-items-center justify-content-center py-2' data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#reviewModal1" : ''} onClick={() => checkRatingModal(business)}><i className="bi bi-pencil me-2"></i>Write a review</button>
                                            </div>
                                            <button className='w-100 btn btn-primary mt-2' onClick={(event) => handleEcommBusinessDetails(event, business)}>View Details</button>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                            :
                            <div className='fs-3 text-danger text-center'>
                                There is no business in your area!
                            </div>
                    }
                </div>

            </div>

            {/* Categories Section */}
            <div className='categories-sec py-5' >
                <div className='container my-xl-4 position-relative'>
                    <div className='section-header text-center mb-4 mb-lg-5'>
                        <div className='section-label fs-1'>All Category</div>
                        <div className='section-sublabel text-dark text-opacity-75'>Elevate your business to the next level with our business category solutions. Unlock potential and take advantage of tailored services today.</div>
                    </div>

                    {props.categoryList.length > 0 ?
                        <div className="row">
                            {props.categoryList.slice(0, 8).map((info, i) => (
                                <div className="col-md-6 col-lg-4 col-xl-3 mb-3 pb-1" key={info._id}>
                                    <div className="category-info-wrapper bg-primary bg-opacity-10 d-flex justify-content-between flex-column border shadow rounded-3 overflow-hidden h-100">
                                        <div className="coverr">
                                            <div className="category-header bg-white d-flex align-items-center gap-2 px-3 py-2">
                                                <img src={info.category_images} className='img-fluid' alt="{info.image}" />
                                                <div className="category-title fw-semibold">{info.name}</div>
                                            </div>
                                            <div className="category-body px-3 py-1">
                                                {info.subcategoryInfo.slice(0, 2).map((item, ind) => (
                                                    <div type='button' className="category-item d-flex gap-2 my-4" key={item._id} onClick={(e) => handleSubCategoryClick(e, item)} ><i className="bi bi-asterisk text-success"></i> {item.name}</div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="category-footer border-2 border-top border-primary border-opacity-50 text-center p-3">
                                            <Link to={'/get-all-categories'} className="btn-link text-decoration-none p-0 border-0 fw-medium" href="/subcategory">View All</Link>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className="btn-wrapper text-center mt-4 mt-lg-5">
                                <Link to={'/get-all-categories'} className="btn btn-primary d-inline-flex align-items-center justify-content-center" href="/get-all-categories">See All Industries<i className="bi bi-arrow-right ms-1"></i></Link>
                            </div>
                        </div> : ''
                    }
                </div>
            </div>

            {/* Deals & Coupons Section */}
            <div className='deals-coupon-sec section-bg-image py-5' style={{ backgroundImage: `url(${Banner3})` }}>
                <div className='container my-xl-4 position-relative'>
                    <div className='section-header text-center mb-4 mb-lg-5'>
                        <div className='section-label fs-1'>Coupons & Rewards Program</div>
                        <div className='section-sublabel text-black text-opacity-75'>Get ready for some serious savings. Business coupons and deals have arrived. Offering you great discounts on products and services!</div>
                    </div>
                    {props.couponBusinessList.length > 0 ?
                        <Slider {...settingCoupon} className='custom-slick px-2 px-sm-0'>
                            {props.couponBusinessList.filter((dd) => dd.isCouponSelect || dd.isDealSelect).map((business, index) => (
                                <div className='deals-coupon-details bg-white border rounded-3 overflow-hidden' key={business._id}>
                                    <div className='coupon-img position-relative'>
                                        <Swiper
                                            slidesPerView={1}
                                            loop={true}
                                            autoplay={{
                                                delay: 2500,
                                                disableOnInteraction: false,
                                            }}
                                            pagination={{
                                                clickable: true,
                                            }}
                                            modules={[Autoplay, Pagination]}
                                            className="mySwiper custom-dots"
                                        >
                                            {
                                                business.businessPicture && business.businessPicture.length > 0 ?
                                                    business.businessPicture.map((item, idx) => (
                                                        <SwiperSlide><img src={item.pictureUrl} key={idx} className='img-fluid' alt='Cleaning' /></SwiperSlide>
                                                    ))
                                                    :
                                                    BusinessImg.map((item, idx) => (
                                                        <SwiperSlide><img src={item.image} key={idx} className='img-fluid' alt='Cleaning' /></SwiperSlide>
                                                    ))
                                            }
                                        </Swiper>
                                        {business.isCouponSelect ?
                                            <div className='discount-tag bg-danger rounded-circle d-flex align-items-center justify-content-center text-white small position-absolute p-2 lh-sm'
                                                style={{ width: '50px', height: '50px' }}>{business.couponText.split(',')[0]}</div> : ''}
                                        {/* {business.isDealSelect ? <div className='discount-tag bg-danger rounded-circle d-flex align-items-center justify-content-center text-white small position-absolute p-2 lh-sm' style={{ width: '50px', height: '50px' }}>{business.dealText.split(',')[0]}</div> : ''} */}
                                    </div>
                                    <div className="deals-coupon-info text-center p-3 pb-4">
                                        <div className='name fs-18 fw-semibold text-capitalize mb-1'>{business.name}</div>
                                        <div className='review-wrapper d-flex align-items-center justify-content-center gap-2 mt-2'>
                                            <div className='rating-number'>{business.rating > 0 ? business.rating.toFixed(1) : 5}</div>
                                            <div className='review-star d-flex justify-content-center gap-1'>
                                                {business.rating > 0 ?
                                                    <Rating initialValue={business.rating} size={"20px"} readonly={true} allowFraction={true} />
                                                    :
                                                    <Rating initialValue={5} size={"20px"} readonly={true} />
                                                }
                                            </div>
                                            <div className='rating-number'>{business.ratingCount > 0 ? `(${business.ratingCount})` : '(1)'}</div>
                                        </div>
                                        <div className='name fw-semibold text-capitalize mb-1'>{business.subCategory.length > 0 ? business.subCategory[0].subCategoryName : ''}</div>
                                        <div className='service-rate text-dark text-opacity-75 text-capitalize'>{business.service_rate}</div>
                                        <div className='timing text-dark text-opacity-75'>{business.business_weekdays[dayString] == true ? `Open till ${business.business_weekdays[dayName].close_time_hour}:${business.business_weekdays[dayName].close_time_min} ${business.business_weekdays[dayName].close_time_am_pm}` : 'Closed today'}</div>
                                        <div className='address text-dark text-opacity-75 text-truncate'>{business.address ? getRequiredAddress(business.address, 1) : business.city}</div>
                                        {business.isCouponSelect ? business.couponText.includes('rewards') ?
                                            <div type="button" className='text-white shadow p-3 w-100 mt-2' data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#rewardModal" : ''}
                                                style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }}
                                                onClick={() => handleCouponClick(business, 'reward')}
                                            >
                                                {business.couponText ? business.couponText.split(',')[1] : 'Click here for the deal min order $30'}
                                            </div>
                                            :
                                            <div type="button" className='text-white shadow p-3 w-100 mt-2' data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#couponModal" : ''} style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }}
                                                onClick={() => handleCouponClick(business, 'coupon')}
                                            >{business.couponText ? business.couponText.split(',')[1] : 'Click here for the deal min order $30'}</div> : ''}
                                        {/* {business.isDealSelect ? <div className='text-white shadow p-3 w-100 mt-2' style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }}>{business.dealText.split(',')[1]}</div> : ''} */}
                                        <div className='d-flex gap-2 mt-2'>
                                            {business.isCallForQuoteSelect ? <div type='button' className='d-flex align-items-center justify-content-center text-white px-3 py-2 flex-fill lh-sm' style={{ background: 'linear-gradient(to bottom right, #00cc66, #FF9A5A)', minHeight: '55px', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }} onClick={() => handleRequestAQuoteClick(business)}>Request a quote</div> : ''}
                                            {business.isBookForAppointmentSelect ? <div type='button' className='d-flex align-items-center justify-content-center text-white px-3 py-2 flex-fill lh-sm' style={{ background: 'linear-gradient(to bottom right, #0073e6, #FF6666)', minHeight: '55px', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }} onClick={() => handleBookAnAppointmentClick(business)}>Book An Appointment</div> : ''}
                                        </div>
                                        <div className='write-review mt-2'>
                                            <button className='btn btn-danger w-100 d-inline-flex align-items-center justify-content-center py-2' data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#reviewModal1" : ''} onClick={() => checkRatingModal(business)}><i className="bi bi-pencil me-2"></i>Write a review</button>
                                        </div>
                                        <button className='w-100 btn btn-primary mt-2' onClick={(event) => handleBusinessDetails(event, business)}>View Details</button>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                        :
                        <div className='fs-3 text-danger text-center'>
                            There is no business in your area!
                        </div>
                    }
                </div >
            </div>

            {/* Blog Listing Section */}
            <div className='blog-sec py-5' >
                <div className='container my-xl-4'>
                    <div className='section-header text-center mb-4 mb-lg-5'>
                        <div className='section-label fs-1'>Latest Blogs</div>
                        <div className='section-sublabel text-dark text-opacity-75'>Stay informed and up-to-date with the latest news, topics, and trends in our collection of blogs.</div>
                    </div>
                    <Slider {...settingsBlog} className='custom-slick px-2 px-sm-0'>
                        {props.blogList.map((blog, index) => (
                            <div className='blog-details border rounded-3 overflow-hidden' key={blog._id}>
                                <div className='blog-img'>
                                    <img src={blog.blog_picture} className='img-fluid w-100' alt='Cleaning' />
                                </div>
                                <div className="blog-info bg-white p-3 pb-4">
                                    <div className='title fs-18 fw-semibold text-capitalize mb-1'>{blog.name}</div>
                                    <div className='description text-dark text-opacity-75 mb-1'>{blog.blog_title}</div>
                                    <div className='date text-muted'>{new Date(blog.createdAt).toDateString()}</div>
                                    <button className='btn btn-primary mt-2' onClick={() => handleBlogDetails(blog)}>View Details</button>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>

            {/* Why Choose Coupon Ranking Section */}
            <div className='why-choose-sec section-bg-image py-5' style={{ backgroundImage: `url(${Banner2})` }}>
                <div className='container my-xl-4 position-relative'>
                    <div className='section-header text-center mb-4 mb-lg-5'>
                        <div className='section-label fs-1'>Why Choose Coupon Ranking</div>
                        <div className='section-sublabel text-dark text-opacity-75'>Make your business stand out with Couponranking: An easier way to increase brand awareness &engagement.</div>
                    </div>

                    <Slider {...settingsChooseUs} className='custom-slick px-2 px-sm-0'>
                        {
                            couporankingRatingList && couporankingRatingList.length > 3 ?
                                couporankingRatingList.map((ratingData, index) => (
                                    <div className='feedback-details bg-light rounded-3 overflow-hidden text-center px-3 py-4 mb-3' key={ratingData._id}>
                                        <div className='feedback-logo mb-2'>
                                            <img src={ratingData.business_logo} className='img-fluid mx-auto border rounded-circle' alt='Business Logo' style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
                                        </div>
                                        <div className="business-info text-center">
                                            <div className='cmpny-name fs-18 fw-semibold text-capitalize mb-1'>{ratingData.name}</div>
                                            <div className='cmpny-feedback text-dark text-opacity-75 mb-1'>{ratingData.comment}</div>
                                            <div className='cmpny-address'>{ratingData.city && ratingData.state ? `${ratingData.city},${ratingData.state}` : 'Champaign,il.'}</div>
                                            <div className='review-wrapper d-flex justify-content-center gap-1 mt-2'>
                                                {ratingData.rating > 0 ?
                                                    <Rating initialValue={ratingData.rating} size={"20px"} readonly={true} allowFraction={true} />
                                                    :
                                                    <Rating initialValue={5} size={"20px"} readonly={true} />
                                                }
                                            </div>
                                            <div className='cmpny-commenttime mt-1'>{new Date(ratingData.ratingTime).toLocaleDateString("en-US")}, {formatAMPM(new Date(ratingData.ratingTime))}</div>
                                        </div>
                                    </div>
                                ))
                                :
                                Business.map((business, index) => (
                                    <div className='feedback-details bg-light rounded-3 overflow-hidden text-center px-3 py-4 mb-3' key={business.name}>
                                        <div className='feedback-logo mb-2'>
                                            <img src={require('../assets/logo.png')} className='img-fluid mx-auto border rounded-circle' alt='Business Logo' style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
                                        </div>
                                        <div className="business-info text-center">
                                            <div className='cmpny-name fs-18 fw-semibold text-capitalize mb-1'>Tamiforo company</div>
                                            <div className='cmpny-feedback text-dark text-opacity-75 mb-1'>couponranking is very good and gotten a lot of customer since i partnered with</div>
                                            <div className='cmpny-address'>Champaign,il.</div>
                                            <div className='review-wrapper d-flex justify-content-center gap-1 mt-2'>
                                                <i className="bi bi-star-fill text-warning"></i>
                                                <i className="bi bi-star-fill text-warning"></i>
                                                <i className="bi bi-star-fill text-warning"></i>
                                                <i className="bi bi-star-fill text-warning"></i>
                                                <i className="bi bi-star-fill text-warning"></i>
                                            </div>
                                        </div>
                                    </div>
                                ))
                        }
                    </Slider>
                </div>
            </div>

            {/* Review Model Popup */}
            <div className="modal fade" id="reviewModal1" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="reviewModal1Label" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header bg-primary text-white px-sm-4">
                            <h1 className="modal-title fs-18 text-capitalize lh-sm" id="reviewModal1Label">{selectedReviewBusiness.businessName}</h1>
                            <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" onClick={() => setRating(0)}></button>
                        </div>
                        <div className="modal-body px-3 px-sm-4 py-4">
                            <div className="review-wrapper mb-2">
                                <div className="star-wrapper d-flex gap-2 fs-5 mb-1">
                                    <Rating emptySymbol="bi bi-star" fullSymbol="bi bi-star-fill"
                                        initialValue={rating}
                                        value={rating}
                                        onClick={(value) => setRating(value)}
                                    />
                                </div>
                                <p className='m-0 text-muted'>Write your review for <small className='fw-medium text-capitalize'>{selectedReviewBusiness.businessName}</small></p>
                            </div>
                            <div className="review-message">
                                <textarea className="form-control small" id="dealMessage" rows="5" placeholder='Enter message here...' required
                                    onChange={(event) => setRatingComment(event.target.value)}
                                ></textarea>
                                <div className='invalid-feedback'></div>
                            </div>
                            <button className='w-100 btn btn-primary py-2 mt-3' data-bs-dismiss="modal" onClick={() => submitReview()}>Submit Review</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* coupon code with call and direction modal */}
            <div className="modal fade" id="couponModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="couponModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header bg-primary text-white">
                            <h1 className="modal-title fs-18 text-capitalize lh-sm" id="couponModalLabel">Promo Code</h1>
                            <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body px-3 py-4">
                            <p className='fs-18 mb-0'>
                                Promo Code: <span className='fw-medium text-muted'>{props.couponDetails.coupon}</span>
                            </p>
                            <p className='fs-18 mb-2'>
                                Expire On: <span className='fw-medium text-muted'>{props.couponDetails.expiryDate ? new Date(props.couponDetails.expiryDate).toLocaleDateString('en-us') : ''}</span>
                            </p>
                            <p>
                                Makes sure you present your code to the cashier to redeem your coupon or when using the phone to redeem you coupon, read out the coupon code to the cashier and you successful redeem your coupon. ONE PROMO CODE PER ORDER (per purchase or per service). Multiple promo code per order will not be allowed.
                            </p>
                            <div className='d-flex gap-2'>
                                <button type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='w-50 bg-light border rounded-pill text-center py-2 px-3'><i className="bi bi-telephone-fill text-primary me-2"></i>Call</button>
                                <button type="button" className='w-50 bg-light border rounded-pill text-center py-2 px-3' data-bs-dismiss="modal" onClick={() => sendOnDirectionPageForCouponModal()}><i className="bi bi-reply-fill text-primary me-2"></i>Directions</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* reward with call and direction modal */}
            <div className="modal fade" id="rewardModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="rewardModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header bg-primary text-white">
                            <h1 className="modal-title fs-18 text-capitalize lh-sm" id="rewardModalLabel">Your Reward</h1>
                            <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body px-3 py-4">
                            <p className='fs-18 mb-0'>
                                Reward: <span className='fw-medium text-muted  mb-2'><Rating initialValue={props.couponDetails && props.couponDetails.rewardStar ? props.couponDetails.rewardStar : 0} size={"20px"} readonly={true} allowFraction={true} /></span>
                            </p>
                            <p className='fs-18 mb-2'>
                                Expire On: <span className='fw-medium text-muted'>{props.couponDetails.expiryDate ? new Date(props.couponDetails.expiryDate).toLocaleDateString('en-us') : ''}</span>
                            </p>
                            <p>
                                Make sure you present your rewards to the cashier to earn your points (stars) and redeem your credit or when ordering online, mention your name to the cashier to earn your star (point) and redeem your credit.
                            </p>
                            <div className='d-flex gap-2'>
                                <button type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='w-50 bg-light border rounded-pill text-center py-2 px-3'><i className="bi bi-telephone-fill text-primary me-2"></i>Call</button>
                                <button type="button" className='w-50 bg-light border rounded-pill text-center py-2 px-3' data-bs-dismiss="modal" onClick={() => sendOnDirectionPageForCouponModal()}><i className="bi bi-reply-fill text-primary me-2"></i>Directions</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Download Our App Section */}
            <div className='why-choose-sec section-bg-image section-bg-overlay py-5' style={{ backgroundImage: `url(${Banner7})` }}>
                <div className='container my-4 my-xl-5'>
                    <div className='download-app-wrapper position-relative text-white text-center mx-auto'>
                        <div className='section-header text-center mb-4 mb-lg-5'>
                            <div className='section-label fs-1 mb-1'>Couponranking App coming soon...</div>
                            <div className='section-sublabel text-white text-opacity-75'>Discover incredible saving with Couponranking: Get discounts and offers from thousands of top brands.</div>
                        </div>

                        <div className="d-flex justify-content-center gap-3 mt-5">
                            <Link to={'https://appstoreconnect.apple.com/login'} target="_blank">
                                <img src={require('../assets/app-store.png')} className="img-fluid" style={{ borderRadius: '11px' }} alt='App Store' />
                            </Link>
                            <Link className="text-md-end" to={'https://play.google.com/store/games'} target="_blank">
                                <img src={require('../assets/play-store.png')} className="img-fluid" style={{ borderRadius: '11px' }} alt='Google Play Store' />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapPropsToState = (state) => {
    return {
        businessList: state.business.bussinesslisting,
        categoryList: state.business.categoryList,
        blogList: state.business.bloglist,
        ratingSuccess: state.rating.success,
        couponDetails: state.rewardAndCoupon.couponDetails,
        eComBusinessList: state.business.ecommerceBusinessList,
        couponBusinessList: state.business.couponBusinessList,
        couporankingRatingList: state.business.couporankingRatingList,
        sliderData1: state.business.slide1,
        sliderData2: state.business.slide2,
        banner: state.business.banner,

        sliderData3: state.business.slide3,
        sliderData4: state.business.slide4,
        sliderData5: state.business.slide5,
        sliderData6: state.business.slide6,
        
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getBusinessList: (data) => dispatch(actions.getBusinessList(data)),
        getCategoryList: () => dispatch(actions.getCategoryList()),
        getEcommerceBusinessList: (data) => dispatch(actions.getEcommerceBusinessList(data)),
        getCouponBusinessList: (data) => dispatch(actions.getCouponBusinessList(data)),
        getBlogList: () => dispatch(actions.getBlogList()),
        clickPerCount: (data) => dispatch(actions.clickPerCount(data)),
        makeRating: (data) => dispatch(actions.makeRating(data)),
        createAndGetCoupon: (data) => dispatch(actions.createAndGetCoupon(data)),
        getCouponrankingRatingList: (data) => dispatch(actions.getCouponrankingRatingList(data)),
        getBusinessListSlide: (data) => dispatch(actions.getBusinessListSlide(data)),

    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(Home))